import React, { useState, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import axios from "axios";
import config from "../../config";
import { ChevronLeftIcon } from "@heroicons/react/24/solid";
import {
  Row,
  Col,
  Card,
  DatePicker,
  Button,
  Form,
  Input,
  Select,
  message,
  Spin,
  Checkbox
} from "antd";

const AddNewPackage = () => {
  const [loading, setLoading] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const UserID = localStorage.getItem("AdminID");
  const AccessKey = localStorage.getItem("AdminAccessKey");
  const [messageApi, contextHolder] = message.useMessage();
  
  const [data, setData] = useState("");
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const urlParams = new URLSearchParams(window.location.search);
  const ID = urlParams.get("ID");

  useEffect(() => {
    document.title = ID ? "Update Package" : "Add Package";
    setIsUpdating(ID);
  }, [ID]);

  useEffect(() => {
    if (ID) {
      setLoading(true);
      const data = {
        UserID: UserID,
        AccessKey: AccessKey,
        ID: ID,
      };

      var api_config = {
        method: "post",
        url: config.base_url + "AdminUser/GetPackageByID",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(api_config)
        .then(function (response) {
     
          if (response.data.status_code === 1) {
            const packageData = response.data.Package;

            form.setFieldsValue({
              Name: packageData.Name,
              Tags: packageData.Tags,
              Price: packageData.Price,
              Title: packageData.Title,
              Description: packageData.Description,
              Savings: packageData.Savings,
              ShowOnWebsite: packageData.ShowOnWebsite, 
            });
            setLoading(false);
          } else {
            setLoading(false);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }, [ID]);

  const handleSubmit = (formData) => {
    setLoading(true);
    const data = {
      UserID: UserID,
      AccessKey: AccessKey,
      ID: ID,
      ...formData,
    };


    var api_config = {
      method: "post",
      url: config.base_url + (ID ? "AdminUser/UpdatePackage" : "AdminUser/AddNewPackage"),
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        if (response.data.status_code === 1) {
          setLoading(false);
          messageApi.open({
            type: "success",
            content: response.data.status_message,
          });

          navigate("/controlbase/packges");
        } else {
          setLoading(false);
          messageApi.open({
            type: "error",
            content: response.data.status_message,
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <>
      {contextHolder}

      <div className="right-side-contents">
        <div className="page-content">
          <Row>
            <Col span={12}>
              <div className="page-header">
                <h6 className="page-title">
                  <NavLink to="/controlbase/packges">
                    <ChevronLeftIcon />
                  </NavLink>
                  {isUpdating ? "Update Package" : "Add New Package"}
                </h6>
              </div>
            </Col>
          </Row>
          <Row gutter={[24, 24]}>
            <Col xs={24} md={12}>
              <Form
                name="basic"
                layout="horizontal"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                initialValues={{
                  remember: true,
                  ShowOnWebsite: false, 
                }}
                onFinish={handleSubmit}
                autoComplete="off"
                form={form}
                size="large"
              >
                <Form.Item
                  label="Name"
                  name="Name"
                  rules={[{ required: true, message: "Please enter Package name" }]}
                >
                  <Input placeholder="Enter Package Name" />
                </Form.Item>

                <Form.Item
                  label="Tags"
                  name="Tags"
                  rules={[{ required: true, message: "Please input Tags" }]}
                >
                  <Input placeholder="Enter Package Tags" />
                </Form.Item>
                <Form.Item
                  label="Price"
                  name="Price"
                  rules={[{ required: true, message: "Please enter Package Price" }]}
                >
                  <Input placeholder="Enter Price" />
                </Form.Item>
                <Form.Item
                  label="Title"
                  name="Title"
                  rules={[{ required: true, message: "Please enter Package Title" }]}
                >
                  <Input placeholder="Enter Title" />
                </Form.Item>
                <Form.Item label="Description" name="Description">
                  <Input placeholder="Enter Your Description" />
                </Form.Item>

                <Form.Item
                  label="Savings"
                  name="Savings"
                  rules={[{ required: true, message: "Please enter Savings" }]}
                >
                  <Input placeholder="Enter Savings" />
                </Form.Item>

                <Form.Item
                  wrapperCol={{ offset: 8, span: 16 }}
                  name="ShowOnWebsite"
                  valuePropName="checked" // Ensure it correctly maps to checked state
                >
                  <Checkbox>Show Package On Website</Checkbox>
                </Form.Item>

                <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                  <Button
                    type="primary"
                    size="large"
                    shape="round"
                    htmlType="submit"
                    loading={loading}
                  >
                    {isUpdating ? "Update Package" : "Add Package"}
                  </Button>
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default AddNewPackage;
