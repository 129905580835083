import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import config from "../../config";
import moment from "moment";
import {
  EditOutlined,
  DeleteOutlined,
  ProfileOutlined,
} from "@ant-design/icons";
import LoginChecked from "../Shared/LoginChecked";
import {
  Space,
  Table,
  Popconfirm,
  Pagination,
  Row,
  Col,
  Breadcrumb,
  Button,
  Input,
  DatePicker,
  Form,
  Select,
  message,
  Tooltip,
} from "antd";
import { NavLink } from "react-router-dom";
import { SearchOutlined, PrinterOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import { calculateColumnTotal, NumberFormat } from "../Shared/Utility";

function CashReceiveable(props) {
  const { RangePicker } = DatePicker;
  const [loading, setLoading] = useState(false);
  const AccessKey = localStorage.getItem("AccessKey");
  const [currentPage, setCurrentPage] = useState(1);
  const UserID = localStorage.getItem("ID");
  const [orderby, SetOrderBy] = useState(1);
  const [pageSize, setPageSize] = useState(1000);
  const [cashreceivable, setCashReceivable] = useState([]);

  const [EndDate, SetEndDate] = useState(null);

  const [ListOfRecords, setListOfRecords] = useState([]);
  const[CashRecevableTotal,setCashRecevableTotal]=useState([]);

  useEffect(() => {
    document.title = "Cash Receiveable";
    setLoading(true);
    const data = {
      UserID: UserID,
      AccessKey: AccessKey,
      PageNo: currentPage,
      PageSize: pageSize,
      EndDate: EndDate,
      OrderBy: orderby,
    };
  
    var api_config = {
      method: "post",
      url: config.base_url + "Report/GetReceiveableReport",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(api_config)
      .then(function (response) {
        
        if (response.data.status_code == 1) {
          setListOfRecords(response.data.listofLedger);
          setCashReceivable(response.data);
          setLoading(false);
        } else {
          setLoading(false);
        }
      })
      .catch(function (error) {
        // console.log(error);
      });
  }, [EndDate, orderby]);

  useEffect(() => {
   
    const totalsRow = {
      Sr: null,
      Name: "",
      Email_Address: "",
      Mobile_No: null,
      ACType: "Total",
      Balance: calculateColumnTotal(ListOfRecords,"Balance"),
      action: "",
    };

  let newlist = [...ListOfRecords, totalsRow];
  setCashRecevableTotal(newlist);


}, [ListOfRecords]);

  const searchInput = useRef(null);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          //   placeholder={Search ${dataIndex}}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1677ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) => (searchedColumn === dataIndex ? text : text),
  });

  const columns = [
    {
      title: "Sr.#",
      dataIndex: "Sr",
      key: "Sr",
      render:(_,record, index)=>
        record.ID !=null ? (index + 1) : null
    },
   
    {
      title: "Name",
      dataIndex: "Name",
      // key: "name",
      sorter: (a, b) => a.Name.localeCompare(b.Name),
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "Email Address",
      dataIndex: "Email_Address",
      key: "email_address",
    },

    {
      title: "Mobile No",
      dataIndex: "Mobile_No",
      key: "MobileNo",
    },

    {
      title: "A/C Type",
      dataIndex: "ACType",
      key: "ACType",
      render:(_,record)=>
        record.ID != null ? (record.Account_Type_BE?.Name) : "Total"
    },
    {
      title: "Current Balance",
      dataIndex: "Balance",
      key: "current_balance",
      align: "right",
      render:(_,record)=>
        record.ID !=null ? (NumberFormat(record.Balance,0)):(<strong>{NumberFormat(record.Balance,0)}</strong>),

      sorter: (a, b) => String(a.Balance).localeCompare(String(b.Balance)),
      sortDirection: ["ascend", "descend"],
    },
    {
      title: "Type",
      dataIndex: "BalanceType",
      key: "type",
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          {/* <NavLink to={`/accounts/edit-account/${record.ID}`} target="_blank">
            <EditOutlined />
          </NavLink> */}

          <NavLink to={`/accounts/edit-account/${record.ID}`} target="_blank">
            <Tooltip title="Edit Account">
              <EditOutlined />
            </Tooltip>
          </NavLink>
          <NavLink
            to={`/accounts/account-ledger/?account_id=${record.ID}&Name=${record.Name}`}
            target="_blank"
          >
            <Tooltip title="View Ladger">
              <ProfileOutlined />
            </Tooltip>
          </NavLink>
        </Space>
      ),
    },
  ];

  const confirm = (e) => {
    message.success("Data deleted successfully");
  };

  const disabledDate = (current) => {
    return current && current > moment().endOf('day');
  };

  return (
    <>
      <LoginChecked />
      <div className="right-side-contents">
        <div className="page-content">
          <div className="page-header">
            <h3 className="page-title">Cash Receiveable</h3>

            <div className="header-actions">
              <NavLink
                to={`/report-cashreceivable/print-list/?EndDate=${EndDate}&OrderBy=${orderby}`}
                target="_blank"
              >
                <Button type="dashed" size="large" shape="round">
                  <PrinterOutlined />
                  Print Report
                </Button>
              </NavLink>
            </div>
          </div>

          <div className="filters-wrap">
            <Form>
              <Form.Item>
                <DatePicker
                  onChange={(date, dateString) => SetEndDate(dateString)}
                  disabledDate={disabledDate}
                 
                />
              </Form.Item>

              <Form.Item>
                <Select
                  showSearch
                  style={{
                    width: 140,
                    marginLeft: 3,
                  }}
                  placeholder="Order By"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.label.toLowerCase().includes(input.toLowerCase())
                  }
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? "")
                      .toLowerCase()
                      .localeCompare((optionB?.label ?? "").toLowerCase())
                  }
                  onChange={(value) => SetOrderBy(value)}
                  options={[
                    {
                      value: "1",
                      label: "Name[A-Z]",
                    },
                    {
                      value: "2",
                      label: "Name[Z-A]",
                    },
                    {
                      value: "3",
                      label: "Amount[L-H]",
                    },
                    {
                      value: "4",
                      label: "Amount[H-L]",
                    },
                  ]}
                />
              </Form.Item>
            </Form>
          </div>

          <Table
            size="small"
            scroll={{
              x: "100%",
            }}
            loading={loading}
            columns={columns}
            // dataSource={ListOfRecords}
            // onChange={handleTableChange}
            dataSource={CashRecevableTotal}
            pagination={false}
            // footer={getFooterContent}
            bordered  
          />
        </div>
      </div>
    </>
  );
}

export default CashReceiveable;
