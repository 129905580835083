import React,{useState,useEffect} from 'react';

import {
  PDFViewer,
  Page,
  Text,
  View,
  Document,
} from "@react-pdf/renderer";
import { Spin } from 'antd';
import axios from 'axios';
import config from '../../../config';
import HeaderPrint from "../../../Pdf/PrintHeaderAccount"
import PrintFooter from '../../../Pdf/PrintFooter';
import pdfStyles from "../../../Pdf/pdfStyles";

function PrintDateWiseSummary2() {

  const[data,setData]=useState([]);
  const[nozzelData,setNozzelData] = useState([]);
  const[PaymentsGiven,setPaymentsGiven] = useState([]);
  const[CashReceived,setCashReceived] = useState([]);
  const[Expense,setExpense]=useState([]);
  const[loading,setLoading]=useState(false);
  const UserID=localStorage.getItem("ID");
  const AccessKey=localStorage.getItem("AccessKey");
  
  const urlParams = new URLSearchParams(window.location.search);
  const Dated = urlParams.get("Dated");
  


  useEffect(() => {
    setLoading(true);
    const data = {
      UserID: UserID,
      AccessKey: AccessKey,
      Dated: Dated,
    };
    

    var api_config = {
      method: "post",
      url: config.base_url + "Report/GetDailySummaryReport2",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
     
        if (response.data.status_code == 1) {
          setData(response.data);
          setNozzelData(response.data.listofReading);
          setPaymentsGiven(response.data.listofCashGiven);
          setExpense(response.data.listofExpenses);
          setCashReceived(response.data.listofCashreceipt);
          

          setLoading(false);
        } else {
          setLoading(false);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  const nozzelDataGenrate = () => {
    if (nozzelData.length > 0) {
      return nozzelData.map((item, index) => {
        return (
          <View key={index} style={pdfStyles.row}>
            <View style={[pdfStyles.td, {width:50}]}>
              <Text style={pdfStyles.tdValue}>{index + 1}</Text>
            </View>
            <View style={[pdfStyles.td, {width:100}]}>
              <Text style={pdfStyles.tdValue}>{item.ProductCode}</Text>
            </View>
            <View style={[pdfStyles.td, {width:50}]}>
              <Text style={pdfStyles.tdValue}>{item.Pump_No}</Text>
            </View>
            <View style={[pdfStyles.td, {width:100}]}>
              <Text style={pdfStyles.tdValue}>{item.Last_Reading}</Text>
            </View>
            <View style={[pdfStyles.td, {width:100}]}>
              <Text style={pdfStyles.tdValue}>{item.Current_Reading}</Text>
            </View>
            <View style={[pdfStyles.td, {width:100}]}>
              <Text style={pdfStyles.tdValue}>{item.Returned}</Text>
            </View>
            <View style={[pdfStyles.td, {width:100}]}>
              <Text style={pdfStyles.tdValue}>{item.UsedQuantity}.00</Text>
            </View>
          </View>
        );
      });
    }
  };

  const paymentGivenGenrate = () => {
    if (PaymentsGiven.length > 0) {
      return PaymentsGiven.map((item, index) => {
        return (
          <View key={index} style={pdfStyles.row}>
            <View style={[pdfStyles.td, {width:50}]}>
              <Text style={pdfStyles.tdValue}>{index + 1}</Text>
            </View>
            <View style={[pdfStyles.td, {width:130}]}>
              <Text style={pdfStyles.tdValue}>{item.VoucherNo}</Text>
            </View>
            <View style={[pdfStyles.td, {width:130}]}>
              <Text style={pdfStyles.tdValue}>{item.Credit_Account?.Name}</Text>
            </View>
            <View style={[pdfStyles.td, {width:130}]}>
              <Text style={pdfStyles.tdValue}>{item.Description}</Text>
            </View>
            <View style={[pdfStyles.td, {width:130, textAlign:"right"}]}>
              <Text style={pdfStyles.tdValue}>{item.Amount}</Text>
            </View>
          </View>
        );
      });
    }
  };
  const ExpenseGenrate = () => {
    if (Expense.length > 0) {
      return Expense.map((item, index) => {
        return (
          <View key={index} style={pdfStyles.row}>
            <View style={[pdfStyles.td, {width:50}]}>
              <Text style={pdfStyles.tdValue}>{index + 1}</Text>
            </View>
            <View style={[pdfStyles.td, {width:130}]}>
              <Text style={pdfStyles.tdValue}>{item.VoucherNo}</Text>
            </View>
            <View style={[pdfStyles.td, {width:130}]}>
              <Text style={pdfStyles.tdValue}>{item.Debit_Account?.Name}</Text>
            </View>
            <View style={[pdfStyles.td, {width:130}]}>
              <Text style={pdfStyles.tdValue}>{item.Description}</Text>
            </View>
            <View style={[pdfStyles.td, {width:130, textAlign:"right"}]}>
              <Text style={pdfStyles.tdValue}>{item.Amount}</Text>
            </View>
          </View>
        );
      });
    }
  };
  const PaymentReaceivedGenrate = () => {
    if (CashReceived.length > 0) {
      return CashReceived.map((item, index) => {
        return (
          <View key={index} style={pdfStyles.row}>
            <View style={[pdfStyles.td, {width:50}]}>
              <Text style={pdfStyles.tdValue}>{index + 1}</Text>
            </View>
            <View style={[pdfStyles.td, {width:130}]}>
              <Text style={pdfStyles.tdValue}>{item.VoucherNo}</Text>
            </View>
            <View style={[pdfStyles.td, {width:130}]}>
              <Text style={pdfStyles.tdValue}>{item.Credit_Account?.Name}</Text>
            </View>
            <View style={[pdfStyles.td, {width:130}]}>
              <Text style={pdfStyles.tdValue}>{item.Description}</Text>
            </View>
            <View style={[pdfStyles.td, {width:130, textAlign:"right"}]}>
              <Text style={pdfStyles.tdValue}>{item.Amount}</Text>
            </View>
          </View>
        );
      });
    }
  };


  const MyDocument = () => (
    <Document>
      <Page size="A4" style={pdfStyles.page}>
        <HeaderPrint />
        <View style={pdfStyles.section}>
          <View style={pdfStyles.title}>
            <Text style={{ fontSize: "12pt" }}>Summary Sheet 2</Text>
          </View>

          <View style={pdfStyles.title}>
            <Text style={{ fontSize: "12pt" }}>Nozzel Details</Text>
          </View>
          <View style={pdfStyles.table}>
            <View style={pdfStyles.tableHead}>
              <View style={[pdfStyles.th, { width:50 }]}>
                <Text style={pdfStyles.thValue}>SR.</Text>
              </View>
              <View style={[pdfStyles.th, { width:100 }]}>
                <Text style={pdfStyles.thValue}>Product</Text>
              </View>
              <View style={[pdfStyles.th, { width:50 }]}>
                <Text style={pdfStyles.thValue}>No.</Text>
              </View>
              <View style={[pdfStyles.th, { width:100 }]}>
                <Text style={pdfStyles.thValue}>Previous</Text>
              </View>
              <View style={[pdfStyles.th, { width:100 }]}>
                <Text style={pdfStyles.thValue}>Current</Text>
              </View>
              <View style={[pdfStyles.th, { width:100 }]}>
                <Text style={pdfStyles.thValue}>	Returned</Text>
              </View>
              <View style={[pdfStyles.th, { width:100 }]}>
                <Text style={pdfStyles.thValue}>	Used</Text>
              </View>
            </View>

            {nozzelDataGenrate()}
          </View>
          <View style={pdfStyles.title}>
            <Text style={{ fontSize: "12pt" }}>Sale Details</Text>
          </View>
          <View style={pdfStyles.table}>
            <View style={pdfStyles.tableHead}>
              <View style={[pdfStyles.th, { width:50}]}>
                <Text style={pdfStyles.thValue}>SR.</Text>
              </View>
              <View style={[pdfStyles.th, { width:50}]}>
                <Text style={pdfStyles.thValue}>	Inv.#</Text>
              </View>
              <View style={[pdfStyles.th, { width:120}]}>
                <Text style={pdfStyles.thValue}>Customer</Text>
              </View>
              <View style={[pdfStyles.th, { width:50}]}>
                <Text style={pdfStyles.thValue}>Product</Text>
              </View>
              <View style={[pdfStyles.th, { width:80}]}>
                <Text style={pdfStyles.thValue}>Name</Text>
              </View>
              <View style={[pdfStyles.th, { width:50}]}>
                <Text style={pdfStyles.thValue}>	Quantity</Text>
              </View>
              <View style={[pdfStyles.th, { width:50}]}>
                <Text style={pdfStyles.thValue}>	Rate</Text>
              </View>
              <View style={[pdfStyles.th, { width:100, textAlign:"right"}]}>
                <Text style={pdfStyles.thValue}>	Amount</Text>
              </View>
            </View>

            {/* {generateData()} */}

            <View style={pdfStyles.tableHead}>
            <View style={[pdfStyles.th, { width:450,textAlign:"right"}]}>
                <Text style={pdfStyles.thValue}>Total</Text>
              </View>
              <View style={[pdfStyles.th, { width:100,textAlign:"right"}]}>
                <Text style={pdfStyles.thValue}>00</Text>
              </View>
            
          </View>
          </View>
          
          <View style={pdfStyles.title}>
            <Text style={{ fontSize: "12pt" }}>Credit Voucher Sales</Text>
          </View>
          <View style={pdfStyles.table}>
            <View style={pdfStyles.tableHead}>
            <View style={[pdfStyles.th, { width:50}]}>
                <Text style={pdfStyles.thValue}>SR.</Text>
              </View>
              <View style={[pdfStyles.th, { width:130}]}>
                <Text style={pdfStyles.thValue}>Voucher No</Text>
              </View>
              <View style={[pdfStyles.th, { width:130}]}>
                <Text style={pdfStyles.thValue}>	Customer Name</Text>
              </View>
              <View style={[pdfStyles.th, { width:130}]}>
                <Text style={pdfStyles.thValue}>Description</Text>
              </View>
              <View style={[pdfStyles.th, { width:130, textAlign:"right"}]}>
                <Text style={pdfStyles.thValue}>	Amount</Text>
              </View>
            </View>

            {/* {PaymentReaceivedGenrate()} */}
            <View style={pdfStyles.tableHead}>
            <View style={[pdfStyles.th, { width:440,textAlign:"right"}]}>
                <Text style={pdfStyles.thValue}>Total</Text>
              </View>
              <View style={[pdfStyles.th, { width:130,textAlign:"right"}]}>
                <Text style={pdfStyles.thValue}>00</Text>
              </View>
            
          </View>
          </View>


          <View style={pdfStyles.title}>
            <Text style={{ fontSize: "12pt" }}>Payment Receipts</Text>
          </View>
          <View style={pdfStyles.table}>
            <View style={pdfStyles.tableHead}>
            <View style={[pdfStyles.th, { width:50}]}>
                <Text style={pdfStyles.thValue}>SR.</Text>
              </View>
              <View style={[pdfStyles.th, { width:130}]}>
                <Text style={pdfStyles.thValue}>Voucher No</Text>
              </View>
              <View style={[pdfStyles.th, { width:130}]}>
                <Text style={pdfStyles.thValue}>	Customer Name</Text>
              </View>
              <View style={[pdfStyles.th, { width:130}]}>
                <Text style={pdfStyles.thValue}>Description</Text>
              </View>
              <View style={[pdfStyles.th, { width:130, textAlign:"right"}]}>
                <Text style={pdfStyles.thValue}>	Amount</Text>
              </View>
            </View>

            {PaymentReaceivedGenrate()}
            <View style={pdfStyles.tableHead}>
            <View style={[pdfStyles.th, { width:440,textAlign:"right"}]}>
                <Text style={pdfStyles.thValue}>Total</Text>
              </View>
              <View style={[pdfStyles.th, { width:130,textAlign:"right"}]}>
                <Text style={pdfStyles.thValue}>{data.cashReceivedTotal}</Text>
              </View>
            
          </View>
          </View>
          
          
          <View style={pdfStyles.title}>
            <Text style={{ fontSize: "12pt" }}>Payment Given</Text>
          </View>
          <View style={pdfStyles.table}>
            <View style={pdfStyles.tableHead}>
            <View style={[pdfStyles.th, { width:50}]}>
                <Text style={pdfStyles.thValue}>SR.</Text>
              </View>
              <View style={[pdfStyles.th, { width:130}]}>
                <Text style={pdfStyles.thValue}>Voucher No</Text>
              </View>
              <View style={[pdfStyles.th, { width:130}]}>
                <Text style={pdfStyles.thValue}>	Customer Name</Text>
              </View>
              <View style={[pdfStyles.th, { width:130}]}>
                <Text style={pdfStyles.thValue}>Description</Text>
              </View>
              <View style={[pdfStyles.th, { width:130, textAlign:"right"}]}>
                <Text style={pdfStyles.thValue}>	Amount</Text>
              </View>
            </View>

            {paymentGivenGenrate()}
            <View style={pdfStyles.tableHead}>
            <View style={[pdfStyles.th, { width:440,textAlign:"right"}]}>
                <Text style={pdfStyles.thValue}>Total</Text>
              </View>
              <View style={[pdfStyles.th, { width:130,textAlign:"right"}]}>
                <Text style={pdfStyles.thValue}>{data.cashGiven}</Text>
              </View>
            
          </View>
          </View>

          <View style={pdfStyles.title}>
            <Text style={{ fontSize: "12pt" }}>Expense Details</Text>
          </View>
          <View style={pdfStyles.table}>
            <View style={pdfStyles.tableHead}>
            <View style={[pdfStyles.th, { width:50}]}>
                <Text style={pdfStyles.thValue}>SR.</Text>
              </View>
              <View style={[pdfStyles.th, { width:130}]}>
                <Text style={pdfStyles.thValue}>Voucher No</Text>
              </View>
              <View style={[pdfStyles.th, { width:130}]}>
                <Text style={pdfStyles.thValue}>	Customer Name</Text>
              </View>
              <View style={[pdfStyles.th, { width:130}]}>
                <Text style={pdfStyles.thValue}>Description</Text>
              </View>
              <View style={[pdfStyles.th, { width:130, textAlign:"right"}]}>
                <Text style={pdfStyles.thValue}>	Amount</Text>
              </View>
            </View>

            {ExpenseGenrate()}
            <View style={pdfStyles.tableHead}>
            <View style={[pdfStyles.th, { width:440,textAlign:"right"}]}>
                <Text style={pdfStyles.thValue}>Total</Text>
              </View>
              <View style={[pdfStyles.th, { width:130,textAlign:"right"}]}>
                <Text style={pdfStyles.thValue}>{data.expensesPaid}</Text>
              </View>
            
          </View>
          </View>

          
          <View
            style={{ marginBottom: 50, marginTop: 0, top: 10, bottom: 10 }}
            fixed
          >
            <Text
              style={{ fontSize: "8pt" }}
              render={({ pageNumber, totalPages }) =>
                `Page ${pageNumber} / ${totalPages}`
              }
            />
          </View>
        </View>
        <PrintFooter fixed/>
      </Page>
    </Document>
  );
  return (
    <>

    <div style={{ width: "100%", height: "100vh", overflow: "hidden" }}>

    {loading ? (
          <div
            style={{
              height: "100vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Spin />
          </div>
        ) : (
      <PDFViewer width="100%" height="100%" fileName="sample.pdf">
        <MyDocument />
      </PDFViewer>)}
    </div>
  </>
  )
}

export default PrintDateWiseSummary2