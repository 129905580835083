import React, { useState, useEffect } from "react";

import { PDFViewer, Page, Text, View, Document } from "@react-pdf/renderer";
import { Spin } from "antd";
import axios from "axios";
import config from "../../../config";
import HeaderPrint from "../../../Pdf/PrintHeaderAccount";
import PrintFooter from "../../../Pdf/PrintFooter";
import pdfStyles from "../../../Pdf/pdfStyles";
import { PrintFormateNumber } from "../../../Pdf/PrintFormateNumber";
import { calculateColumnTotal } from "../../Shared/Utility";
import dayjs from "dayjs";

function PrintSummary3() {
  const [ListOfGernalVouchers, setListOfGernalVouchers] = useState([]);
  const [ListOfReading, setListOfReading] = useState([]);
  const [ListOfSaleDetails, setListOfSaleDetails] = useState([]);
  const [PaymentsGiven, setPaymentGiven] = useState([]);
  const [ListOfExpense, setListOfExpense] = useState([]);
  const [ListOfReceipts, setListOfReceipts] = useState([]);
  const [ListOfCreditPurchase, setListOfCreditPurchase] = useState([]);
  const [ListOfCreditSales, setListOfCreditSale] = useState([]);

  const [data, setData] = useState({});

  const [loading, setLoading] = useState(false);
  const UserID = localStorage.getItem("ID");
  const AccessKey = localStorage.getItem("AccessKey");

  const urlParams = new URLSearchParams(window.location.search);
  const Dated = urlParams.get("Dated");

  useEffect(() => {
    setLoading(true);
    const data = {
      UserID: UserID,
      AccessKey: AccessKey,
      Dated: Dated,
    };

    var api_config = {
      method: "post",
      url: config.base_url + "Report/GetDailySummaryReport3",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        if (response.data.status_code == 1) {
          setListOfReading(response.data.listofReading);
          setListOfSaleDetails(response.data.listofSaleDetail);
          setData(response.data);
          setListOfGernalVouchers(response.data.listofGeneralVoucher);
          setPaymentGiven(response.data.listofPayments);
          setListOfExpense(response.data.listofExpenses);
          setListOfReceipts(response.data.listofReceipts);
          setListOfCreditPurchase(response.data.listofCreditPurchase);
          setListOfCreditSale(response.data.listofCreditSales);

          setLoading(false);
        } else {
          setLoading(false);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  const ListOfReadings = () => {
    if (ListOfReading.length > 0) {
      return ListOfReading.map((item, index) => {
        return (
          <View key={index} style={pdfStyles.row}>
            <View style={[pdfStyles.td, { width: 50 }]}>
              <Text style={pdfStyles.tdValue}>{index + 1}</Text>
            </View>
            <View style={[pdfStyles.td, { width: 100 }]}>
              <Text style={pdfStyles.tdValue}>{item.ProductName}</Text>
            </View>
            <View style={[pdfStyles.td, { width: 50 }]}>
              <Text style={pdfStyles.tdValue}>{item.PumpMachineID}</Text>
            </View>
            <View style={[pdfStyles.td, { width: 100, textAlign: "right" }]}>
              <Text style={pdfStyles.tdValue}>{item.Last_Reading}</Text>
            </View>
            <View style={[pdfStyles.td, { width: 100, textAlign: "right" }]}>
              <Text style={pdfStyles.tdValue}>{item.Current_Reading}</Text>
            </View>
            <View style={[pdfStyles.td, { width: 100, textAlign: "right" }]}>
              <Text style={pdfStyles.tdValue}>{item.Returned}</Text>
            </View>
            <View style={[pdfStyles.td, { width: 100, textAlign: "right" }]}>
              <Text style={pdfStyles.tdValue}>{item.UsedQuantity}.00</Text>
            </View>
          </View>
        );
      });
    }
  };
  const SalesList = () => {
    if (ListOfSaleDetails.length > 0) {
      return ListOfSaleDetails.map((item, index) => {
        return (
          <View key={index} style={pdfStyles.row}>
            <View style={[pdfStyles.td, { width: 50 }]}>
              <Text style={pdfStyles.tdValue}>{index + 1}</Text>
            </View>
            <View style={[pdfStyles.td, { width: 60 }]}>
              <Text style={pdfStyles.tdValue}>{item.Receipt_No}</Text>
            </View>
            <View style={[pdfStyles.td, { width: 120 }]}>
              <Text style={pdfStyles.tdValue}>{item.Account_BE?.Name}</Text>
            </View>
            <View style={[pdfStyles.td, { width: 90 }]}>
              <Text style={pdfStyles.tdValue}>{item.Product_BE?.Name}</Text>
            </View>
            <View style={[pdfStyles.td, { width: 50 }]}>
              <Text style={pdfStyles.tdValue}>{item.Vehicle_No}</Text>
            </View>
            <View style={[pdfStyles.td, { width: 50, textAlign: "right" }]}>
              <Text style={pdfStyles.tdValue}>{item.Quantity}</Text>
            </View>
            <View style={[pdfStyles.td, { width: 50, textAlign: "right" }]}>
              <Text style={pdfStyles.tdValue}>{item.Price}</Text>
            </View>
            <View style={[pdfStyles.td, { width: 90, textAlign: "right" }]}>
              <Text style={pdfStyles.tdValue}>
                {PrintFormateNumber(item.Amount, 0)}
              </Text>
            </View>
          </View>
        );
      });
    }
  };

  const Vouchers = () => {
    if (ListOfGernalVouchers.length > 0) {
      return ListOfGernalVouchers.map((item, index) => {
        return (
          <View key={index} style={pdfStyles.row}>
            <View style={[pdfStyles.td, { width: 50 }]}>
              <Text style={pdfStyles.tdValue}>{index + 1}</Text>
            </View>
            <View style={[pdfStyles.td, { width: 80 }]}>
              <Text style={pdfStyles.tdValue}>{item.VoucherNo}</Text>
            </View>
            <View style={[pdfStyles.td, { width: 110 }]}>
              <Text style={pdfStyles.tdValue}>{item.Credit_Account?.Name}</Text>
            </View>
            <View style={[pdfStyles.td, { width: 90 }]}>
              <Text style={pdfStyles.tdValue}>{item.Debit_Account?.Name}</Text>
            </View>
            <View style={[pdfStyles.td, { width: 190 }]}>
              <Text style={pdfStyles.tdValue}>{item.Description}</Text>
            </View>
            <View style={[pdfStyles.td, { width: 100, textAlign: "right" }]}>
              <Text style={pdfStyles.tdValue}>
                {PrintFormateNumber(item.Amount, 0)}
              </Text>
            </View>
          </View>
        );
      });
    }
  };
  const PaymentsGives = () => {
    if (PaymentsGiven.length > 0) {
      return PaymentsGiven.map((item, index) => {
        return (
          <View key={index} style={pdfStyles.row}>
            <View style={[pdfStyles.td, { width: 50 }]}>
              <Text style={pdfStyles.tdValue}>{index + 1}</Text>
            </View>
            <View style={[pdfStyles.td, { width: 80 }]}>
              <Text style={pdfStyles.tdValue}>{item.VoucherNo}</Text>
            </View>
            <View style={[pdfStyles.td, { width: 90 }]}>
              <Text style={pdfStyles.tdValue}>{item.Credit_Account?.Name}</Text>
            </View>
            <View style={[pdfStyles.td, { width: 110 }]}>
              <Text style={pdfStyles.tdValue}>{item.Debit_Account?.Name}</Text>
            </View>
            <View style={[pdfStyles.td, { width: 190 }]}>
              <Text style={pdfStyles.tdValue}>{item.Description}</Text>
            </View>
            <View style={[pdfStyles.td, { width: 100, textAlign: "right" }]}>
              <Text style={pdfStyles.tdValue}>
                {PrintFormateNumber(item.Amount, 0)}
              </Text>
            </View>
          </View>
        );
      });
    }
  };
  const Expenses = () => {
    if (ListOfExpense.length > 0) {
      return ListOfExpense.map((item, index) => {
        return (
          <View key={index} style={pdfStyles.row}>
            <View style={[pdfStyles.td, { width: 50 }]}>
              <Text style={pdfStyles.tdValue}>{index + 1}</Text>
            </View>
            <View style={[pdfStyles.td, { width: 80 }]}>
              <Text style={pdfStyles.tdValue}>{item.VoucherNo}</Text>
            </View>
            <View style={[pdfStyles.td, { width: 90 }]}>
              <Text style={pdfStyles.tdValue}>{item.Credit_Account?.Name}</Text>
            </View>
            <View style={[pdfStyles.td, { width: 110 }]}>
              <Text style={pdfStyles.tdValue}>{item.Debit_Account?.Name}</Text>
            </View>
            <View style={[pdfStyles.td, { width: 190 }]}>
              <Text style={pdfStyles.tdValue}>{item.Description}</Text>
            </View>
            <View style={[pdfStyles.td, { width: 100, textAlign: "right" }]}>
              <Text style={pdfStyles.tdValue}>
                {PrintFormateNumber(item.Amount, 0)}
              </Text>
            </View>
          </View>
        );
      });
    }
  };
  const Receipts = () => {
    if (ListOfReceipts.length > 0) {
      return ListOfReceipts.map((item, index) => {
        return (
          <View key={index} style={pdfStyles.row}>
            <View style={[pdfStyles.td, { width: 50 }]}>
              <Text style={pdfStyles.tdValue}>{index + 1}</Text>
            </View>
            <View style={[pdfStyles.td, { width: 80 }]}>
              <Text style={pdfStyles.tdValue}>{item.VoucherNo}</Text>
            </View>
            <View style={[pdfStyles.td, { width: 90 }]}>
              <Text style={pdfStyles.tdValue}>{item.Credit_Account?.Name}</Text>
            </View>
            <View style={[pdfStyles.td, { width: 110 }]}>
              <Text style={pdfStyles.tdValue}>{item.Debit_Account?.Name}</Text>
            </View>
            <View style={[pdfStyles.td, { width: 190 }]}>
              <Text style={pdfStyles.tdValue}>{item.Description}</Text>
            </View>
            <View style={[pdfStyles.td, { width: 100, textAlign: "right" }]}>
              <Text style={pdfStyles.tdValue}>
                {PrintFormateNumber(item.Amount, 0)}
              </Text>
            </View>
          </View>
        );
      });
    }
  };
  const CreditPurchase = () => {
    if (ListOfCreditPurchase.length > 0) {
      return ListOfCreditPurchase.map((item, index) => {
        return (
          <View key={index} style={pdfStyles.row}>
            <View style={[pdfStyles.td, { width: 50 }]}>
              <Text style={pdfStyles.tdValue}>{index + 1}</Text>
            </View>
            <View style={[pdfStyles.td, { width: 80 }]}>
              <Text style={pdfStyles.tdValue}>{item.VoucherNo}</Text>
            </View>
            <View style={[pdfStyles.td, { width: 90 }]}>
              <Text style={pdfStyles.tdValue}>{item.Credit_Account?.Name}</Text>
            </View>
            <View style={[pdfStyles.td, { width: 110 }]}>
              <Text style={pdfStyles.tdValue}>{item.Debit_Account?.Name}</Text>
            </View>
            <View style={[pdfStyles.td, { width: 190 }]}>
              <Text style={pdfStyles.tdValue}>{item.Description}</Text>
            </View>
            <View style={[pdfStyles.td, { width: 100, textAlign: "right" }]}>
              <Text style={pdfStyles.tdValue}>
                {PrintFormateNumber(item.Amount, 0)}
              </Text>
            </View>
          </View>
        );
      });
    }
  };
  const CreditSales = () => {
    if (ListOfCreditSales.length > 0) {
      return ListOfCreditSales.map((item, index) => {
        return (
          <View key={index} style={pdfStyles.row}>
            <View style={[pdfStyles.td, { width: 50 }]}>
              <Text style={pdfStyles.tdValue}>{index + 1}</Text>
            </View>
            <View style={[pdfStyles.td, { width: 80 }]}>
              <Text style={pdfStyles.tdValue}>{item.VoucherNo}</Text>
            </View>
            <View style={[pdfStyles.td, { width: 90 }]}>
              <Text style={pdfStyles.tdValue}>{item.Credit_Account?.Name}</Text>
            </View>
            <View style={[pdfStyles.td, { width: 110 }]}>
              <Text style={pdfStyles.tdValue}>{item.Debit_Account?.Name}</Text>
            </View>
            <View style={[pdfStyles.td, { width: 190 }]}>
              <Text style={pdfStyles.tdValue}>{item.Description}</Text>
            </View>
            <View style={[pdfStyles.td, { width: 100, textAlign: "right" }]}>
              <Text style={pdfStyles.tdValue}>
                {PrintFormateNumber(item.Amount, 0)}
              </Text>
            </View>
          </View>
        );
      });
    }
  };

  const MyDocument = () => (
    <Document>
      <Page size="A4" style={pdfStyles.page}>
        <HeaderPrint />
        <View style={pdfStyles.section}>
          <View style={pdfStyles.title}>
            <Text style={{ fontSize: "16pt" }}>Summary Sheet 3</Text>
            <Text style={{ fontSize: "12pt" }}>
              {dayjs(Dated).format("DD MMMM YYYY")}
            </Text>
          </View>

          <View style={pdfStyles.title}>
            <Text style={{ fontSize: "12pt" }}>Nozzle Details</Text>
            <Text
              style={{
                fontSize: "12pt",
                fontFamily: "Vazirmatn",
                textAlign: "right",
              }}
            >
              نوزل کی تفصیلات
              </Text>
          </View>
          <View style={pdfStyles.table}>
            <View style={pdfStyles.tableHead}>
              <View style={[pdfStyles.th, { width: 50 }]}>
                <Text style={pdfStyles.thValue}>SR.</Text>
              </View>
              <View style={[pdfStyles.th, { width: 100 }]}>
                <Text style={pdfStyles.thValue}>Product</Text>
              </View>
              <View style={[pdfStyles.th, { width: 50 }]}>
                <Text style={pdfStyles.thValue}>No.</Text>
              </View>
              <View style={[pdfStyles.th, { width: 100, textAlign: "right" }]}>
                <Text style={pdfStyles.thValue}>Previous</Text>
              </View>
              <View style={[pdfStyles.th, { width: 100, textAlign: "right" }]}>
                <Text style={pdfStyles.thValue}>Current</Text>
              </View>
              <View style={[pdfStyles.th, { width: 100, textAlign: "right" }]}>
                <Text style={pdfStyles.thValue}> Returned</Text>
              </View>
              <View style={[pdfStyles.th, { width: 100, textAlign: "right" }]}>
                <Text style={pdfStyles.thValue}> Used</Text>
              </View>
            </View>

            {ListOfReadings()}
          </View>
          <View style={pdfStyles.title}>
            <Text style={{ fontSize: "12pt" }}>Sale Details</Text>
            <Text
              style={{
                fontSize: "12pt",
                fontFamily: "Vazirmatn",
                textAlign: "right",
              }}
            >
فروخت کی تفصیلات            </Text>
          </View>
          <View style={pdfStyles.table}>
            <View style={pdfStyles.tableHead}>
              <View style={[pdfStyles.th, { width: 50 }]}>
                <Text style={pdfStyles.thValue}>SR.</Text>
              </View>
              <View style={[pdfStyles.th, { width: 60 }]}>
                <Text style={pdfStyles.thValue}>Inv.#</Text>
              </View>
              <View style={[pdfStyles.th, { width: 120 }]}>
                <Text style={pdfStyles.thValue}>Customer</Text>
              </View>
              <View style={[pdfStyles.th, { width: 90 }]}>
                <Text style={pdfStyles.thValue}>Product</Text>
              </View>
              <View style={[pdfStyles.th, { width: 50 }]}>
                <Text style={pdfStyles.thValue}>Vehicle</Text>
              </View>
              <View style={[pdfStyles.th, { width: 50, textAlign: "right" }]}>
                <Text style={pdfStyles.thValue}>Quantity</Text>
              </View>
              <View style={[pdfStyles.th, { width: 50, textAlign: "right" }]}>
                <Text style={pdfStyles.thValue}>Price</Text>
              </View>
              <View style={[pdfStyles.th, { width: 90, textAlign: "right" }]}>
                <Text style={pdfStyles.thValue}>Amount</Text>
              </View>
            </View>

            {SalesList()}

            <View style={pdfStyles.tableHead}>
              <View style={[pdfStyles.th, { width: 470, textAlign: "right"}]}>
                <Text style={pdfStyles.thValue}>Cash Sale</Text>
              </View>
              <View style={[pdfStyles.th, { width: 90, textAlign: "right" }]}>
                <Text style={[pdfStyles.thValue,{fontWeight: 'extrabold' }]}>
                  {PrintFormateNumber(data.CashSales, 0)}
                </Text>
              </View>
            </View>
            <View style={pdfStyles.tableHead}>
              <View style={[pdfStyles.th, { width: 470, textAlign: "right" }]}>
                <Text style={pdfStyles.thValue}>Credit Sale</Text>
              </View>
              <View style={[pdfStyles.th, { width: 90, textAlign: "right" }]}>
                <Text style={pdfStyles.thValue}>
                  {PrintFormateNumber(data.CreditSales, 0)}
                </Text>
              </View>
            </View>
            <View style={pdfStyles.tableHead}>
              <View style={[pdfStyles.th, { width: 470, textAlign: "right" }]}>
                <Text style={pdfStyles.thValue}>Total Sale</Text>
              </View>
              <View style={[pdfStyles.th, { width: 90, textAlign: "right" }]}>
                <Text style={pdfStyles.thValue}>
                  {PrintFormateNumber(data.totalSales, 0)}
                </Text>
              </View>
            </View>
          </View>

          <View style={pdfStyles.title}>
            <Text style={{ fontSize: "12pt" }}>Vouchers Details</Text>
            <Text
              style={{
                fontSize: "12pt",
                fontFamily: "Vazirmatn",
                textAlign: "right",
              }}
            >
واؤچر کی تفصیلات</Text>
          </View>
          <View style={pdfStyles.table}>
            <View style={pdfStyles.tableHead}>
              <View style={[pdfStyles.th, { width: 50 }]}>
                <Text style={pdfStyles.thValue}>SR.</Text>
              </View>
              <View style={[pdfStyles.th, { width: 80 }]}>
                <Text style={pdfStyles.thValue}>Voucher No</Text>
              </View>
              <View style={[pdfStyles.th, { width: 110 }]}>
                <Text style={pdfStyles.thValue}>Customer Name</Text>
              </View>
              <View style={[pdfStyles.th, { width: 90 }]}>
                <Text style={pdfStyles.thValue}>Received In</Text>
              </View>
              <View style={[pdfStyles.th, { width: 190 }]}>
                <Text style={pdfStyles.thValue}>Description</Text>
              </View>
              <View style={[pdfStyles.th, { width: 100, textAlign: "right" }]}>
                <Text style={pdfStyles.thValue}>Amount</Text>
              </View>
            </View>

            {Vouchers()}
            <View style={pdfStyles.tableHead}>
              <View style={[pdfStyles.th, { width: 520, textAlign: "right" }]}>
                <Text style={pdfStyles.thValue}>Total</Text>
              </View>
              <View style={[pdfStyles.th, { width: 100, textAlign: "right" }]}>
                <Text style={pdfStyles.thValue}>
                  {PrintFormateNumber(
                    calculateColumnTotal(ListOfGernalVouchers, "Amount"),
                    0
                  )}
                </Text>
              </View>
            </View>
          </View>
          <View style={pdfStyles.title}>
            <Text style={{ fontSize: "12pt" }}>Payments Given Details</Text>
            <Text
              style={{
                fontSize: "12pt",
                fontFamily: "Vazirmatn",
                textAlign: "right",
              }}
            >
دی گئی ادائیگیوں کی تفصیلات</Text>
          </View>
          <View style={pdfStyles.table}>
            <View style={pdfStyles.tableHead}>
              <View style={[pdfStyles.th, { width: 50 }]}>
                <Text style={pdfStyles.thValue}>SR.</Text>
              </View>
              <View style={[pdfStyles.th, { width: 80 }]}>
                <Text style={pdfStyles.thValue}>Voucher No</Text>
              </View>
              <View style={[pdfStyles.th, { width: 90 }]}>
                <Text style={pdfStyles.thValue}>From</Text>
              </View>
              <View style={[pdfStyles.th, { width: 110 }]}>
                <Text style={pdfStyles.thValue}>To</Text>
              </View>
              <View style={[pdfStyles.th, { width: 190 }]}>
                <Text style={pdfStyles.thValue}>Description</Text>
              </View>
              <View style={[pdfStyles.th, { width: 100, textAlign: "right" }]}>
                <Text style={pdfStyles.thValue}>Amount</Text>
              </View>
            </View>

            {PaymentsGives()}
            <View style={pdfStyles.tableHead}>
              <View style={[pdfStyles.th, { width: 520, textAlign: "right" }]}>
                <Text style={pdfStyles.thValue}>Total</Text>
              </View>
              <View style={[pdfStyles.th, { width: 100, textAlign: "right" }]}>
                <Text style={pdfStyles.thValue}>
                  {PrintFormateNumber(
                    calculateColumnTotal(PaymentsGiven, "Amount"),
                    0
                  )}
                </Text>
              </View>
            </View>
          </View>
          <View style={pdfStyles.title}>
            <Text style={{ fontSize: "12pt" }}>Expenses Details</Text>
            <Text
              style={{
                fontSize: "12pt",
                fontFamily: "Vazirmatn",
                textAlign: "right",
              }}
            >
اخراجات کی تفصیلات</Text>
            
          </View>
          <View style={pdfStyles.table}>
            <View style={pdfStyles.tableHead}>
              <View style={[pdfStyles.th, { width: 50 }]}>
                <Text style={pdfStyles.thValue}>SR.</Text>
              </View>
              <View style={[pdfStyles.th, { width: 80 }]}>
                <Text style={pdfStyles.thValue}>Voucher No</Text>
              </View>
              <View style={[pdfStyles.th, { width: 90 }]}>
                <Text style={pdfStyles.thValue}>From</Text>
              </View>
              <View style={[pdfStyles.th, { width: 110 }]}>
                <Text style={pdfStyles.thValue}>To</Text>
              </View>
              <View style={[pdfStyles.th, { width: 190 }]}>
                <Text style={pdfStyles.thValue}>Description</Text>
              </View>
              <View style={[pdfStyles.th, { width: 100, textAlign: "right" }]}>
                <Text style={pdfStyles.thValue}>Amount</Text>
              </View>
            </View>

            {Expenses()}
            <View style={pdfStyles.tableHead}>
              <View style={[pdfStyles.th, { width: 520, textAlign: "right" }]}>
                <Text style={pdfStyles.thValue}>Total</Text>
              </View>
              <View style={[pdfStyles.th, { width: 100, textAlign: "right" }]}>
                <Text style={pdfStyles.thValue}>
                  {PrintFormateNumber(
                    calculateColumnTotal(ListOfExpense, "Amount"),
                    0
                  )}
                </Text>
              </View>
            </View>
          </View>
          <View style={pdfStyles.title}>
            <Text style={{ fontSize: "12pt" }}>Receipts Details</Text>
            <Text
              style={{
                fontSize: "12pt",
                fontFamily: "Vazirmatn",
                textAlign: "right",
              }}
            >
وصولیوں کی تفصیلات</Text>
          </View>
          <View style={pdfStyles.table}>
            <View style={pdfStyles.tableHead}>
              <View style={[pdfStyles.th, { width: 50 }]}>
                <Text style={pdfStyles.thValue}>SR.</Text>
              </View>
              <View style={[pdfStyles.th, { width: 80 }]}>
                <Text style={pdfStyles.thValue}>Voucher No</Text>
              </View>
              <View style={[pdfStyles.th, { width: 90 }]}>
                <Text style={pdfStyles.thValue}>From</Text>
              </View>
              <View style={[pdfStyles.th, { width: 110 }]}>
                <Text style={pdfStyles.thValue}>To</Text>
              </View>
              <View style={[pdfStyles.th, { width: 190 }]}>
                <Text style={pdfStyles.thValue}>Description</Text>
              </View>
              <View style={[pdfStyles.th, { width: 100, textAlign: "right" }]}>
                <Text style={pdfStyles.thValue}>Amount</Text>
              </View>
            </View>

            {Receipts()}
            <View style={pdfStyles.tableHead}>
              <View style={[pdfStyles.th, { width: 520, textAlign: "right" }]}>
                <Text style={pdfStyles.thValue}>Total</Text>
              </View>
              <View style={[pdfStyles.th, { width: 100, textAlign: "right" }]}>
                <Text style={pdfStyles.thValue}>
                  {PrintFormateNumber(
                    calculateColumnTotal(ListOfReceipts, "Amount"),
                    0
                  )}
                </Text>
              </View>
            </View>
          </View>
          <View style={pdfStyles.title}>
            <Text style={{ fontSize: "12pt" }}>Credit Purchase Details</Text>
            <Text
              style={{
                fontSize: "12pt",
                fontFamily: "Vazirmatn",
                textAlign: "right",
              }}
            >
ادھار خریداری کی تفصیلات</Text>
          </View>
          <View style={pdfStyles.table}>
            <View style={pdfStyles.tableHead}>
              <View style={[pdfStyles.th, { width: 50 }]}>
                <Text style={pdfStyles.thValue}>SR.</Text>
              </View>
              <View style={[pdfStyles.th, { width: 80 }]}>
                <Text style={pdfStyles.thValue}>Voucher No</Text>
              </View>
              <View style={[pdfStyles.th, { width: 90 }]}>
                <Text style={pdfStyles.thValue}>To</Text>
              </View>
              <View style={[pdfStyles.th, { width: 110 }]}>
                <Text style={pdfStyles.thValue}>From</Text>
              </View>
              <View style={[pdfStyles.th, { width: 190 }]}>
                <Text style={pdfStyles.thValue}>Description</Text>
              </View>
              <View style={[pdfStyles.th, { width: 100, textAlign: "right" }]}>
                <Text style={pdfStyles.thValue}>Amount</Text>
              </View>
            </View>

            {CreditPurchase()}
            <View style={pdfStyles.tableHead}>
              <View style={[pdfStyles.th, { width: 520, textAlign: "right" }]}>
                <Text style={pdfStyles.thValue}>Total</Text>
              </View>
              <View style={[pdfStyles.th, { width: 100, textAlign: "right" }]}>
                <Text style={pdfStyles.thValue}>
                  {PrintFormateNumber(
                    calculateColumnTotal(ListOfCreditPurchase, "Amount"),
                    0
                  )}
                </Text>
              </View>
            </View>
          </View>
          <View style={pdfStyles.title}>
            <Text style={{ fontSize: "12pt" }}>Credit Sales Details</Text>
            <Text
              style={{
                fontSize: "12pt",
                fontFamily: "Vazirmatn",
                textAlign: "right",
              }}
            >
ادھار فروخت کی تفصیلات</Text>
          </View>
          <View style={pdfStyles.table}>
            <View style={pdfStyles.tableHead}>
              <View style={[pdfStyles.th, { width: 50 }]}>
                <Text style={pdfStyles.thValue}>SR.</Text>
              </View>
              <View style={[pdfStyles.th, { width: 80 }]}>
                <Text style={pdfStyles.thValue}>Voucher No</Text>
              </View>
              <View style={[pdfStyles.th, { width: 90 }]}>
                <Text style={pdfStyles.thValue}>From</Text>
              </View>
              <View style={[pdfStyles.th, { width: 110 }]}>
                <Text style={pdfStyles.thValue}>To</Text>
              </View>
              <View style={[pdfStyles.th, { width: 190 }]}>
                <Text style={pdfStyles.thValue}>Description</Text>
              </View>
              <View style={[pdfStyles.th, { width: 100, textAlign: "right" }]}>
                <Text style={pdfStyles.thValue}>Amount</Text>
              </View>
            </View>

            {CreditSales()}
            <View style={pdfStyles.tableHead}>
              <View style={[pdfStyles.th, { width: 520, textAlign: "right" }]}>
                <Text style={pdfStyles.thValue}>Total</Text>
              </View>
              <View style={[pdfStyles.th, { width: 100, textAlign: "right" }]}>
                <Text style={pdfStyles.thValue}>
                  {PrintFormateNumber(
                    calculateColumnTotal(ListOfCreditSales, "Amount"),
                    0
                  )}
                </Text>
              </View>
            </View>
          </View>

          <View
            style={{ marginBottom: 50, marginTop: 0, top: 10, bottom: 10 }}
            fixed
          >
            <Text
              style={{ fontSize: "8pt" }}
              render={({ pageNumber, totalPages }) =>
                `Page ${pageNumber} / ${totalPages}`
              }
            />
          </View>
        </View>
        <PrintFooter fixed />
      </Page>
    </Document>
  );
  return (
    <>
      <div style={{ width: "100%", height: "100vh", overflow: "hidden" }}>
        {loading ? (
          <div
            style={{
              height: "100vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Spin />
          </div>
        ) : (
          <PDFViewer width="100%" height="100%" fileName="sample.pdf">
            <MyDocument />
          </PDFViewer>
        )}
      </div>
    </>
  );
}

export default PrintSummary3;
