import React, { useState } from "react";
import GoogleMapReact from 'google-map-react';

const AnyReactComponent = ({ text }) => <div>{text}</div>;

export default function Map({ onLocationSelect }) {
  const [clickedLocation, setClickedLocation] = useState(null);

  const defaultProps = {
    center: {
      lat: 31.1137,
      lng: 74.4672
    },
    zoom: 11
  };

  const handleMapClick = ({ lat, lng }) => {
    setClickedLocation({ lat, lng });
    onLocationSelect(lat, lng);
  };

  return (
   
    <div style={{ height: '300px', width: '100%' }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: "" }}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
        onClick={handleMapClick}
      >
        
        {clickedLocation && (
          <AnyReactComponent
            lat={clickedLocation.lat}
            lng={clickedLocation.lng}
            text="Selected Location"
          />
        )}
      </GoogleMapReact>
    </div>
  );
}
