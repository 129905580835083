import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  PDFViewer,
  Page,
  Text,
  View,
  Image,
  Document,
  StyleSheet,
} from "@react-pdf/renderer";
import moment from "moment";
import Logo from "../../assets/images/Print-image.png";
import config from "../../config";
import { useParams } from "react-router-dom";
import PrintFooter from "../../Pdf/PrintFooter";
import pdfStyles from "../../Pdf/pdfStyles";
import { NumberFormat } from "../../components/Shared/Utility";

function SubscriptionInvoicePdf(props) {
  const params = useParams();
  const [AccessKey, setAccessKey] = useState(
    localStorage.getItem("AdminAccessKey")
  );
  const [PumpDetail, setPumpDetail] = useState({});

  const [UserID, setUserID] = useState(localStorage.getItem("AdminID"));
  const [loading, setLoading] = useState(false);
  const [listofLedger, SetlistofLedger] = useState([]);
  const [closingBalance, setClosingBalance] = useState("");
  const [totalRecords, setTotalRecords] = useState(0);

  const [totalDebit, settotalDebit] = useState("");
  const [totalCredit, settotalCredit] = useState("");
  const [cashBalance, setcashBalance] = useState("");
  const [purchasedInfo, setPurchasedInfo] = useState({});

  const [PumpID, setPumpID] = useState(0);

  const [openingBalance, setOpeningBalance] = useState("");

  const urlParams = new URLSearchParams(window.location.search);
  const StartDate = urlParams.get("StartDate");
  const EndDate = urlParams.get("EndDate");
  const Full_Name = urlParams.get("Full_Name");
  const [AllData, setAllData] = useState("");

  useEffect(() => {
    document.title = "Invoice";
    setLoading(true);
    const data = {
      UserID: UserID,
      AccessKey: AccessKey,
      PumpID: params.id,
    };
    var api_config = {
      method: "post",
      url: config.base_url + "AdminUser/GetPackagePurchaseByPumpID",
      header: {
        "Content-Type": "application/josn",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        if (response.data.status_code == 1) {
          SetlistofLedger(response.data.listofRecords);
          setTotalRecords(response.data.totalRecords);
          setLoading(false);
        } else {
          setLoading(false);
        }
      })
      .catch(function (error) {});
  }, [params]);

  useEffect(() => {
    document.title = "Invoice";
    setLoading(true);
    const data = {
      UserID: UserID,
      AccessKey: AccessKey,
      ID: params.id,
    };

    var api_config = {
      method: "post",
      url: config.base_url + "AdminUser/GetPackagePurchaseByID",
      header: {
        "Content-Type": "application/josn",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        if (response.data.status_code == 1) {
          setPurchasedInfo(response.data.PackagePurchased);
        } else {
          setLoading(false);
        }
      })
      .catch(function (error) {});
  }, [params]);

  console.log(purchasedInfo);

  useEffect(() => {
    setLoading(true);
    const data = {
      UserID: UserID,
      AccessKey: AccessKey,
      ID: params.id,
    };

    var api_config = {
      method: "post",
      url: config.base_url + "AdminUser/GetPetrolPumpInfo",
      header: {
        "Content-Type": "application/josn",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        if (response.data.status_code == 1) {
          setPumpDetail(response.data);
          setLoading(false);
        } else {
          setLoading(false);
        }
      })
      .catch(function (error) {});
  }, [params]);

  // Create styles
  const styles = StyleSheet.create({
    box: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      gap:20,
    },
    boxleft: {
      width: "40%",
      marginTop: "50px",
    },
    boxright: {
      width: "50%",
    },
    invoicecontent: {
      margin: "20px 0px",
      borderRadius: 10,
      border: 0.5,
      borderWidth: 1,
      borderColor: "#f0f0f0",
      backgroundColor: "#E8EDFF",
    },
    ul: {
      padding: "5",
    },
    li: {
      flexDirection: "row",
      justifyContent: "space-between",
      paddingHorizontal: 5,
      paddingVertical: 6,
      gap: "5",
    },
    label: {
      width: "25%",
      fontSize: 10,
      fontWeight: "extralight",
      color: "#000",
      textAlign: "right",
    },
    boxlabel: {
      fontSize: 15,
      fontWeight: "extralight",
      color: "#000",
    },
    span: {
      width: "80%",
      fontSize: 10,
      fontWeight: "bold",
      color: "#000",
      textAlign: "left",
      borderBottomWidth: 0.2,
      borderBottomColor: "#000",
    },
    info:{
        fontSize:14,
        textAlign:"left",
        padding:'5px 0px 5px 35px'
    }
  });

const generateData = () => {
        return (
          <View  style={pdfStyles.row}>
            <View style={[pdfStyles.td, { width: 50 }]}>
              <Text style={pdfStyles.tdValue}>1</Text>
            </View>
            <View style={[pdfStyles.td, { width:420 }]}>
                <Text style={pdfStyles.tdValue}>
                  {purchasedInfo.PackageName} Package{" "}
               </Text>
               <Text style={[pdfStyles.tdValue, { marginVertical: 5 }]}>
                  form {moment(purchasedInfo.StartDate).format("DD/MM/YYYY")} to{" "}
                  {moment(purchasedInfo.EndDate).format("DD/MM/YYYY")}{" "}
                </Text>
                <Text style={pdfStyles.tdValue}>
                Payment Received via {purchasedInfo.PaymentMode}.
               </Text>
            </View>
            <View style={[pdfStyles.td, { width:100,display:"flex", alignItems:'center', justifyContent:'center'}]}>
                <Text style={pdfStyles.tdValue}>
                  {NumberFormat(purchasedInfo.PackagePrice)}
                </Text>
               </View>

          </View>
        );
      
    };

  const MyDocument = () => (
    <Document>
      <Page size="A4" style={pdfStyles.page} orientation="portrait">
        <View style={pdfStyles.section}>
          <View>
            <View>
              <Image src={Logo} style={{ width: 70, height: "auto" }} />
            </View>
            <View style={styles.box}>
            <View style={styles.boxright}>
              <View style={styles.invoicecontent}>
                <View style={styles.info}><Text>From:</Text></View>
                <View style={styles.ul}>
                  <View style={styles.li}>
                    <Text style={styles.label}>Name:</Text>
                    <Text style={styles.span}>
                    Fuelbook 
                    </Text>
                  </View>
                  <View style={styles.li}>
                    <Text style={styles.label}>Address:</Text>
                    <Text style={styles.span}>
                    Fast Mall, Railway Road Kasur, Pakistan                    
                    </Text>
                  </View>
                  <View style={styles.li}>
                    <Text style={styles.label}>Mobile:</Text>
                    <Text style={styles.span}>
                    0321 6590065                   
                    </Text>
                  </View>
                  <View style={styles.li}>
                    <Text style={styles.label}>Email:</Text>
                    <Text style={styles.span}>
                    info@fuelbook.pk
                    </Text>
                  </View>
                  {/* <View style={styles.li}>
                                        <Text style={styles.label}>Package Name:</Text>
                                        <Text style={styles.span}>{PumpDetail.PumpDetails?.PackageName}</Text>
                                    </View>
                                    <View style={styles.li}>
                                        <Text style={styles.label}>Package Expiry:</Text>
                                        <Text style={styles.span}>{(moment(PumpDetail.PumpDetails?.PackageExpiry).format('DD/MM/YYYY'))}</Text>
                                    </View> */}
                </View>
              </View>
            </View>
            <View style={styles.boxright}>
              <View style={styles.invoicecontent}>
              <View style={styles.info}><Text>To:</Text></View>
                <View style={styles.ul}>
                  <View style={styles.li}>
                    <Text style={styles.label}>Name:</Text>
                    <Text style={styles.span}>
                      {PumpDetail.PumpDetails?.Name}
                    </Text>
                  </View>
                  <View style={styles.li}>
                    <Text style={styles.label}>Pump Code:</Text>
                    <Text style={styles.span}>
                      {PumpDetail.PumpDetails?.PumpCode}
                    </Text>
                  </View>
                  <View style={styles.li}>
                    <Text style={styles.label}>Mobile:</Text>
                    <Text style={styles.span}>
                      {PumpDetail.PumpDetails?.Mobile}
                    </Text>
                  </View>
                  <View style={styles.li}>
                    <Text style={styles.label}>Email:</Text>
                    <Text style={styles.span}>
                      {PumpDetail.PumpDetails?.Email}
                    </Text>
                  </View>
                  {/* <View style={styles.li}>
                                        <Text style={styles.label}>Package Name:</Text>
                                        <Text style={styles.span}>{PumpDetail.PumpDetails?.PackageName}</Text>
                                    </View>
                                    <View style={styles.li}>
                                        <Text style={styles.label}>Package Expiry:</Text>
                                        <Text style={styles.span}>{(moment(PumpDetail.PumpDetails?.PackageExpiry).format('DD/MM/YYYY'))}</Text>
                                    </View> */}
                </View>
              </View>
            </View>
            </View>

            
          </View>
        </View>
        <View style={pdfStyles.section}>
         <View style={pdfStyles.table}>
            <View style={pdfStyles.tableHead}>
              <View style={[pdfStyles.th, { width: 50 }]}>
                <Text style={pdfStyles.thValue}>SR.</Text>
              </View>
              <View style={[pdfStyles.th, {  width: 420}]}>
                <Text style={pdfStyles.thValue}>Description</Text>
              </View>
              <View style={[pdfStyles.th, { width: 100, textAlign:"center" }]}>
                <Text style={pdfStyles.thValue}>Price</Text>
              </View>
            </View>
            {generateData()}
            <View style={pdfStyles.tableHead}>
              <View style={[pdfStyles.th, { width: 470, textAlign:"right" }]}>
                <Text style={pdfStyles.thValue}>Discount</Text>
              </View>
              <View style={[pdfStyles.th, { width: 100,textAlign:"center" }]}>
                <Text style={pdfStyles.thValue}>{NumberFormat(purchasedInfo.DiscountValue)}</Text>
              </View>
            </View>
            <View style={pdfStyles.tableHead}>
              <View style={[pdfStyles.th, { width: 470, textAlign:"right" }]}>
                <Text style={pdfStyles.thValue}>Received</Text>
              </View>
              <View style={[pdfStyles.th, { width: 100,textAlign:"center" }]}>
                <Text style={pdfStyles.thValue}>{NumberFormat(purchasedInfo.NetReceiveable)}</Text>
              </View>
            </View>
          </View>

          <View style={{ marginTop: 100 }}>
            <Text style={pdfStyles.thValue}>
              This invoice serves as proof of your subscription purchase. Please
              retain it for your records. If you have any questions or require
              assistance, feel free to contact our support team at
              support@fuelbook.com.
            </Text>
          </View>

          <View style={{ marginTop: 10 }}>
            <Text style={pdfStyles.thValue}>
              Address: Fast Mall, Railway Road Kasur, Pakistan
            </Text>
          </View>
          <PrintFooter />
          <View
            style={{ marginBottom: 50, marginTop: 20, top: 10, bottom: 10 }}
            fixed
          >
            <Text
              style={{ fontSize: "8pt" }}
              render={({ pageNumber, totalPages }) =>
                `${pageNumber} / ${totalPages}`
              }
            />
          </View>
        </View>
      </Page>
    </Document>
  );

  return (
    <>
      <div style={{ width: "100%", height: "100vh", overflow: "hidden" }}>
        {loading ? (
          "Loading..."
        ) : (
          <PDFViewer
            width="100%"
            height="100%"
            fileName="Subscription Invoice.pdf"
          >
            <MyDocument />
          </PDFViewer>
        )}
      </div>
    </>
  );
}

export default SubscriptionInvoicePdf;
