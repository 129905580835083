import React, { useEffect, useState, useRef } from "react";
import moment from "moment";
import { ChevronLeftIcon } from "@heroicons/react/24/outline";
import config from "../../config";

import axios from "axios";
import {
  Row,
  Col,
  Modal,
  Form,
  Input,
  Select,
  DatePicker,
  Button,
  message,
  Spin,
  Skeleton,
} from "antd";
import { NavLink, useNavigate } from "react-router-dom";
import LoginChecked from "../Shared/LoginChecked";

function AddVoucher() {
  let navigate = useNavigate();

  const AccessKey = localStorage.getItem("AccessKey");
  const UserID = localStorage.getItem("ID");

  const [voucherNo, setVoucherNo] = useState(0);

  const [debitAccountID, setdebitAccountID] = useState(0);
  const [creditAccountID, setcreditAccountID] = useState(0);

  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);
  const [gettingVoucher, setgettingVoucher] = useState(false);

  // customer accounts
  const [CustomerAccounts, setCustomerAccounts] = useState([]);
  const [loadingCustomerAccounts, setloadingCustomerAccounts] = useState(false);
  const [CustomerAccount, setCustomerAccount] = useState({});
  const [loadingCustomerAccount, setloadingCustomerAccount] = useState(false);

  // received in
  const [ListOfRecAccounts, setListOfRecAccounts] = useState([]);
  const [loadingRecAccounts, setLoadingRecAccounts] = useState(false);
  const [RecAccountsDropdown, setRecAccountsDropdown] = useState([]);
  const [loadingRecAccount, setLoadingRecAccount] = useState(false);
  const [RecAccount, setRecAccount] = useState({});

  useEffect(() => {
    setloadingCustomerAccounts(true);
    const data = {
      UserID: UserID,
      AccessKey: AccessKey,
      Account_Type_ID: 8,
    };
    var api_config = {
      method: "post",
      url: config.base_url + "account/GetAccountsByType",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
    
        if (response.data.status_code == 1) {
          let list = response.data.listofAccounts;

          const result = list.map((item) => ({
            value: item.ID,
            label: item.Name,
          }));

          // Handle success
          setCustomerAccounts(result);
          setloadingCustomerAccounts(false);
        } else {
          setloadingCustomerAccounts(false);
          // Handle error
        }
        //   setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    setLoadingRecAccounts(true);
    const data = {
      UserID: UserID,
      AccessKey: AccessKey,
      Account_Type_ID: 0,
    };
    var api_config = {
      method: "post",
      url: config.base_url + "account/GetAccountsByType",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        if (response.data.status_code == 1) {
          // Handle success
          setListOfRecAccounts(response.data.listofAccounts);
          setLoadingRecAccounts(false);
        } else {
          setLoadingRecAccounts(false);
          // Handle error
        }
        //   setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    if (!loadingRecAccounts) {
      //const list = ListOfRecAccounts.filter((item) => item.Account_Type_ID === 1 || item.Account_Type_ID === 13);

      const result = ListOfRecAccounts.map((item) => ({
        value: item.ID,
        label: item.Name,
      }));

      setRecAccountsDropdown(result);
    }
  }, [ListOfRecAccounts]);

  const handleSubmit = (formData) => {
    setLoadingSave(true);
    formData["Dated"] = moment(formData.OpeningDate).format("YYYY-MM-DD");

    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      Credit_Account_ID: creditAccountID,
      Debit_Account_ID: debitAccountID,

      VoucherMode: 'General',
      ...formData,
    };


    var api_config = {
      method: "post",
      url: config.base_url + "Voucher/AddNewVoucher",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        if (response.data.status_code == 1) {
          setLoadingSave(false);
          message.success(response.data.status_message);
          form.resetFields();
          setcreditAccountID(0);
          setdebitAccountID(0);
          navigate("/vouchers/manage-vouchers");
          // Handle success
        } else {
          // Handle error
          setLoadingSave(false);
          message.error(response.data.status_message);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    setgettingVoucher(true);

    const data = {
      UserID: UserID,
      AccessKey: AccessKey,
    };

    var api_config = {
      method: "post",
      url: config.base_url + "/Voucher/GetNextVoucherNo",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    // setVoucherNo(data.voucherNo);
    axios(api_config)
      .then(function (response) {
        
        if (response.data.status_code == 1) {
          setVoucherNo(response.data.NextVoucherNo);
          // Handle success

          setgettingVoucher(false);
        } else {
          // Handle error
        }
        //   setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  const onSelectDebitAccount = (item) => {
    setdebitAccountID(item);
    setLoadingRecAccount(true);
    const data = {
      UserID: UserID,
      AccessKey: AccessKey,
      ID: item,
    };
    var api_config = {
      method: "post",
      url: config.base_url + "account/GetAccountbyIDWithBalance",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(api_config)
      .then(function (response) {
        if (response.data.status_code == 1) {
          // Handle success
          // setlistofAccounts(response.data.listofAccounts);
          setRecAccount(response.data);
          setLoadingRecAccount(false);
        } else {
          // Handle error
          setLoadingRecAccount(false);
        }
        //   setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const onSelectCreditAccount = (item) => {
    setcreditAccountID(item);
    setloadingCustomerAccount(true);
    const data = {
      UserID: UserID,
      AccessKey: AccessKey,
      ID: item,
    };
    var api_config = {
      method: "post",
      url: config.base_url + "account/GetAccountbyIDWithBalance",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(api_config)
      .then(function (response) {
        if (response.data.status_code == 1) {
          // Handle success
          // setlistofAccounts(response.data.listofAccounts);
          setCustomerAccount(response.data);
          setloadingCustomerAccount(false);
        } else {
          // Handle error
          setloadingCustomerAccount(false);
        }
        //   setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  //const accountTypesArray = AccountTypesDropdown();

 

  const disabledDate = (current) => {
    return current && current > moment().endOf('day');
  };

  return (
    <>
      <LoginChecked />

      <div className="right-side-contents full-screen">
        <div className="page-content">
          <div className="page-header">
            <h3 className="page-title">
              <NavLink to="/vouchers/manage-vouchers">
                <ChevronLeftIcon />
              </NavLink>
              General Voucher
            </h3>
          </div>

          <Form
            name="basic"
            onFinish={handleSubmit}
            //    onFinishFailed={onFinishFailed}
            autoComplete="off"
            size="large"
            fields={[
              {
                name: "NextVoucherNo",
                value: voucherNo,
              },
            ]}
          >
            <div className="full-screen-box">
              <section>
                <Row align="bottom" gutter={24}>
                  <Col xs={24} md={8}>
                    <Form.Item
                      style={{ marginBottom: 0 }}
                      label="Debit Account"
                      name="Debit_Account"
                      className="input-vertical"
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      rules={[{ required: true }]}
                    >
                      <Select
                        showSearch
                        placeholder="Select Account"
                        optionFilterProp="children"
                        onChange={onSelectDebitAccount}
                        filterOption={(input, option) =>
                          (option?.label ?? "")
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        loading={loadingRecAccounts}
                        options={RecAccountsDropdown}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={16}>
                    <ul>
                      {loadingRecAccount ? (
                        <>
                          <li>
                            <Skeleton.Input
                              style={{ width: 150, height: 40 }}
                              active
                            />
                          </li>
                        </>
                      ) : (
                        <>
                          <li>
                            <span className="lbl">Current Balance</span>
                            <span className="val balance">
                              Rs. {RecAccount.Balance} {RecAccount.BalanceType}
                            </span>
                          </li>
                        </>
                      )}
                    </ul>
                  </Col>
                </Row>
              </section>
              <section style={{ marginTop: 20 }}>
                <Row align="bottom" gutter={24}>
                  <Col xs={24} md={8}>
                    <Form.Item
                      rules={[{ required: true }]}
                      style={{ marginBottom: 0 }}
                      label="Credit Account"
                      name="Credit_Account"
                      className="input-vertical"
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                    >
                      <Select
                        showSearch
                        placeholder="Select Account"
                        optionFilterProp="children"
                        onChange={onSelectCreditAccount}
                        // onSearch={onSearch}
                        filterOption={(input, option) =>
                          (option?.label ?? "")
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        loading={loadingRecAccounts}
                        options={RecAccountsDropdown}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={16}>
                    <ul>
                      {loadingCustomerAccount ? (
                        <>
                          <li>
                            <Skeleton.Input
                              style={{ width: 150, height: 40 }}
                              active
                            />
                          </li>
                          <li>
                            <Skeleton.Input
                              style={{ width: 150, height: 40 }}
                              active
                            />
                          </li>
                        </>
                      ) : (
                        <>
                          <li>
                            <span className="lbl">Current Balance</span>
                            <span className="val balance">
                              Rs. {CustomerAccount.Balance}{" "}
                              {CustomerAccount.BalanceType}
                            </span>
                          </li>

                          <li>
                            <span className="lbl">Mobile</span>
                            <span className="val">
                              {CustomerAccount.Mobile_No == ""
                                ? "Not available"
                                : CustomerAccount.Mobile_No}
                            </span>
                          </li>
                        </>
                      )}
                    </ul>
                  </Col>
                </Row>
              </section>
              <section
                style={{
                  paddingTop: "20px",
                  marginTop: "40px",
                  borderTop: "1px solid rgba(0,0,0,0.1)",
                }}
              >
                {gettingVoucher ? (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Spin />
                  </div>
                ) : (
                  <>
                    <Row gutter={24}>
                      <Col xs={24} md={6}>
                        <Form.Item
                          className="input-vertical"
                          name="NextVoucherNo"
                          label="Voucher No"
                          rules={[{ required: true }]}
                        >
                          <Input disabled />
                        </Form.Item>
                      </Col>
                      <Col xs={24} md={6}>
                        <Form.Item
                          className="input-vertical"
                          name="Dated"
                          label="Date"
                          rules={[{ required: true }]}
                        >
                          <DatePicker style={{ width: "100%" }} disabledDate={disabledDate} />
                        </Form.Item>
                      </Col>
                      <Col xs={24} md={12}>
                        <Form.Item
                          className="input-vertical"
                          name="Amount"
                          label="Amount"
                          rules={[{ required: true }]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <Form.Item
                          className="input-vertical"
                          name="Description"
                          label="Description"
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                    </Row>

                    <div className="text-right">
                      <NavLink
                        to="/vouchers/manage-vouchers"
                        style={{ marginRight: "20px" }}
                      >
                        <Button size="large" shape="round">
                          Cancel
                        </Button>
                      </NavLink>
                      <Button
                        type="primary"
                        size="large"
                        shape="round"
                        htmlType="submit"
                        loading={loadingSave}
                      >
                        Add Voucher
                      </Button>
                    </div>
                  </>
                )}
              </section>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
}

export default AddVoucher;
