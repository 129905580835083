import React, { useEffect, useState } from "react";
import axios from "axios";
import config from "../../config";
import { ChevronLeftIcon } from "@heroicons/react/24/solid";
import LoginChecked from "../Shared/LoginChecked";
import {
  Space,
  Row,
  Col,
  Button,
  Input,
  Form,
  Select,
  Skeleton,
  InputNumber,
  message,
} from "antd";
import { NavLink, useNavigate } from "react-router-dom";
import { NumberFormat } from "../Shared/Utility";

const { Option } = Select;

const ProductRates = () => {
  const [loading, setLoading] = useState(false);
  const [listOfCustomers, setListOfCustomers] = useState([]);
  const [ProductName, setProductName] = useState("");
  const UserID = localStorage.getItem("ID");
  const AccessKey = localStorage.getItem("AccessKey");
  const urlParams = new URLSearchParams(window.location.search);
  const ID = urlParams.get("product_id") || "";

  const navigate = useNavigate();

  useEffect(() => {
    document.title = "Product Ledger";
    const data = {
      UserID: UserID,
      AccessKey: AccessKey,
      ProductID: ID,
    };

    var api_config = {
      method: "post",
      url: config.base_url + "Product/GetCustomerRateByProductID",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(api_config)
      .then(function (response) {
       
        if (response.data.status_code == 1) {
          setListOfCustomers(response.data.listofRates);
        } else {
        }

        //   setLoading(false);
      })
      .catch(function (error) {
        // console.log(error);
      });
  }, []);

  useEffect(() => {
    const data = {
      UserID: UserID,
      AccessKey: AccessKey,
      StartDate: 0,
      EndDate: 0,
      ID: ID,
    };

    var api_config = {
      method: "post",
      url: config.base_url + "Product/GetProductByID",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(api_config)
      .then(function (response) {
        if (response.data.status_code == 1) {
          setProductName(response.data.Product.Name);
        } else {
        }

        //   setLoading(false);
      })
      .catch(function (error) {
        // console.log(error);
      });
  }, []);

  const handleRateChange = (index, value) => {
    const newList = [...listOfCustomers];
    newList[index].rate = value;
    setListOfCustomers(newList);
    updateNewPrice(index, value, newList[index].adjustmentType);
  };

  const handleAdjustmentTypeChange = (index, value) => {
    const newList = [...listOfCustomers];
    newList[index].adjustmentType = value;
    setListOfCustomers(newList);
    updateNewPrice(index, newList[index].rate, value);
  };

  const updateNewPrice = (index, rateValue, type) => {
    const newList = [...listOfCustomers];

    const salePrice = listOfCustomers[index].Selling_Rate || 0;

    let newPrice;
    if (type === "%") {
      newPrice = salePrice * (1 + rateValue / 100);
    } else {
      newPrice = salePrice + rateValue;
    }

    newList[index].newPrice = +NumberFormat(newPrice);
    setListOfCustomers(newList);

    return {
      UserID: UserID,
      AccessKey: AccessKey,
      ProductID: listOfCustomers[index].ProductID || "",
      SalePrice: newPrice,
    };
  };

  const updatePrices = () => {
    const data = {
      UserID: UserID,
      AccessKey: AccessKey,
      ProductID: ID,
      ListofCustomerRates: listOfCustomers.map((product) => ({
        ID: product.ID,
        Selling_Rate:
          product.newPrice !== undefined
            ? product.newPrice
            : product.Selling_Rate,
      })),
    };
    const api_config = {
      method: "post",
      url: config.base_url + "Product/UpdateCustomerRateByProductID",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then((response) => {
        if (response.data.status_code === 1) {
          setLoading(false);
          message.success("Prices updated for the single customer.");
          navigate(`/products/manage-product`);
        } else {
          message.error("Failed to update prices.");
        }
      })
      .catch((error) => {
        console.log(error);
        message.error("An error occurred while updating prices.");
      });
  };
  return (
    <>
      <LoginChecked />
      <div className="right-side-contents">
        <div className="page-content">
          <div className="page-header">
            <h3 className="page-title">
              <NavLink to="/products/manage-product">
                <ChevronLeftIcon />
              </NavLink>
              {`Custom Rates For Customers of (${ProductName})`}
            </h3>
          </div>

          <Form
            name="basic"
            layout="horizontal"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            autoComplete="off"
          >
            {loading ? (
              <Row>
                <Col xs={24} md={16}>
                  <Skeleton active />
                  <Skeleton active />
                </Col>
              </Row>
            ) : (
              <div className="table-responsive">
                <table className="add-sale-table">
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Customer Name</th>
                      <th>Existing Price</th>
                      <th>Price Variation</th>
                      <th>New Price</th>
                    </tr>
                  </thead>
                  <tbody>
                    {listOfCustomers.map((item, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{item.Selected_Account.Name}</td>
                        <td>{item.Selling_Rate}</td>
                        <td>
                          <Form.Item style={{ marginBottom: "0px" }}>
                            <Space direction="vertical">
                              <InputNumber
                                addonAfter={
                                  <Select
                                    value={item.adjustmentType || "value"}
                                    onChange={(value) =>
                                      handleAdjustmentTypeChange(index, value)
                                    }
                                    style={{ width: 60 }}
                                  >
                                    <Option value="value">RS</Option>
                                    <Option value="%">%</Option>
                                  </Select>
                                }
                                value={item.rate || 0}
                                onChange={(value) =>
                                  handleRateChange(index, value)
                                }
                              />
                            </Space>
                          </Form.Item>
                        </td>
                        <td>
                          <Form.Item style={{ marginBottom: "0px" }}>
                            <Input
                              style={{ pointerEvents: "none" }}
                              value={
                                item.newPrice !== undefined
                                  ? NumberFormat(item.newPrice)
                                  : 0
                              }
                              placeholder={item.Sale_Price}
                            />
                          </Form.Item>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </Form>
          <div
            className="header-actions"
            style={{
              marginTop: "20px",
              marginRight: "60px",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Space>
              <Button
                type="primary"
                size="large"
                shape="round"
                onClick={updatePrices}
              >
                Update Prices
              </Button>
            </Space>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductRates;
