import React, { useEffect, useState } from "react";
import axios from "axios";
import config from "../../config";
import LoginChecked from "../Shared/LoginChecked";
import {
  Space,
  Card,
  Row,
  Col,
  Input,
  Form,
  Spin,
  Button,
  DatePicker,
  message
} from "antd";
import moment from "moment";

function DipSettings() {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);
  const [dipsData, setDipsData] = useState([]);
  const [tankQuantities, setTankQuantities] = useState({});
  const [adjustmentRates, setAdjustmentRates] = useState({});
  const [Dated, setDated] = useState(moment().format("YYYY-MM-DD"));

  const AccessKey = localStorage.getItem("AccessKey");
  const UserID = localStorage.getItem("ID");

  useEffect(() => {
    document.title = "Add Dip";
    setLoading(true);
    const data = {
      UserID: UserID,
      AccessKey: AccessKey,
      Dated: Dated,
    };

    const api_config = {
      method: "post",
      url: config.base_url + "Product/GetProductTanks",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
    
      .then(response => {
        
        if (response.data.status_code === 1) {
          const products = response.data.ListofProducts;
          setDipsData(products);

          const initialRates = products.reduce((acc, product) => {
            acc[product.ID] = product.Last_Purchase_Price;
            return acc;
          }, {});
          setAdjustmentRates(initialRates);

          const initialQuantities = products.reduce((acc, product) => {
            acc[product.ID] = product.ListofTanks.reduce((tankAcc, tank) => {
              tankAcc[tank.ID] = { stock: 0, ReadingValue: 0 };
              return tankAcc;
            }, {});
            return acc;
          }, {});
          setTankQuantities(initialQuantities);
        }
        setLoading(false);
      })
      .catch(error => {
        console.error(error);
        setLoading(false);
      });
  }, [Dated]);

  const handleStockChange = (productId, tankId, field, value) => {
    setTankQuantities(prevQuantities => ({
      ...prevQuantities,
      [productId]: {
        ...prevQuantities[productId],
        [tankId]: {
          ...prevQuantities[productId][tankId],
          [field]: Number(value) || 0
        }
      }
    }));
  };

  const handleAdjustmentRateChange = (productId, value) => {
    setAdjustmentRates(prevRates => ({
      ...prevRates,
      [productId]: value
    }));
  };

  const handelSaveDip = () => {
    if (!Dated) {
      message.error("Please Select Date");
      return;
    }

    const listOfProductDIP = dipsData.map(product => ({
      ProductID: product.ID,
      AdjustmentRate: adjustmentRates[product.ID] || product.Last_Purchase_Price,
      TotalSystemStock: product.ListofTanks.reduce(
        (total, tank) => total + tank.UseableCapacity,
        0
      ),
      ListofDipReading: product.ListofTanks.map(tank => ({
        Reading: tankQuantities[product.ID]?.[tank.ID]?.ReadingValue || 0,
        StockInLtr: tankQuantities[product.ID]?.[tank.ID]?.stock || 0,
        TankID: tank.ID
      }))
    }));

    const data = {
      UserID: UserID,
      AccessKey: AccessKey,
      Date: Dated,
      ListofProductDIP: listOfProductDIP
    };



    const api_config = {
      method: "post",
      url: config.base_url + "Product/AdjustDIP",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    setLoadingSave(true);
    axios(api_config)
      .then(response => {
        if (response.data.status_code === 1) {
          message.success(response.data.status_message);
        } else {
          message.error(response.data.status_message);
        }
        setLoadingSave(false);
      })
      .catch(error => {
        console.error(error);
        setLoadingSave(false);
      });
  };

  const calculateProductTotalQuantity = (productId) => {
    const productQuantities = tankQuantities[productId];
    if (!productQuantities) return 0;

    return Object.values(productQuantities).reduce(
      (total, tank) => total + (tank.stock || 0),
      0
    );
  };

  const disabledDate = (current) => {
    return current && current > moment().endOf('day');
  };

  return (
    <>
      <LoginChecked />
      <div className="right-side-contents">
        <div className="page-content">
          <div className="page-header">
            <h6 className="page-title">Add DIP</h6>
          </div>
          <div className="filter-wrap">
            <Form>
              <Row>
                <Space>
                  <Form.Item>
                    <DatePicker
                      // value={Dated ? moment(Dated, "YYYY-MM-DD") : null}
                      onChange={(date, value) => setDated(value)}
                      disabledDate={disabledDate}
                    />
                  </Form.Item>
                </Space>
              </Row>
            </Form>
          </div>
          {loading ? (
            <div style={{height: "100vh", width: "100%", display: "flex", alignItems: "center", justifyContent: "center"}}>
              <Spin />
            </div>
          ) : (
            <Form>
              {dipsData.map(product => (
                <Card key={product.ID} style={{ marginBottom: "20px" }}>
                  <Row>
                    <Col span={8}>
                      <h3>{product.Name}</h3>
                    </Col>
                    <Col md={8} xs={24}>
                      <Space>
                        <p>Available System Quantity:</p>
                        <strong>
                          {product.Balance}
                        </strong>
                      </Space>
                    </Col>
                    <Col span={8} style={{ marginTop: "10px" }}>
                      <Space>
                        <label>Adjustment Rate</label>
                        <Input
                        // value={product.Last_Purchase_Price}
                        value={adjustmentRates[product.ID] || ""}
                        onChange={(e) => handleAdjustmentRateChange(product.ID, e.target.value)}
                         />
                      </Space>
                    </Col>
                  </Row>

                  <Row>
                    <Col md={8} xs={7}>
                      <h3 className="content-heading">Tank No.</h3>
                    </Col>
                    <Col md={8} xs={9}>
                      <h3 className="content-heading">Dip Reading</h3>
                    </Col>
                    <Col span={8}>
                      <h3 className="content-heading">Stock in Ltr.</h3>
                    </Col>
                  </Row>

                  {product.ListofTanks.length > 0 ? (
                    product.ListofTanks.map(tank => (
                      <Row key={tank.ID} className="data-row">
                        <Col span={8}>
                          <p className="content-heading">{tank.TankNo}</p>
                        </Col>
                        <Col span={8}>
                          <Input
                            style={{ width: "90%" }}
                            defaultValue={0}
                            onChange={(e) => handleStockChange(product.ID, tank.ID, 'ReadingValue', e.target.value)}
                          />
                        </Col>
                        <Col span={8}>
                          <Input
                            style={{ width: "90%" }}
                            defaultValue={0}
                            onChange={(e) => handleStockChange(product.ID, tank.ID, 'stock', e.target.value)}
                          />
                        </Col>
                      </Row>
                    ))
                  ) : (
                    <Row className="data-row">
                      <Col span={8}>
                        <p className="content-heading">No Tanks Available</p>
                      </Col>
                      <Col span={16}>
                        <p className="content-heading">-</p>
                      </Col>
                    </Row>
                  )}

                  <Row justify="end" style={{ marginTop: "5px" }}>
                    <Col md={4} xs={12}>
                      <h3 className="content-top">Total Quantity</h3>
                    </Col>
                    <Col md={7} xs={10}>
                      <Input value={calculateProductTotalQuantity(product.ID)} readOnly />
                    </Col>
                    <Col md={1} xs={10}></Col>
                  </Row>
                </Card>
              ))}

              <Row justify={"end"}>
                <Col md={7}>          
                  <Button
                    type="primary"
                    size="large"
                    shape="round"
                    style={{marginBottom:"50px"}}
                    onClick={handelSaveDip}
                    disabled={!Dated || loadingSave}
                  >
                    Save Dip Adjustment 
                    {loadingSave ?<Spin size="small" /> : ''}
                  </Button>
                </Col>   
              </Row> 
            </Form>
          )}
        </div>
      </div>
    </>
  );
}

export default DipSettings;
