import React, { useState, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import axios from "axios";
import config from "../../config";
import { ChevronLeftIcon } from "@heroicons/react/24/solid";
import { Row, Col, Button, Form, Input, message, Spin } from "antd";
import {
  BankOutlined,
  LockOutlined,
  MailOutlined,
  PhoneOutlined,
} from "@ant-design/icons";

const AddNewPump = () => {
  const [loading, setLoading] = useState(false);
  const UserID = localStorage.getItem("AdminID");
  const AccessKey = localStorage.getItem("AdminAccessKey");
  const MemberType = localStorage.getItem("MemberType");
  const [messageApi, contextHolder] = message.useMessage();
  const [data, setData] = useState("");
  let navigate = useNavigate();
  useEffect(() => {
    document.title = "Add Pump";
  }, []);

  const handelSubmit = (formData) => {
    setLoading(true);
    formData["Mobile"] = formData.Mobile.startsWith("0")
      ? "92" + formData.Mobile.slice(1)
      : formData.Mobile.startsWith("+92")
      ? formData.Mobile.slice(1)
      : formData.Mobile;

    const data = {
      UserID: UserID,
      AccessKey: AccessKey,
      ...formData,
    };
    var api_config = {
      method: "post",
      url: config.base_url + "AdminUser/RegisterPetrolPump",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(api_config)
      .then(function (response) {
        if (response.data.status_code == 1) {
          setLoading(false);
          messageApi.open({
            type: "success",
            content: response.data.status_message,
          });

          navigate("/controlbase/petrolpump");
        } else {
          // Handle error
          setLoading(false);
          message.error(response.data.status_message);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <>
      <>
        {/* {contextHolder} */}

        <div className="right-side-contents">
          <div className="page-content">
            <Row>
              <Col span={12}>
                <div className="page-header">
                  <h6 className="page-title">
                    <NavLink to="/controlbase/petrolpump">
                      <ChevronLeftIcon />
                    </NavLink>
                    Add New Pump
                  </h6>
                </div>
              </Col>
            </Row>
            <Row gutter={[24, 24]}>
              <Col xs={24} md={12}>
                <Form
                  name="basic"
                  layout="horizontal"
                  labelCol={{
                    span: 8,
                  }}
                  wrapperCol={{
                    span: 16,
                  }}
                  initialValues={{
                    remember: true,
                  }}
                  onFinish={handelSubmit}
                  autoComplete="off"
                  size="large"
                >
                  <Form.Item
                    label="Petrol Pump Name"
                    name="Name"
                    rules={[
                      {
                        required: true,
                        message: "Please enter Petrol Pump Name",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Enter Petrol Pump Name"
                      prefix={<BankOutlined />}
                    />
                  </Form.Item>
                  <Form.Item
                    label="Mobile No"
                    name="Mobile"
                    rules={[
                      {
                        required: true,
                        message: "Please enter Mobile No",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Enter Mobile No"
                      prefix={<PhoneOutlined />}
                    />
                  </Form.Item>
                  <Form.Item
                    label="Email Address"
                    name="Email"
                    rules={[
                      {
                        required: true,
                        message: "Please enter Email Address",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Enter Email Address"
                      prefix={<MailOutlined />}
                    />
                  </Form.Item>
                  <Form.Item
                    label="Password"
                    name="Password"
                    rules={[
                      {
                        required: true,
                        message: "Please enter Password",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Enter Password"
                      prefix={<LockOutlined />}
                    />
                  </Form.Item>

                  <Form.Item
                    wrapperCol={{
                      offset: 8,
                      span: 16,
                    }}
                  >
                    <Button
                      type="primary"
                      size="large"
                      shape="round"
                      htmlType="submit"
                      loading={loading}
                    >
                      {loading ? <Spin /> : "Add Petrol Pump"}
                    </Button>
                  </Form.Item>
                </Form>
              </Col>
            </Row>
          </div>
        </div>
      </>
    </>
  );
};

export default AddNewPump;
