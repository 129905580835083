const host = window.location.hostname;

let base_url;
let images;


if (host == 'localhost' || host == 'nd.petrol-pump.com') {
 // base_url = "http://localhost:52476/api/";
  base_url = "https://developerapi.petrol-pump.com/api/";
  // base_url = "https://api.fuelbook.pk/api/";
  images = "https://api.fuelbook.pk/images/";
}
else {
  base_url = "https://api.fuelbook.pk/api/";
  images = "https://api.fuelbook.pk/images/"
  // images = "https://app.fulebook.pk/images/"
}

const config = {


  base_url,
  images,
  date_format: "DD MMM YYYY"


};
export default config;