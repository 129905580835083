import React, { useEffect, useState } from "react";
import axios from "axios";
import config from "../../../config";
import {
  PDFViewer,
  Page,
  Text,
  View,
  Document,
  StyleSheet,
} from "@react-pdf/renderer";
import moment from "moment";
import PrintHeaderAccount from "../../../Pdf/PrintHeaderAccount";
import PrintFooter from "../../../Pdf/PrintFooter";
import { calculateColumnTotal } from "../../Shared/Utility";
import { Spin } from "antd";
import pdfStyles from "../../../Pdf/pdfStyles";
import { PrintFormateNumber } from "../../../Pdf/PrintFormateNumber";

function PrintAccountLedger() {
  const AccessKey = localStorage.getItem("AccessKey");
  const UserID = localStorage.getItem("ID");
  const [loading, setLoading] = useState(false);
  const [AccountName, setAccountName] = useState("");
  const [listofLedger, SetlistofLedger] = useState([]);
  const urlParams = new URLSearchParams(window.location.search);
  const account_id = urlParams.get("account_id");
  const PrintType = urlParams.get("PrintType");
  const TitleType = urlParams.get("TitleType");
  const StartDate = urlParams.get("StartDate");
  const Discription = urlParams.get("Discription");
  const Quantity = urlParams.get("Quantity");
  const Rate = urlParams.get("Rate");
  const Product = urlParams.get("Product");

  const EndDate = urlParams.get("EndDate");
  const account_name = urlParams.get("Name");
  const today = new Date();
  const date = moment().format("DD MMM YYYY");
  const [lastBalance, setLastBalance] = useState(null);
  const [firstBalance, setFirstBalance] = useState(null);
  const [firstDate, setFirstDate] = useState(null);

  const [ListOfLedgerOld, setListOfLedgerOld] = useState([]);

  useEffect(() => {
    setLoading(true);
    const data = {
      UserID: UserID,
      AccessKey: AccessKey,
      PageSize: 100000,
      StartDate: StartDate,
      EndDate: EndDate,
      PageNo: 1,
      ID: account_id,
    };

    var api_config = {
      method: "post",
      url: config.base_url + "account/GetAccountLedger",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(api_config)
      .then(function (response) {
       
        if (response.data.status_code == 1) {
          // Handle success
          const ledgerData = response.data.listofLedger;
          setListOfLedgerOld(response.data.listofLedger);

          if (ledgerData && ledgerData.length > 0) {
            const firstItem = ledgerData[0];
            setFirstBalance(firstItem.Balance);
            setFirstDate(firstItem.Transaction_Date);

            const lastItem = ledgerData[ledgerData.length - 1];
            setLastBalance(lastItem.Balance);
          }

          setLoading(false);
        } else {
          setLoading(false);
          // Handle error
        }
        //   setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    setLoading(true);
    const data = {
      UserID: UserID,
      AccessKey: AccessKey,
      ID: account_id,
    };

    var api_config = {
      method: "post",
      url: config.base_url + "account/GetAccountbyID",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(api_config)
      .then(function (response) {
        if (response.data.status_code == 1) {
          setLoading(false);
          setAccountName(response.data.Name);
        } else {
          // setLoadingGetAccount(false);
        }
        //   setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    if (PrintType === "Sale") {
      const filtered = ListOfLedgerOld.filter(
        (item) => item.Reference_Type === "Sale"
      );
      SetlistofLedger(filtered);
    } else if (PrintType === "Voucher") {
      const filtered = ListOfLedgerOld.filter(
        (item) => item.Reference_Type === "Voucher"
      );
      SetlistofLedger(filtered);
    } else {
      SetlistofLedger(ListOfLedgerOld);
    }
  }, [ListOfLedgerOld, PrintType]);
  let totalWidth = 695;
  let remainingWidth = totalWidth; 


if (Discription === "false" || !Discription) remainingWidth -= 130; 
if (Rate === "false" || !Rate) remainingWidth -= 50; 
if (Quantity === "false" || !Quantity) remainingWidth -= 50; 
if(Product === "false" || !Product) remainingWidth -=50;


let remainingsaleWidth = 560;


if (Rate === "false" || !Rate) remainingsaleWidth -= 50; 
if (Quantity === "false" || !Quantity) remainingsaleWidth -= 50; 
if (Discription === "false" || !Discription) remainingsaleWidth -= 130;
if(Product === "false" || !Product) remainingWidth -=50;


  const generateData = () => {
    if (listofLedger.length > 0) {
      if (PrintType == "All") {
        return listofLedger.map((item, index) => {
          return (
            <React.Fragment key={index}>
              {index > 0 && index % 36 === 0 && (
                <View style={pdfStyles.pageBreak} />
              )}
              <View key={index} style={pdfStyles.row}>
                <View style={[pdfStyles.td, { width: 30 }]}>
                  <Text style={pdfStyles.tdValue}>{index + 1}</Text>
                </View>
                <View style={[pdfStyles.td, { width: 80 }]}>
                  <Text style={pdfStyles.tdValue}>
                    {moment(item.Transaction_Date).format(config.date_format)}
                  </Text>
                </View>
                {Product !== "false" && Product && (
                  <View style={[pdfStyles.td, { width: 50 }]}>
                    <Text style={pdfStyles.tdValue}>{item.Product}</Text>
                  </View>
                )}
                {Rate !== "false" && Rate && (
                  <View style={[pdfStyles.td, { width: 50 }]}>
                    <Text style={pdfStyles.tdValue}>{item.Rate}</Text>
                  </View>
                )}
                {Quantity !== "false" && Quantity && (
                  <View style={[pdfStyles.td, { width: 50 }]}>
                    <Text style={pdfStyles.tdValue}>{item.Quantity}</Text>
                  </View>
                )}
                {
                  Discription !== "false" && Discription &&
                  <View style={[pdfStyles.td, { width: 130 }]}>
                  <Text style={pdfStyles.tdValue}>{item.Description}</Text>
                </View>
                }
               
                <View style={[pdfStyles.td, { width: 100 }]}>
                  <Text style={pdfStyles.tdValue}>{item.Vehicle_No}</Text>
                </View>
                <View style={[pdfStyles.td, { width:70 }]}>
                  <Text style={pdfStyles.tdValue}>{item.Receipt_No}</Text>
                </View>
                <View style={[pdfStyles.td, { textAlign: "right", width: 65 }]}>
                  <Text style={pdfStyles.tdValue}>
                    {PrintFormateNumber(item.Debit, 0)}
                  </Text>
                </View>
                <View style={[pdfStyles.td, { textAlign: "right", width: 70 }]}>
                  <Text style={pdfStyles.tdValue}>
                    {PrintFormateNumber(item.Credit, 0)}
                  </Text>
                </View>
                <View style={[pdfStyles.td, { textAlign: "right", width: 70 }]}>
                  <Text style={pdfStyles.tdValue}>
                    {PrintFormateNumber(item.Balance, 0)}
                  </Text>
                </View>
                <View style={[pdfStyles.td, { width: 70 }]}>
                  <Text style={pdfStyles.tdValue}>{item.BalanceType}</Text>
                </View>
              </View>
            </React.Fragment>
          );
        });
      } else if (PrintType == "Sale") {
        return listofLedger.map((item, index) => {
          if (item.Reference_Type == "Sale") {
            return (
              <View key={index} style={pdfStyles.row}>
                <View style={[pdfStyles.td, { width: 30 }]}>
                  <Text style={pdfStyles.tdValue}>{1 + index + 1}</Text>
                </View>
                <View style={[pdfStyles.td, { width: 80 }]}>
                  <Text style={pdfStyles.tdValue}>
                    {moment(item.Transaction_Date).format(config.date_format)}
                  </Text>
                </View>
                {Product !== "false" && Product && (
                  <View style={[pdfStyles.td, { width: 50 }]}>
                    <Text style={pdfStyles.tdValue}>{item.Product}</Text>
                  </View>
                )}
                {Rate !== "false" && Rate && (
                  <View style={[pdfStyles.td, { width: 50 }]}>
                    <Text style={pdfStyles.tdValue}>{item.Rate}</Text>
                  </View>
                )}
                {Quantity !== "false" && Quantity && (
                  <View style={[pdfStyles.td, { width: 50 }]}>
                    <Text style={pdfStyles.tdValue}>{item.Quantity}</Text>
                  </View>
                )}
                {
                  Discription !== "false" && Discription &&
                  <View style={[pdfStyles.td, { width: 130 }]}>
                  <Text style={pdfStyles.tdValue}>{item.Description}</Text>
                </View>
                }
                <View style={[pdfStyles.td, { width: 100 }]}>
                  <Text style={pdfStyles.tdValue}>{item.Vehicle_No}</Text>
                </View>
                <View style={[pdfStyles.td, { width: 70 }]}>
                  <Text style={pdfStyles.tdValue}>{item.Receipt_No}</Text>
                </View>
                <View style={[pdfStyles.td, { textAlign: "right", width: 65 }]}>
                  <Text style={pdfStyles.tdValue}>
                    {PrintFormateNumber(item.Debit, 0)}
                  </Text>
                </View>
                <View style={[pdfStyles.td, { textAlign: "right", width: 70 }]}>
                  <Text style={pdfStyles.tdValue}>
                    {PrintFormateNumber(item.Credit, 0)}
                  </Text>
                </View>
                <View style={[pdfStyles.td, { textAlign: "right", width: 70 }]}>
                  <Text style={pdfStyles.tdValue}></Text>
                </View>
                <View style={[pdfStyles.td, { width: 70 }]}>
                  <Text style={pdfStyles.tdValue}></Text>
                </View>
              </View>
            );
          }
        });
      } else if (PrintType == "Voucher") {
        return listofLedger.map((item, index) => {
          if (item.Reference_Type == "Voucher") {
            return (
              <View key={index} style={pdfStyles.row}>
                <View style={[pdfStyles.td, { width: 30 }]}>
                  <Text style={pdfStyles.tdValue}>{index + 1}</Text>
                </View>
                <View style={[pdfStyles.td, { width: 80 }]}>
                  <Text style={pdfStyles.tdValue}>
                    {moment(item.Transaction_Date).format(config.date_format)}
                  </Text>
                </View>
                {Rate !== "false" && Rate && (
                  <View style={[pdfStyles.td, { width: 50 }]}>
                    <Text style={pdfStyles.tdValue}>{item.Rate}</Text>
                  </View>
                )}
                {Quantity !== "false" && Quantity && (
                  <View style={[pdfStyles.td, { width: 50 }]}>
                    <Text style={pdfStyles.tdValue}>{item.Quantity}</Text>
                  </View>
                )}
                {
                  Discription !== "false" && Discription &&
                  <View style={[pdfStyles.td, { width: 130 }]}>
                  <Text style={pdfStyles.tdValue}>{item.Description}</Text>
                </View>
                }
                <View style={[pdfStyles.td, { width: 100 }]}>
                  <Text style={pdfStyles.tdValue}>{item.Vehicle_No}</Text>
                </View>
                <View style={[pdfStyles.td, { width: 70 }]}>
                  <Text style={pdfStyles.tdValue}>{item.Receipt_No}</Text>
                </View>
                <View style={[pdfStyles.td, { textAlign: "right", width: 65 }]}>
                  <Text style={pdfStyles.tdValue}>
                    {PrintFormateNumber(item.Debit, 0)}
                  </Text>
                </View>
                <View style={[pdfStyles.td, { textAlign: "right", width: 70 }]}>
                  <Text style={pdfStyles.tdValue}>
                    {PrintFormateNumber(item.Credit, 0)}
                  </Text>
                </View>
                <View style={[pdfStyles.td, { textAlign: "right", width: 70 }]}>
                  <Text style={pdfStyles.tdValue}>
                    {PrintFormateNumber(item.Balance, 0)}
                  </Text>
                </View>
                <View style={[pdfStyles.td, { width: 70 }]}>
                  <Text style={pdfStyles.tdValue}>{item.BalanceType}</Text>
                </View>
              </View>
            );
          }
        });
      }
    }
  };

  const MyDocument = () => (
    <Document>
      <Page size="A4" style={pdfStyles.page}>
        <PrintHeaderAccount />
        <View style={pdfStyles.section}>
          <View style={pdfStyles.title}>
            {PrintType === "Sale" ? (
              <Text style={pdfStyles.pagetitle}>
                Ledger Sales Invoice for {AccountName}{" "}
              </Text>
            ) : PrintType === "Voucher" ? (
              <Text style={pdfStyles.pagetitle}>
                Ledger Voucher Invoice of {AccountName}{" "}
              </Text>
            ) : (
              <Text style={pdfStyles.pagetitle}>
                Ledger details of {AccountName}{" "}
              </Text>
            )}

            <Text style={{ fontSize: "10pt" }}>{date}</Text>
          </View>
          <View style={pdfStyles.table}>
            <View style={pdfStyles.tableHead}>
              <View style={[pdfStyles.th, { width: 30 }]}>
                <Text style={pdfStyles.thValue}>SR#</Text>
              </View>
              <View style={[pdfStyles.th, { width: 80 }]}>
                <Text style={pdfStyles.thValue}>Dated.</Text>
              </View>
              {Product !== "false" && Product && (
                <View style={[pdfStyles.th, { width: 50 }]}>
                  <Text style={pdfStyles.thValue}>Product</Text>
                </View>
              )}
              
              {Rate !== "false" && Rate && (
                <View style={[pdfStyles.th, { width: 50 }]}>
                  <Text style={pdfStyles.thValue}>Rate</Text>
                </View>
              )}

              {Quantity !== "false" && Quantity && (
                <View style={[pdfStyles.th, { width: 50 }]}>
                  <Text style={pdfStyles.thValue}>Quantity</Text>
                </View>
              )}
              {Discription !== "false" && Discription && (
                <View style={[pdfStyles.th, { width: 130 }]}>
                <Text style={pdfStyles.thValue}>Description</Text>
              </View>
              )}
              
              <View style={[pdfStyles.th, { width: 100 }]}>
                <Text style={pdfStyles.thValue}>Vehicle No</Text>
              </View>
              <View style={[pdfStyles.th, { width: 70 }]}>
                <Text style={pdfStyles.thValue}>Receipt No</Text>
              </View>
              <View style={[pdfStyles.th, { textAlign: "right", width: 65 }]}>
                <Text style={pdfStyles.thValue}>Debit</Text>
              </View>
              <View style={[pdfStyles.th, { textAlign: "right", width: 70 }]}>
                <Text style={pdfStyles.thValue}>Credit</Text>
              </View>
              <View style={[pdfStyles.th, { textAlign: "right", width: 70 }]}>
                <Text style={pdfStyles.thValue}>Balance</Text>
              </View>
              <View style={[pdfStyles.th, { width: 70 }]}>
                <Text style={pdfStyles.thValue}>Type</Text>
              </View>
            </View>
            {PrintType === "Sale" && (
              <View style={pdfStyles.row}>
              <View style={[pdfStyles.td, { width: 30 }]}>
                <Text style={pdfStyles.tdValue}>1</Text>
              </View>
              <View style={[pdfStyles.td, { width: 80 }]}>
                <Text style={pdfStyles.tdValue}>
                {moment(firstDate).format(config.date_format)}
                </Text>
              </View>
              {Product !== "false" && Product && (
                <View style={[pdfStyles.th, { width: 50 }]}>
                  <Text style={pdfStyles.thValue}></Text>
                </View>
              )}
              {Rate !== "false" && Rate && (
                <View style={[pdfStyles.td, { width: 50 }]}>
                  <Text style={pdfStyles.tdValue}></Text>
                </View>
              )}
              {Quantity !== "false" && Quantity && (
                <View style={[pdfStyles.td, { width: 50 }]}>
                  <Text style={pdfStyles.tdValue}></Text>
                </View>
              )}
              {
                Discription !== "false" && Discription &&
                <View style={[pdfStyles.td, { width: 130 }]}>
                <Text style={pdfStyles.tdValue}></Text>
              </View>
              }
              <View style={[pdfStyles.td, { width: 100 }]}>
                <Text style={pdfStyles.tdValue}></Text>
              </View>
              <View style={[pdfStyles.td, { width: 70 }]}>
                <Text style={pdfStyles.tdValue}></Text>
              </View>
              <View style={[pdfStyles.td, { textAlign: "right", width: 65 }]}>
                <Text style={pdfStyles.tdValue}>
                {PrintFormateNumber(firstBalance, 0)}
                </Text>
              </View>
              <View style={[pdfStyles.td, { textAlign: "right", width: 70 }]}>
                <Text style={pdfStyles.tdValue}>
                  
                </Text>
              </View>
              <View style={[pdfStyles.td, { textAlign: "right", width: 70 }]}>
                <Text style={pdfStyles.tdValue}></Text>
              </View>
              <View style={[pdfStyles.td, { width: 70 }]}>
                <Text style={pdfStyles.tdValue}></Text>
              </View>
            </View>
            )}
            {generateData()}

            {PrintType === "All" && (
              <View>
                <View style={pdfStyles.tableHead}>
                  <View
                    style={[pdfStyles.th, { width: remainingWidth, textAlign: "right" }]}
                  >
                    <Text style={pdfStyles.thValue}>Total</Text>
                  </View>
                  <View
                    style={[pdfStyles.th, { width: 70, textAlign: "right" }]}
                  >
                    <Text style={pdfStyles.thValue}>
                      {PrintFormateNumber(lastBalance, 0)}
                    </Text>
                  </View>
                  <View style={[pdfStyles.th, { width: 70 }]}>
                    <Text style={pdfStyles.thValue}></Text>
                  </View>
                </View>
              </View>
            )}
            {PrintType === "Sale" && (
              <View>
                <View style={pdfStyles.tableHead}>
                  <View
                    style={[pdfStyles.th, { width: remainingsaleWidth, textAlign: "right" }]}
                  >
                    <Text style={pdfStyles.thValue}>Total</Text>
                  </View>
                  <View
                    style={[pdfStyles.th, { width: 65, textAlign: "right" }]}
                  >
                    <Text style={pdfStyles.thValue}>
                      {PrintFormateNumber(
                        calculateColumnTotal(listofLedger, "Debit") +
                          firstBalance,
                        0
                      )}
                    </Text>
                  </View>
                  <View style={[pdfStyles.th, { width: 70 }]}>
                    <Text style={pdfStyles.thValue}></Text>
                  </View>
                  <View style={[pdfStyles.th, { width: 70 }]}>
                    <Text style={pdfStyles.thValue}></Text>
                  </View>
                  <View style={[pdfStyles.th, { width: 70 }]}>
                    <Text style={pdfStyles.thValue}></Text>
                  </View>
                </View>
              </View>
            )}
          </View>
          <View
            style={{ marginBottom: 60, marginTop: 0, top: 10, bottom: 10 }}
            fixed
          >
            <Text
              style={{ fontSize: "8pt" }}
              render={({ pageNumber, totalPages }) =>
                `Page ${pageNumber} / ${totalPages}`
              }
            />
          </View>
        </View>
        <PrintFooter />
      </Page>
    </Document>
  );

  return (
    <>
      <div style={{ width: "100%", height: "100vh", overflow: "hidden" }}>
        {loading ? (
          <div
            style={{
              height: "100vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Spin />
          </div>
        ) : (
          <PDFViewer width="100%" height="100%" fileName="sample.pdf">
            <MyDocument />
          </PDFViewer>
        )}
      </div>
    </>
  );
}

export default PrintAccountLedger;
