import React, { useEffect, useState } from "react";
import account from "../../../assets/images/account.png";
import balance from "../../../assets/images/balance.png";
import calander from "../../../assets/images/calander.png";
import { calculateColumnTotal, NumberFormat } from "../../Shared/Utility";
import {
  Button,
  Col,
  Spin,
  Row,
  Space,
  Table,
  DatePicker,
  Form,
  Dropdown,
  message,
  Modal,
  Input,
  Select,
  Pagination,
  Tooltip,
  Divider,
  Checkbox,
} from "antd";
import LoginChecked from "../../Shared/LoginChecked";
import axios from "axios";
import config from "../../../config";
import { NavLink, useParams } from "react-router-dom";
import { ChevronLeftIcon } from "@heroicons/react/24/solid";
import moment from "moment";
import {
  DeleteOutlined,
  EditOutlined,
  FileOutlined,
  PrinterOutlined,
  DownOutlined,
  MailOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import AccountDropDown from "../../Shared/AccountDropDown";
import dayjs from "dayjs";
import { CheckBadgeIcon, ClipboardDocumentListIcon } from "@heroicons/react/24/outline";
import { JsonToExcel } from "react-json-to-excel";

function AccountLedger() {
  const urlParams = new URLSearchParams(window.location.search);

  const account_id = urlParams.get("account_id");
  const Name = urlParams.get("Name");
  const ID = urlParams.get("ID");

  const { RangePicker } = DatePicker;
  const [OrderBy, SetOrderBy] = useState("1");
  const [Dated, setDated] = useState(null);
  const AccessKey = localStorage.getItem("AccessKey");
  const UserID = localStorage.getItem("ID");
  const [listofAccounts, SetlistofAccounts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingAccounts, setLoadingAccounts] = useState(false);
  const [listofAccount, setlistofAccount] = useState([]);
  const [IsVoucherUpdated, setIsVoucherUpdated] = useState(false);
  const [AccountID, SetAccountID] = useState(0);
  const [VoucherType, SetVoucherType] = useState("");
  const [DebitAccountID, SetDebitAccountID] = useState(0);
  const [creditAccountID, SetCreditAccountID] = useState(0);
  const [Button_Lodding, setButton_Lodding] = useState(false);
  const [selectedVoucher, setSelectedVoucher] = useState(null);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [open, setOpen] = useState(false);
  const accounts = AccountDropDown();
  const [DebitCount, setDebitCount] = useState("");
  const [CreditCount, setCreditCount] = useState("");
  const [Total, setTotal] = useState("");
  const [listofVouchers, SetlistofVouchers] = useState([]);
  const [TotalVouchers, setTotalVouchers] = useState("");
  const [debitAccountID, setdebitAccountID] = useState(0);
  const [loadingRecAccount, setLoadingRecAccount] = useState(false);
  const [RecAccount, setRecAccount] = useState({});

  const [showQuantityColumn, setShowQuantityColumn] = useState(false);
  const [showRateColumn, setShowRateColumn] = useState(false);
  const [showDescriptionColumn, setShowDescriptionColumn] = useState(true);
  const [showProduct, setShowProduct] = useState(false);
  const[openingBalnce,setOpeningBalnce]= useState("");
  const[remainingBalance,setRemainingBalance] = useState("");

  const [form] = Form.useForm();

  const [AccountIdData, setAccountIdData] = useState("");
  const [loadingVoucher, setLoadingVoucher] = useState(false);
  const [StartDate, SetStartDate] = useState("");
  const [EndDate, SetEndDate] = useState("");
  const [VoucherMode, setVoucherMode] = useState("");

  // pagination
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const [totalRecords, setTotalRecords] = useState(0);

  const [ExcelArray, setExcelArray] = useState([]);

  let params = useParams();

  useEffect(() => {
    setIsVoucherUpdated(false);
    setLoading(true);
    const data = {
      UserID: UserID,
      AccessKey: AccessKey,
      PageNo: pageNo,
      PageSize: pageSize,
      StartDate: StartDate,
      EndDate: EndDate,
      ID: account_id,
    };

    var api_config = {
      method: "post",
      url: config.base_url + "account/GetAccountLedger",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
       
        if (response.data.status_code == 1) {
          // Handle success
          SetlistofAccounts(response.data.listofLedger);
          setOpeningBalnce(response.data.listofLedger[0].Balance)
          setRemainingBalance(response.data.listofLedger[response.data.listofLedger.length - 1].Balance)
          setTotalRecords(response.data.totalRecords);
          const exceldata =response.data.listofLedger;
          if(exceldata !=null && exceldata.length > 0){
            const listOfLedgerNew = exceldata.map((item,index)=>({
              Serial_No:index+1,
              Dated:dayjs(item.Transaction_Date).format("DD-MMMM-YYYY"),
              Discription:item.Description,
              VehicleNo:item.Vehicle_No,
              ReceiptNo:item.Receipt_No,
              Debit:item.Debit,
              Credit:item.Credit,
              Balance:item.Balance,
              Document_Type:item.Reference_Type

            }))
            setExcelArray(listOfLedgerNew);
          }

          setLoading(false);
        } else {
          setLoading(false);
          // Handle error
        }
        //   setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [StartDate, EndDate, pageSize, pageNo, IsVoucherUpdated]);



  useEffect(() => {
    document.title = "Account Ledger";
    setIsVoucherUpdated(false);
    setLoading(true);
    const data = {
      UserID: UserID,
      AccessKey: AccessKey,
      StartDate: StartDate,
      EndDate: EndDate,
      OrderBy: OrderBy,
      PageNo: pageNo,
      PageSize: pageSize,
      AccountID: AccountID,
      VoucherType: VoucherType,
      Debit_Account_ID: DebitAccountID,
      Credit_Account_ID: creditAccountID,
    };

    var api_config = {
      method: "post",
      url: config.base_url + "Voucher/GetVouchers",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(api_config)
      .then(function (response) {
      
        if (response.data.status_code == 1) {
          // Handle success
          SetlistofVouchers(response.data.listofVouchers);
          setTotalVouchers(response.data.TotalVouchers);
          setDebitCount(response.data.DebitCount);
          setCreditCount(response.data.CreditCount);
          setTotal(response.data.Total);

          setLoading(false);
        } else {
          setLoading(false);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [StartDate, EndDate]);

  useEffect(() => {
    setLoadingAccounts(false);

    const data = {
      UserID: UserID,
      AccessKey: AccessKey,
      OrderBy: 1,
      PageNo: 1,
      PageSize: 10000,
      BalanceType: "",
      Account_Type_ID: "",
      Dated: Dated,
      Name: "",
    };

    var api_config = {
      method: "post",
      url: config.base_url + "account/GetAccounts",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(api_config)
      .then(function (response) {
        if (response.data.status_code == 1) {
          // Handle success

          let list = response.data.listofAccounts;
          const result = list.map((item) => ({
            value: item.ID,
            label: item.Name,
          }));

          setlistofAccount(result);

          setLoadingAccounts(false);
        } else {
          // Handle error
        }
        //   setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  useEffect((item) => {
    setdebitAccountID(item);
    setLoadingRecAccount(true);
    const data = {
      UserID: UserID,
      AccessKey: AccessKey,
      ID: item,
    };

    var api_config = {
      method: "post",
      url: config.base_url + "account/GetAccountbyIDWithBalance",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(api_config)
      .then(function (response) {
       
        if (response.data.status_code == 1) {
          setRecAccount(response.data);
          setLoadingRecAccount(false);
          setLoading(false);
        } else {
          // Handle error
          // setLoadingRecAccount(false);
        }
        //   setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    setLoading(true);
    const data = {
      UserID: UserID,
      AccessKey: AccessKey,
      ID: account_id,
    };

    var api_config = {
      method: "post",
      url: config.base_url + "account/GetAccountbyIDWithBalance",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(api_config)
      .then(function (response) {
        if (response.data.status_code == 1) {
          setLoading(false);
          setAccountIdData(response.data);
        } else {
          // setLoadingGetAccount(false);
        }
        //   setLoading(false);
      })
      .catch(function (error) {
        // console.log(error);
      });
  }, []);

 
  const columns = [
    {
      title: "Sr.# ",
      dataIndex: "sr",
      key: "sr",
      render: (_, record, index) =>
        record.ID ? index + 1 + pageSize * (pageNo - 1) : null,
    },
    {
      title: "Dated",
      dataIndex: "Transaction_Date",
      key: "Transaction_Date",
      render: (_, record) =>
        moment(record.Transaction_Date).format(config.date_format),
    },
    {
      title: "Ref.#",
      dataIndex: "Reference_No",
      key: "Reference_No",
    },
    ...(showProduct
      ? [
          {
            title: "Product",
            dataIndex: "Product",
            key: "Product",
          },
        ]
      : []),

    ...(showRateColumn
      ? [
          {
            title: "Rate",
            dataIndex: "Rate",
            key: "Rate",
            align: "right",
          },
        ]
      : []),

    ...(showQuantityColumn
      ? [
          {
            title: "Quantity",
            dataIndex: "Quantity",
            key: "Quantity",
            align: "right",
            render: (_, record) => {
              return NumberFormat(record.Quantity, 0);
            },
          },
        ]
      : []),
      

    ...(showDescriptionColumn
      ? [
          {
            title: "Description",
            dataIndex: "Description",
            key: "Description",
          },
        ]
      : []),
    {
      title: "Vehicle No",
      dataIndex: "Vehicle_No",
      key: "Vehicle_No",
    },
    {
      title: "Receipt No",
      dataIndex: "Receipt_No",
      key: "Receipt_No",
    },
    {
      title: "Debit",
      dataIndex: "Debit",
      key: "Debit",
      align: "right",
      render: (_, record) => {
        return NumberFormat(record.Debit, 0);
      },
    },
    {
      title: "Credit",
      dataIndex: "Credit",
      key: "Credit",
      align: "right",
      render: (_, record) => {
        return NumberFormat(record.Credit, 0);
      },
    },
    {
      title: "Balance",
      dataIndex: "Balance",
      key: "Balance",
      align: "right",
      render: (_, record) => {
        return NumberFormat(record.Balance, 0);
      },
    },
    {
      title: "Balance Type",
      dataIndex: "BalanceType",
      key: "BalanceType",
    },
    {
      title: "Document Type",
      dataIndex: "Reference_Type",
      key: "Reference_Type",
    },
  
    {
      title: "Action",
      key: "action",
      fixed: "right",
      render: (_, record) => (
        <Space size="middle">
          <Tooltip title="Edit">
            {record.Reference_Type === "Voucher" && (
              <EditOutlined onClick={() => ShowModal(record.Reference_No)} />
            )}
            {record.Reference_Type === "Sale" && (
              <NavLink to={`/sales/edit-sales/${record.Reference_No}`}>
                <EditOutlined />
              </NavLink>
            )}
            {record.Reference_Type === "Purchase" && (
              <NavLink to={`/purchase/edit-purchase/${record.Reference_No}`}>
                <EditOutlined />
              </NavLink>
            )}
          </Tooltip>

          {record.Reference_Type === "Voucher" && (
            <NavLink
              to={`/accounts/print-voucher-list/?voucher_id=${record.Reference_No}`}
              target="_blank"
            >
              <Tooltip title="Print Voucher">
                <PrinterOutlined />
              </Tooltip>
            </NavLink>
          )}

          {record.Reference_Type === "Sale" && (
            <NavLink
              to={`/sales/printnewsale/${record.Reference_No}`}
              target="_blank"
            >
              <Tooltip title="Print Sale">
                <FileOutlined />
              </Tooltip>
            </NavLink>
          )}

          {record.Reference_Type === "Purchase" && null}
        </Space>
      ),
    },
  ];

  const items = [
    {
      key: "1",
      label: (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={`/accounts/print-account-ledger/?account_id=${account_id}&PrintType=All&StartDate=${StartDate}&EndDate=${EndDate}&Discription=${
            showDescriptionColumn
          }&Quantity=${showQuantityColumn}&Rate=${
            showRateColumn}&Product=${showProduct}`}
        >
          Print
        </a>
      ),
      icon: <PrinterOutlined />,
    },
    {
      key: "2",
      label: (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={`/accounts/print-account-ledger/?account_id=${account_id}&Name=${Name}&PrintType=Voucher&TitleType=Sales&StartDate=${StartDate}&EndDate=${EndDate}&Discription=${
            showDescriptionColumn
          }&Quantity=${showQuantityColumn}&Rate=${
            showRateColumn
          }&Product=${showProduct}`}
        >
          Print as Invoice
        </a>
      ),
      icon: <FileOutlined />,
    },
    {
      key: "3",
      label: (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={`/accounts/print-account-ledger/?account_id=${account_id}&Name=${Name}&PrintType=Sale&StartDate=${StartDate}&EndDate=${EndDate}&Discription=${showDescriptionColumn}&Quantity=${showQuantityColumn}&Rate=${showRateColumn}&Product=${showProduct}`}
        >
          Print Only Sales
        </a>
      ),
      icon: <PrinterOutlined />,
    },
    {
      key: "4",
      label: (
        <a target="_blank" rel="noopener noreferrer" href="#">
          Email to client
        </a>
      ),
      icon: <MailOutlined />,
    },
    // {
    //   key: "5",
    //   label: (
    //     <a target="_blank" rel="noopener noreferrer" href="#">
    //       Adjust Ledger
    //     </a>
    //   ),
    //   icon: <SettingOutlined />,
    // },
  ];

  const onPageChange = (page, pageSize) => {
    setPageNo(page);
  };

  const onPageSizeChange = (current, size) => {
    setPageNo(1); // Reset to the first page when changing page size
    setPageSize(size);
  };

  const handleTableChange = async (pagination, filters, sorter) => {
    // Make API request with sorting parameters
    const sortField = sorter.field;
    const sortOrder = sorter.order === "descend" ? "desc" : "asc";

    if (sortField == "Name") {
      if (sortOrder == "asc") {
        SetOrderBy(1);
      } else {
        SetOrderBy(2);
      }
    } else if (sortField == "Balance") {
      if (sortOrder == "asc") {
        SetOrderBy(3);
      } else {
        SetOrderBy(4);
      }
    }
  };
  const Update_Voucher = (formData) => {
    setButton_Lodding(true);
    setLoading(true);

    formData["Dated"] = dayjs(formData["Dated"]).format("YYYY-MM-DD");

    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      ID: selectedVoucher.ID,
      ...formData,
    };

    var api_config = {
      method: "post",
      url: config.base_url + "Voucher/UpdateVoucher",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        if (response.data.status_code == 1) {
          setIsVoucherUpdated(true);
          setButton_Lodding(false);
          setLoading(false);
          message.success(response.data.status_message);

          setOpen(false);
          setConfirmLoading(false);
        } else {
          // Handle error
          setLoading(false);
          setButton_Lodding(false);
          message.error(response.data.status_message);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const ShowModal = (RefId) => {
    setOpen(true);
    setLoadingVoucher(true);
    const data = {
      UserID: UserID,
      AccessKey: AccessKey,
      ID: RefId,
    };

    var api_config = {
      method: "post",
      url: config.base_url + "Voucher/GetVoucherByID",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(api_config)
      .then(function (response) {
        if (response.data.status_code == 1) {
          const record = response.data;

          const editVoucher = {
            voucherNo: record.VoucherNo,
            Amount: record.Amount,
            Dated: dayjs(record.Dated),
            Debit_Account_ID: record.DebitAccount?.ID,
            Credit_Account_ID: record.CreditAccount?.ID,
            Description: record.Description,
          };

          setVoucherMode(record.VoucherMode);
          form.setFieldsValue(editVoucher);

          setSelectedVoucher(record);
          setLoadingVoucher(false);
        } else {
          setLoadingVoucher(false);
        }
      })
      .catch(function (error) {
        console.log(error);
      });

    // datafetch();

    // record = response.data.vouvher;
  };

  const handleCancel = () => {
    setSelectedVoucher(null);
    setOpen(false);
  };
  const handleDateChange = (date, value) => {
    SetStartDate(value[0]);

    SetEndDate(value[1]);
  };

  const handleDate = (date, value) => {
    setDated(value);
  };

  const disabledDate = (current) => {
    return current && current > moment().endOf("day");
  };

  return (
    <>
      <LoginChecked />
      <Modal
        title="Update Voucher"
        open={open}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
        footer={null} // This will hide the footer buttons (OK and Cancel)
      >
        {loadingVoucher ? (
          <div
            style={{
              height: 270,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Spin />
          </div>
        ) : (
          <Form
            name="basic"
            layout="vertical"
            onFinish={Update_Voucher}
            autoComplete="off"
            form={form}
          >
            <Row gutter={[16, 3]}>
              <Col span={8}>
                <Form.Item name="voucherNo" label="Voucher No">
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name="Amount" label="Amount">
                  <Input />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name="Dated" label="Date">
                  <DatePicker defaultValue={Dated} onChange={handleDate} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Debit Account"
                  name="Debit_Account_ID"
                  className="input-vertical"
                >
                  <Select
                    showSearch
                    placeholder="Select Account"
                    optionFilterProp="children"
                    // onChange={onSelectDebitAccount}
                    loading={loadingAccounts}
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    //loading={accounts === false ? true : false}
                    options={accounts}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Credit Account"
                  name="Credit_Account_ID"
                  className="input-vertical"
                >
                  <Select
                    showSearch
                    placeholder="Select Account"
                    optionFilterProp="children"
                    loading={loadingAccounts}
                    // onChange={onSelectCreditAccount}
                    // onSearch={onSearch}
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    // loading={accounts === false ? true : false}
                    options={accounts}
                  />
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item name="Description" label="Description">
                  <Input />
                </Form.Item>
              </Col>

              {/* <Col span={12}>
              <Checkbox>Send Email</Checkbox>
            </Col> */}
              <Col span={24}>
                <div className="text-right">
                  <Space wrap>
                    <Button
                      type="primary"
                      size="large"
                      shape="round"
                      loading={Button_Lodding}
                      htmlType="submit"
                    >
                      Update Voucher
                    </Button>
                  </Space>
                </div>
              </Col>
            </Row>
          </Form>
        )}
      </Modal>

      {contextHolder}

      <div className="right-side-contents">
        {/* {loading ? <div className='spin-center'> <Space size="large">  <Spin size="large" /> </Space> </div> : */}
        <div className="page-content">
          <div className="page-header">
            <div>
              <div
                style={{ display: "flex", alignItems: "center", gap: "10px" }}
              >
                <NavLink to="/accounts/manage-accounts">
                  <ChevronLeftIcon style={{ width: "20px" }} />
                </NavLink>
                <span className="page-title ">{AccountIdData?.Name}</span>
                <strong className="gernal-leader"> General Ledger </strong>
                {AccountIdData?.Is_Default != "" && (
                  <CheckBadgeIcon style={{ width: "20px" }} />
                )}
              </div>
              <ul className="ledger-header">
                {AccountIdData?.AccountTypeName != "" && (
                  <>
                    <li className="vertical-divider">
                      <img src={account} alt="" className="img-icon" />
                      <div className="ledger-content">
                        <strong>Account Type </strong>{" "}
                        <span>{AccountIdData?.AccountTypeName}</span>
                      </div>
                    </li>
                  </>
                )}

                {AccountIdData?.Balance != "" && (
                  <>
                    <li className="vertical-divider">
                      <img src={balance} alt="" className="img-icon" />
                      <div className="ledger-content">
                        <strong>Current Balance </strong>{" "}
                        <span>
                          {AccountIdData?.Balance} ({AccountIdData?.BalanceType}
                          )
                        </span>
                      </div>
                    </li>
                  </>
                )}

                {AccountIdData?.OpeningDate != "" && (
                  <>
                    <li>
                      <img src={calander} alt="" className="img-icon" />
                      <div className="ledger-content">
                        <strong>Opening Date </strong>{" "}
                        <span>
                          {dayjs(AccountIdData?.OpeningDate).format(
                            config.date_format
                          )}
                        </span>
                      </div>
                    </li>
                  </>
                )}
              </ul>
            </div>

            <div className="header-actions">
            <JsonToExcel
                title="Export In Excel"
                data={ExcelArray}
                fileName={`${AccountIdData?.Name}_Accounts_Ladger_Detail_${dayjs().format("DD-MM-YYYY")}`}
                btnClassName="excel-button"
              />
              <Dropdown
                menu={{
                  items,
                }}
              >
                <Button type="dashed" size="large" shape="round">
                  <Space>
                    Options
                    <DownOutlined />
                  </Space>
                </Button>
              </Dropdown>
              
            </div>
          </div>

          <div
            className="filter-wrap"
            style={{
              display: "flex",
              alignItems: "center",
              // justifyContent: "space-between",
              gap: 20,
              flexWrap: "wrap",
            }}
          >
            <Form>
              <Form.Item>
                <RangePicker
                  allowClear
                  onChange={handleDateChange}
                  disabledDate={disabledDate}
                />
              </Form.Item>
            </Form>
            <Space direction="horizontal" style={{ marginBottom: 20 }}>
              <Checkbox
                checked={showDescriptionColumn}
                onChange={() =>
                  setShowDescriptionColumn(!showDescriptionColumn)
                }
              >
                Show Description Column
              </Checkbox>
              <Checkbox
                checked={showQuantityColumn}
                onChange={() => setShowQuantityColumn(!showQuantityColumn)}
              >
                Show Quantity Column
              </Checkbox>
              <Checkbox
                checked={showRateColumn}
                onChange={() => setShowRateColumn(!showRateColumn)}
              >
                Show Rate Column
              </Checkbox>
              <Checkbox
                checked={showProduct}
                onChange={() => setShowProduct(!showProduct)}
              >
                Show Product
              </Checkbox>
            </Space>
          </div>
          <Table
            size="small"
            scroll={{
              x: "max-content",
            }}
            bordered
            loading={loading}
            onChange={handleTableChange}
            dataSource={listofAccounts}
            columns={columns}
            pagination={false}
          />
          <div className="text-end mt-5">
            <Pagination
              current={pageNo}
              pageSize={pageSize}
              size="small"
              total={totalRecords}
              onChange={onPageChange}
              onShowSizeChange={onPageSizeChange} // Handle page size change
              showSizeChanger
              showQuickJumper
              showTotal={(total) => `Total ${total} items`}
            />
          </div>
          <div className="custom-table">
            <Row >
              <Col md={12} xs={24}>
                <div className="fb-card">
                  <div className="fb-card-header">
                    <div className="fb-card-title">
                      <span>
                        <ClipboardDocumentListIcon />
                      </span>
                      <h4>Summary Details</h4>
                    </div>
                  </div>
                  <div
                    className="fb-card-body table"
                    style={{ "--cardheight": "270px" }}
                  >
                    <ul className="profit-list">
                      <li>
                        <h4>Total Petrol:</h4>
                        {/* <p>{NumberFormat(ProfitOnSale.GrossProfit, 0)}</p> */}
                      </li>
                      <li>
                        <h4>Total Diesel:</h4>
                        {/* <p>{NumberFormat(ProfitOnSale.totalGain, 0)}</p> */}
                      </li>
                      <li>
                        <h4>Total Liters</h4>
                        {/* <p>{NumberFormat(0)}</p> */}
                      </li>
                      <li>
                        <h4>
                          Opning Balance
                        </h4>
                        <p>
                          {NumberFormat(openingBalnce, 0)}
                        </p>
                      </li>
                      <li>
                        <h4>
                          Total Debit Amount
                        </h4>
                        <p >
                          {NumberFormat(calculateColumnTotal(listofAccounts,'Debit'))}
                        </p>
                      </li>
                      <li>
                        <h4>
                          Total Credit Amount
                        </h4>
                        <p >
                        {NumberFormat(calculateColumnTotal(listofAccounts,'Credit'))}
                        </p>
                      </li>
                      <li>
                        <h4>
                          Balance
                        </h4>
                        <p >
                        {NumberFormat(remainingBalance,0)}
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </Col>
             
            </Row>
          </div>
        </div>
      </div>
    </>
  );
}
export default AccountLedger;
