import React, { useEffect, useState } from "react";
import axios from "axios";
import config from "../../config";
import {
  Row,
  Col,
  Button,
  Skeleton,
} from "antd";
import { NavLink } from "react-router-dom";
import { PrinterOutlined } from "@ant-design/icons";
import LoginChecked from "../Shared/LoginChecked";
import { NumberFormat } from "../Shared/Utility";
import { IdentificationIcon } from "@heroicons/react/24/outline";
function FinancialSummary() {
  const AccessKey = localStorage.getItem("AccessKey");
  const UserID = localStorage.getItem("ID");
  const [loading, setLoading] = useState(false);
  const [FinalData, setFinalData] = useState({});
  const[ReceivableAmounr,setReceivableAmounr] = useState("");
  const[PayableAmount,setPayableAmount] = useState('');


  useEffect(() => {
    document.title = "Financial Summary";

    setLoading(true);
    const data = {
      UserId: UserID,
      AccessKey: AccessKey,
    };

    var api_config = {
      method: "post",
      url: config.base_url + "Report/GetFinancialSummary",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        if (response.data.status_code == 1) {
          setFinalData(response.data);
          const {productBalance,customerReceiveable,bankBalance,cashBalance,customerPayable,supplierPayable,capitalBalance} = response.data;

          const receivableAmount = (productBalance || 0) + (customerReceiveable || 0) +(bankBalance || 0) + (cashBalance || 0);
          const PayableAmounr = (customerPayable || 0) +(supplierPayable || 0) + (capitalBalance || 0);
          setReceivableAmounr(receivableAmount);
          setPayableAmount(PayableAmounr);
          setLoading(false);
        } else {
          setLoading(false);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  return (
    <>
      <LoginChecked />
      <div className="right-side-contents">
        <div className="page-content">
          <div className="page-header">
            <h3 className="page-title">
            Financial Summary
            </h3>

            <div className="header-actions">
             
              <NavLink
                to={`/report-final/print-list`}
                target="blank"
              >
                <Button
                  type="dashed"
                  shape="round"
                  size="large"
                  icon={<PrinterOutlined />}
                >
                  Print Report
                </Button>
              </NavLink>
            </div>
          </div>
          {loading ? (
            <>
              <Row>
                <Col xs={24} md={16}>
                  <Skeleton active />
                  <Skeleton active />
                </Col>
              </Row>
            </>
          ) : (
            <Row justify={"center"}>
               <Col md={20} sm={24}>
       
                <div className="fb-card">
                  <div className="fb-card-header">
                    <div className="fb-card-title">
                      <span>
                        <IdentificationIcon />
                        
                      </span>
                      <h4>FINANCIAL SUMMARY DETAILS</h4>
                    </div>
                  </div>

                  <div className="fb-card-body table">
                    <ul className="profit-list">
                      <li >
                        <h4>Total Amount of All Products:</h4>
                        <p>{FinalData.productBalance ? NumberFormat(FinalData.productBalance,0)  : 0}</p>
                      </li>
                      <li >
                        <h4>Total Customer Amount Receivable :</h4>
                        <p> {FinalData.customerReceiveable ? NumberFormat(FinalData.customerReceiveable,0): 0}</p>
                      </li>
                      <li >
                        <h4>Total Bank Amount</h4>
                        <p>{FinalData.bankBalance ? NumberFormat(FinalData.bankBalance,0) : 0}</p>
                      </li>
                      <li >
                        <h4>Total Cash In Hand</h4>
                        <p >{FinalData.cashBalance ? NumberFormat(FinalData.cashBalance,0)  : 0}</p>
                      </li>
                      <li >
                        <h4 style={{color:'#405289'}}>Total Amount</h4>
                        <p className="netprofit">{FinalData ? NumberFormat(ReceivableAmounr,0) : 0}</p>
                      </li>
                      <li >
                        <h4>Total Payable Amount of Customer</h4>
                        <p >{FinalData.supplierPayable ? NumberFormat(FinalData.customerPayable,0): 0}</p>
                      </li>
                      <li >
                        <h4>Total Payable Amount of Supplier</h4>
                        <p> {FinalData.supplierPayable ? NumberFormat(FinalData.supplierPayable,0) : 0}</p>
                      </li>
                      <li >
                        <h4>Total Capital Amount</h4>
                        <p>{FinalData.capitalBalance
                        ? NumberFormat(FinalData.capitalBalance,0)
                        : 0}</p>
                      </li>
                      <li >
                        <h4 style={{ color: ReceivableAmounr > PayableAmount ? "rgb(85 162 107)" : "#ec0d0d", }}>Total Gross Amount</h4>
                        <p className={ReceivableAmounr > PayableAmount?  "profit" : "loss"}>{FinalData
                        ? NumberFormat(ReceivableAmounr - PayableAmount,0)
                        : 0}</p>
                      </li>
                      
                    </ul>
                  </div>
                </div>
              </Col>
      </Row>
          )}
        </div>
      </div>
      
    </>
  );
}

export default FinancialSummary;
