import React, { useState, useEffect } from "react";
import axios from "axios";
import { Row, Col, Button, Form, Input, message } from "antd";

import config from "../../config";
import { NavLink } from "react-router-dom";
import LoginChecked from "../Shared/LoginChecked";

function ChangePassword() {
  const AccessKey = localStorage.getItem("AccessKey");
  const UserID = localStorage.getItem("ID");

  const [loading, setLoading] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();

  useEffect(() => {
    document.title = "Change Password";
  }, []);

  const handleSubmit = (formData) => {
    setLoading(true);

    if (formData[""] != formData[""]) {
      return false;
    }

    const data = {
      AccessKey: AccessKey,
      ID: UserID,
      ...formData,
    };



    var api_config = {
      method: "post",
      url: config.base_url + "Members/ChangePassword",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

  

    axios(api_config)
      .then(function (response) {
     
        if (response.data.status_code == 1) {
          setLoading(false);
          messageApi.open({
            type: "success",
            content: response.data.status_message,
          });

          // Handle success
        } else {
          // Handle error
          setLoading(false);
          messageApi.open({
            type: "error",
            content: response.data.status_message,
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <>
      <LoginChecked />
      {contextHolder}

      <div className="right-side-contents">
        <div className="page-content">
          <div className="page-header">
            <h6 className="page-title">Change Password</h6>
          </div>
          <Row gutter={[24, 24]} justify={"center"}>
            <Col md={12} xs={24}>
              <Form
                name="basic"
                layout="horizontal"
                size="large"
                labelCol={{
                  span: 8,
                }}
                wrapperCol={{
                  span: 16,
                }}
                initialValues={{
                  remember: true,
                }}
                onFinish={handleSubmit}
                //    onFinishFailed={onFinishFailed}
                autoComplete="off"
              >
                <Form.Item
                  label="Old Password"
                  name="OldPassword"
                  rules={[
                    {
                      required: true,
                      message: "Please input your password!",
                    },
                  ]}
                >
                  <Input.Password />
                </Form.Item>
                <Form.Item
                  label="New Password"
                  name="NewPassword"
                  rules={[
                    {
                      required: true,
                      message: "Please input your password!",
                    },
                  ]}
                >
                  <Input.Password />
                </Form.Item>
                <Form.Item
                  label="Confirm Password"
                  name="ConfrimPassword"
                  rules={[
                    {
                      required: true,
                      message: "Please input your password!",
                    },
                  ]}
                >
                  <Input.Password />
                </Form.Item>
                <Form.Item
                  wrapperCol={{
                    offset: 8,
                    span: 16,
                  }}
                >
                  <Button
                    type="primary"
                    size="large"
                    shape="round"
                    htmlType="submit"
                    loading={loading}
                  >
                    Change Password
                  </Button>
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}

export default ChangePassword;
