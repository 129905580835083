import React, { useEffect, useState } from "react";
import axios from "axios";
import config from "../../config";
import { ChevronLeftIcon } from "@heroicons/react/24/solid";
import dayjs from "dayjs";
import LoginChecked from "../Shared/LoginChecked";
import {
  Space,
  Row,
  Col,
  Button,
  Input,
  Form,
  Select,
  Skeleton,
  InputNumber,
  Popconfirm,
  message,
} from "antd";
import { NavLink } from "react-router-dom";

const { Option } = Select;

function CustomRate() {
  const [loading, setLoading] = useState(false);
  const [listOfProducts, setListOfProducts] = useState([]);
  const [AccountHolder, setAccountHolder] = useState("");
  const UserID = localStorage.getItem("ID");
  const AccessKey = localStorage.getItem("AccessKey");

  const urlParams = new URLSearchParams(window.location.search);
  const ID = urlParams.get("ID") || "";

  useEffect(() => {
    setLoading(true);
    const data = {
      UserID: UserID,
      AccessKey: AccessKey,
    };

    axios
      .post(config.base_url + "Product/GetProducts", data)
      .then((response) => {
       
        if (response.data.status_code === 1) {
          setListOfProducts(
            response.data.ListofProducts.map((product) => ({
              ...product,
              Sale_Price: product.Sale_Price || 0,
              ProductID: product.ProductID || "",
            }))
          );
        }
        setLoading(false);
      })
      .catch((error) => {
        message.error("Network Error");
     
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    setLoading(true);
    const data = {
      UserID: UserID,
      AccessKey: AccessKey,
      ID: ID,
    };

    const api_config = {
      method: "post",
      url: config.base_url + "account/GetAccountbyID",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then((response) => {
     
        if (response.data.status_code === 1) {
          setAccountHolder(response.data.Name);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }, [UserID, AccessKey, ID]);

  const handleRateChange = (index, value) => {
    const newList = [...listOfProducts];
    newList[index].rate = value;
    setListOfProducts(newList);
    updateNewPrice(index, value, newList[index].adjustmentType);
  };

  const handleAdjustmentTypeChange = (index, value) => {
    const newList = [...listOfProducts];
    newList[index].adjustmentType = value;
    setListOfProducts(newList);
    updateNewPrice(index, newList[index].rate, value);
  };

  const handleConfirmUpdatePrice = (id, price) => {
    const data = {
      UserID: UserID,
      AccessKey: AccessKey,
      ProductID: id,
      SalePrice: price,
    };

    const api_config = {
      method: "post",
      url: config.base_url + "Account/UpdateSingleProductRateForAllCustomer",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then((response) => {
        if (response.data.status_code === 1) {
          setLoading(false);
          message.success("Prices updated for all customers.");
        } else {
          message.error("Failed to update prices.");
        }
      })
      .catch((error) => {
        // console.log(error);
        message.error("An error occurred while updating prices.");
      });
  };

  const updateNewPrice = (index, rateValue, type) => {
    const newList = [...listOfProducts];

    const salePrice = listOfProducts[index].Sale_Price || 0;

    let newPrice;
    if (type === "%") {
      newPrice = salePrice * (1 + rateValue / 100);
    } else {
      newPrice = salePrice + rateValue;
    }

    newList[index].newPrice = newPrice;
    setListOfProducts(newList);

    return {
      UserID: UserID,
      AccessKey: AccessKey,
      ProductID: listOfProducts[index].ProductID || "",
      SalePrice: newPrice,
    };
  };

  const updatePricesForAllCustomers = () => {
    const data = {
      UserID: UserID,
      AccessKey: AccessKey,
      ListofProducts: listOfProducts.map((product) => ({
        ID: product.ID,
        Sale_Price: product.newPrice || product.Sale_Price,
      })),
    };
    const api_config = {
      method: "post",
      url: config.base_url + "Account/UpdateAllRatesForAllCustomer",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then((response) => {
        if (response.data.status_code === 1) {
          message.success("Prices updated for all customers.");
          setLoading(false);
        } else {
          message.error("Failed to update prices.");
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        message.error("An error occurred while updating prices.");
        setLoading(false);
      });
  };

  const updatePricesForSingleCustomer = () => {
    const data = {
      UserID: UserID,
      AccessKey: AccessKey,
      ID: ID,
      ListofProducts: listOfProducts.map((product) => ({
        ID: product.ID,
        Sale_Price: product.newPrice || product.Sale_Price,
      })),
    };

    const api_config = {
      method: "post",
      url: config.base_url + "Account/UpdateAllRatesForSingleCustomer",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then((response) => {
       
        if (response.data.status_code === 1) {
          setLoading(false);
          message.success("Prices updated for the single customer.");
        } else {
          message.error("Failed to update prices.");
        }
      })
      .catch((error) => {
        console.log(error);
        message.error("An error occurred while updating prices.");
      });
  };

  return (
    <>
      <LoginChecked />
      <div className="right-side-contents">
        <div className="page-content">
          <div className="page-header">
            <h3 className="page-title">
              <NavLink to="/accounts/manage-accounts">
                <ChevronLeftIcon />
              </NavLink>
              {`Custom Rates For (${AccountHolder})`}
            </h3>
          </div>

          <Form
            name="basic"
            layout="horizontal"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            autoComplete="off"
          >
            {loading ? (
              <Row>
                <Col xs={24} md={16}>
                  <Skeleton active />
                  <Skeleton active />
                </Col>
              </Row>
            ) : (
              <div className="table-responsive">
                <table className="add-sale-table">
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Product Name</th>
                      <th>Existing Price</th>
                      <th>Price Variation</th>
                      <th>New Price</th>
                      <th>Apply to All</th>
                    </tr>
                  </thead>
                  <tbody>
                    {listOfProducts.map((item, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{item.Name}</td>
                        <td>{item.Sale_Price}</td>
                        <td>
                          <Form.Item style={{ marginBottom: "0px" }}>
                            <Space direction="vertical">
                              <InputNumber
                                addonAfter={
                                  <Select
                                    value={item.adjustmentType || "value"}
                                    onChange={(value) =>
                                      handleAdjustmentTypeChange(index, value)
                                    }
                                    style={{ width: 60 }}
                                  >
                                    <Option value="value">RS</Option>
                                    <Option value="%">%</Option>
                                  </Select>
                                }
                                value={item.rate || 0}
                                onChange={(value) =>
                                  handleRateChange(index, value)
                                }
                              />
                            </Space>
                          </Form.Item>
                        </td>
                        <td>
                          <Form.Item style={{ marginBottom: "0px" }}>
                            <Input
                              style={{ pointerEvents: "none" }}
                              value={
                                item.newPrice !== undefined ? item.newPrice : 0
                              }
                              placeholder={item.Sale_Price}
                              // onChange={(e) =>
                            />
                          </Form.Item>
                        </td>
                        <td>
                          <Form.Item style={{ marginBottom: "0px" }}>
                            <Popconfirm
                              title="Apply The Rate"
                              description="Are you sure to apply the rate for all?"
                              okText="Yes"
                              cancelText="No"
                              onConfirm={(id, price) =>
                                handleConfirmUpdatePrice(
                                  item.ID,
                                  item.newPrice !== undefined
                                    ? item.newPrice
                                    : item.Sale_Price
                                )
                              }
                            >
                              <Button
                                type="dashed"
                                primary
                                size="default"
                                shape="round"
                              >
                                Apply To All
                              </Button>
                            </Popconfirm>
                          </Form.Item>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </Form>
          <div
            className="header-actions"
            style={{
              marginTop: "20px",
              marginRight: "60px",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Space>
              <Button
                type="primary"
                size="large"
                shape="round"
                onClick={updatePricesForSingleCustomer}
              >
                Update Prices
              </Button>

              <Popconfirm
                title="Apply The Rate"
                description="Are you sure to apply the rates for all customers?"
                okText="Yes"
                cancelText="No"
                onConfirm={updatePricesForAllCustomers}
              >
                <Button
                  type="dashed"
                  danger
                  size="large"
                  shape="round"
                  loading={loading}
                >
                  Update Prices for All Customers
                </Button>
              </Popconfirm>
            </Space>
          </div>
        </div>
      </div>
    </>
  );
}

export default CustomRate;
