import React, { useState, useEffect } from "react";
import config from "../../config";
import axios from "axios";
import {
  Table,
  message,
  Space,
  Spin,
  Row,
  Col,
  Button
} from "antd";
import {PlusOutlined} from "@ant-design/icons"
import { NavLink, useParams } from "react-router-dom";
import dayjs from "dayjs";
import {
  ChevronLeftIcon,
  DevicePhoneMobileIcon,
  EnvelopeIcon,
  EyeIcon,
  EyeSlashIcon,
  GlobeAltIcon,
  MapPinIcon,
  PhoneIcon,
  
} from "@heroicons/react/24/outline";

const PumpDetail = () => {
  const [loading, setLoading] = useState(false);
  const UserID = localStorage.getItem("AdminID");
  const AccessKey = localStorage.getItem("AdminAccessKey");
  const [PumpDetail, setPumpDetail] = useState({});
  const [ListOfSubscriptions, setListOfSubscriptions] = useState([]);
  const [ShowPassword, setShowPassword] = useState(false);
  let params = useParams();

  useEffect(() => {
    setLoading(true);
    const data = {
      UserID: UserID,
      AccessKey: AccessKey,
      ID: params.id,
    };
    var api_config = {
      method: "post",
      url: config.base_url + "AdminUser/GetPetrolPumpWorkingDetail",
      header: {
        "Content-Type": "application/josn",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {

        if (response.data.status_code == 1) {
          setPumpDetail(response.data);
          setLoading(false);
        } else {
          // Handle error
          setLoading(false);
        }
        //   setLoading(false);
      })
      .catch(function (error) {
        message.error("Network Error");
        // console.log(error);
      });
  }, []);

  useEffect(() => {
    setLoading(true);
    const data = {
      UserID: UserID,
      AccessKey: AccessKey,
      PumpID: params.id,
    };
    var api_config = {
      method: "post",
      url: config.base_url + "AdminUser/GetPackagePurchaseByPumpID",
      header: {
        "Content-Type": "application/josn",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        
        if (response.data.status_code == 1) {
          setListOfSubscriptions(response.data.listofRecords);
          setLoading(false);
        } else {
          // Handle error
          setLoading(false);
        }
        //   setLoading(false);
      })
      .catch(function (error) {
        message.error("Network Error");
        // console.log(error);
      });
  }, []);

  const columns = [
    // {
    //   title: "SR#",
    //   dataIndex: "Sr",
    //   key: "Sr",
    //   render: (_, record, index) => index + 1 + PageSize * (PageNo - 1),
    // },
    {
      title: "ID",
      dataIndex: "ID",
      key: "ID",
    },
    {
      title: "Pump Code",
      dataIndex: "PumpCode",
      key: "PumpCode",
    },
    {
      title: "Start Date",
      dataIndex: "StartDate",
      key: "startdate",
      render: (_, record) => dayjs(record.StartDate).format(config.date_format),
    },
    {
      title: "End Date",
      dataIndex: "EndDate",
      key: "EndDate",
      render: (_, record) => dayjs(record.EndDate).format(config.date_format),
    },

    {
      title: "Package",
      dataIndex: "PackageName",
      key: "PackageName",
    },

    {
      title: "Price",
      dataIndex: "PackagePrice",
      key: "PackagePrice",
    },
    {
      title: "Payment Mode",
      dataIndex: "PaymentMode",
      key: "PaymentMode",
    },
    {
      title: "Payment Received By",
      dataIndex: "PaymentReceivedBy",
      key: "PaymentReceivedBy",
    },
    {
      title: "Discount %",
      dataIndex: "DiscountPercentage",
      key: "DiscountPercentage",
    },
    {
      title: "Discount Value",
      dataIndex: "DiscountValue",
      key: "DiscountValue",
    },
    {
      title: "Grace Period",
      dataIndex: "GracePeriod",
      key: "GracePeriod",
    },
  ];

  return (
    <>
      <div className="right-side-contents">
        <div className="page-content control-base-wrap">
          <div className="page-header">
            <h3 className="page-title">
              <NavLink to="/controlbase/petrolpump">
                <ChevronLeftIcon />
              </NavLink>
              Pump Detail
            </h3>
          </div>
          {loading ? (
            <div className="spin-center">
              <Space size="large">
                <Spin size="large" />
              </Space>
            </div>
          ) : (
            <>
              <div style={{ marginBottom: 30 }} className="white-box">
                <div className="pd-head">
                  <div className="name">
                    <div className="logo">
                      <img
                        style={{ height: "50px" }}
                        src={
                          config.images + PumpDetail.PumpDetails?.Logo_Reports
                        }
                        alt=""
                      />
                    </div>
                    <div className="info">
                      <h3>{PumpDetail.PumpDetails?.Name}</h3>
                      <p>{PumpDetail.PumpDetails?.PumpCode}</p>
                    </div>
                  </div>
                  <div className="pd-head-right">
                    <ul>
                      <li>
                        <span className="lbl">Registered On:</span>
                        <span className="val label">
                          {dayjs(PumpDetail.CreatedDate).format("DD MMM YYYY")}
                        </span>
                      </li>
                      <li>
                        <span className="lbl">Expiry Date:</span>
                        <span className="val label">
                          {dayjs(PumpDetail.ExpiryDate).format("DD MMM YYYY")}
                        </span>
                      </li>
                      <li>
                        <span className="lbl">Package:</span>
                        <span className="val label trial">
                          {PumpDetail.PackageType}
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
                <Row>
                  <Col md={8} xs={24}>
                    <ul className="icon-info-list">
                      <li>
                        <EnvelopeIcon />
                        <span>{PumpDetail.PumpDetails?.Email}</span>
                      </li>
                      <li>
                        <DevicePhoneMobileIcon />
                        <span>{PumpDetail.PumpDetails?.Mobile}</span>
                      </li>
                      <li>
                        <PhoneIcon />
                        <span>{PumpDetail.PumpDetails?.Phone}</span>
                      </li>
                      <li>
                        <GlobeAltIcon />
                        <span>{PumpDetail.PumpDetails?.Website}</span>
                      </li>
                      <li>
                        <MapPinIcon />
                        <span>{PumpDetail.PumpDetails?.Address}</span>
                      </li>
                      <li>
                        {ShowPassword ?
                          <EyeSlashIcon style={{ cursor: 'pointer' }} onClick={() => setShowPassword(!ShowPassword)} />
                          :
                          <EyeIcon style={{ cursor: 'pointer' }} onClick={() => setShowPassword(!ShowPassword)} />
                        }
                        <span>{ShowPassword && PumpDetail.PumpUser?.Password}</span>
                      </li>
                    </ul>
                  </Col>
                  <Col md={16} xs={24}>
                    <table class="add-sale-table">
                      <thead>
                        <tr>
                          <th>Sr. No.</th>
                          <th>Module Name</th>
                          <th>Sub Module Name</th>
                          <th>No. of Records</th>
                          <th>Last Added Date</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>1</td>
                          <td>Purchase</td>
                          <td>Purchase Details</td>
                          <td>
                            {PumpDetail.PurchaseHead}&nbsp;/&nbsp;
                            {PumpDetail.PurchaseDetail}
                          </td>
                          <td>{PumpDetail.LastPurchaseDate}</td>
                        </tr>
                        <tr>
                          <td>2</td>
                          <td>Sales</td>
                          <td>Sales Details</td>
                          <td>
                            {PumpDetail.SalesHead}
                            &nbsp;/&nbsp;{PumpDetail.SalesDetail}
                          </td>
                          <td>{PumpDetail.LastSaleDate}</td>
                        </tr>
                        <tr>
                          <td>3</td>
                          <td>Vouchers</td>
                          <td></td>
                          <td>{PumpDetail.Vouchers}</td>
                          <td>{PumpDetail.LastVoucherDate}</td>
                        </tr>
                        <tr>
                          <td>4</td>
                          <td>General Ledger</td>
                          <td></td>
                          <td>{PumpDetail.GeneralLedger}</td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>5</td>
                          <td>Product Ledger</td>
                          <td></td>
                          <td>{PumpDetail.ProductLedger}</td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>6</td>
                          <td>Products</td>
                          <td></td>
                          <td>{PumpDetail.Products}</td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>7</td>
                          <td>Accounts</td>
                          <td></td>
                          <td>{PumpDetail.Accounts}</td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>8</td>
                          <td>Login Logs</td>
                          <td></td>
                          <td>{PumpDetail.LoginLogs}</td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>9</td>
                          <td>Dip Readings</td>
                          <td>Dip Reading Adjustments</td>
                          <td>
                            {PumpDetail.DipReadings}&nbsp;/&nbsp;
                            {PumpDetail.DipReadingAdjustment}
                          </td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>10</td>
                          <td>Nozzle Readings</td>
                          <td>&nbsp;</td>
                          <td>{PumpDetail.NozleReadings}</td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>11</td>
                          <td>Pump Machines</td>
                          <td></td>
                          <td>{PumpDetail.PumpMachines}</td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>12</td>
                          <td>Customer Rates</td>
                          <td></td>
                          <td>{PumpDetail.CustomerRates}</td>
                          <td></td>
                        </tr>

                        <tr>
                          <td>13</td>
                          <td>Investment Summary</td>
                          <td>&nbsp;</td>
                          <td>0</td>
                          <td></td>
                        </tr>
                      </tbody>
                    </table>
                  </Col>
                </Row>
              </div>
              <div style={{ marginBottom: 50 }}>
                {/* <h4 style={{ marginBottom: 20 }}>Subscriptions</h4> */}
            <div className="page-header">
            <h3 className="page-title">Subscriptions</h3>
            <div className="header-actions">
              <NavLink to={`/controlbase/petrolpump/add-subscription/${params.id}`}>
                <Button
                  type="primary"
                  size="large"
                  shape="round"
                  icon={<PlusOutlined style={{ color: "white" }} />}
                >
                  Add Subscriptions
                </Button>
              </NavLink>
            </div>
          </div>
                <Table
                  size="small"
                  scroll={{
                    x: "max-content",
                  }}
                  loading={loading}
                  dataSource={ListOfSubscriptions}
                  columns={columns}
                  pagination={false}
                  bordered
                />
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default PumpDetail;
