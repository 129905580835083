import React, { useEffect, useState } from "react";
import {
  PDFViewer,
  Page,
  Text,
  View,
  Document,
  StyleSheet,
} from "@react-pdf/renderer";
import dayjs from "dayjs";
import axios from "axios";
import { Spin } from "antd";
import config from "../../../config";
import PrintHeaderAccount from "../../../Pdf/PrintHeaderAccount";
import PrintFooter from "../../../Pdf/PrintFooter";
import { PrintFormateNumber } from "../../../Pdf/PrintFormateNumber";

const PrintFinancialSummary = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const StartDate = urlParams.get("StartDate");
  const EndDate = urlParams.get("EndDate");

  const UserID = localStorage.getItem("ID");
  const AccessKey = localStorage.getItem("AccessKey");

  const [summaryDetail, setSummaryDetail] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    const data = {
      UserID: UserID,
      AccessKey: AccessKey,
    
    };

    var api_config = {
      method: "post",
      url: config.base_url + "Report/GetFinancialSummary",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(api_config)
      .then(function (response) {
        if (response.data.status_code == 1) {
          // Handle success
          setSummaryDetail(response.data);
          setLoading(false);
        } else {
          setLoading(false);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

//   const formattedStartDate = dayjs(StartDate).format("DD-MM-YYYY");
//   const formattedEndDate = dayjs(EndDate).format("DD-MM-YYYY");

  const styles = StyleSheet.create({
    page: {
      //flexDirection: 'row',
      backgroundColor: "#FFF",
    },
    section: {
      margin: 10,
      padding: 10,
      flexDirection: "column",
      marginBottom: 20,
      //flexGrow: 1
    },
    title: {
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      marginBottom: 20,
    },
   
    container: {
      flexDirection: "row",
      border: "0.3px",
      padding: "10px 5px",
      alignItems: "flex-start",
    },
    label: {
      width: 200,
    },
    data: {
      flex: 1,
      marginLeft: 10,
    },
    text: {
      fontSize: 12,
    },
  });
  const MyDocument = () => (
    <Document>
      <Page size="A4" style={styles.page}>
        <PrintHeaderAccount />
        <View style={styles.section}>
          <View style={styles.title}>
            <View>
              <Text style={{ fontSize: "14pt" }}>Financial Summary</Text>
            </View>
            {/* <View>
              <Text
                style={{
                  fontSize: "11pt",
                 
                  textAlign: "right",
                }}
              >
                {formattedStartDate} To {formattedEndDate}
              </Text>
            </View> */}
          </View>

          <View style={styles.table}>
            <View>
              <View style={styles.container}>
                <View style={styles.label}>
                  <Text style={styles.text}>Total Amount Of All Products</Text>
                </View>
                <View>
                  <Text style={{ color: "grey" }}>|</Text>
                </View>
                <View style={styles.data}>
                  <Text style={styles.text}>{PrintFormateNumber(summaryDetail.productBalance,0)}</Text>
                </View>
              </View>

              <View style={styles.container}>
                <View style={styles.label}>
                  <Text style={styles.text}>Total Customer Amount Receivable</Text>
                </View>
                <View>
                  <Text style={{ color: "grey" }}>|</Text>
                </View>
                <View style={styles.data}>
                  <Text style={styles.text}>
                    {PrintFormateNumber(summaryDetail.customerReceiveable,0)}
                  </Text>
                </View>
              </View>

              <View style={styles.container}>
                <View style={styles.label}>
                  <Text style={styles.text}> Total Bank Amount</Text>
                </View>
                <View>
                  <Text style={{ color: "grey" }}>|</Text>
                </View>
                <View style={styles.data}>
                  <Text style={styles.text}>
                    {PrintFormateNumber(summaryDetail.bankBalance,0)}
                  </Text>
                </View>
              </View>

              <View style={styles.container}>
                <View style={styles.label}>
                  <Text style={styles.text}>Total Cash In Hand</Text>
                </View>
                <View>
                  <Text style={{ color: "grey" }}>|</Text>
                </View>
                <View style={styles.data}>
                  <Text style={styles.text}>
                    {PrintFormateNumber(summaryDetail.cashBalance,0)}
                  </Text>
                </View>
              </View>

              <View style={styles.container}>
                <View style={styles.label}>
                  <Text style={styles.text}>Total Amount</Text>
                </View>
                <View>
                  <Text style={{ color: "grey" }}>|</Text>
                </View>
                <View style={styles.data}>
                  <Text style={styles.text}>
                    {PrintFormateNumber((summaryDetail.productBalance + summaryDetail.customerReceiveable + summaryDetail.bankBalance + summaryDetail.cashBalance ),0)}
                  </Text>
                </View>
              </View>

              <View style={styles.container}>
                <View style={styles.label}>
                  <Text style={styles.text}>Total Payable Amount of Customer</Text>
                </View>
                <View>
                  <Text style={{ color: "grey" }}>|</Text>
                </View>
                <View style={styles.data}>
                  <Text style={styles.text}>
                    {PrintFormateNumber(summaryDetail.customerPayable,0)}
                  </Text>
                </View>
              </View>

              <View style={styles.container}>
                <View style={styles.label}>
                  <Text style={styles.text}>Total Payable Amount Of Supplier</Text>
                </View>
                <View>
                  <Text style={{ color: "grey" }}>|</Text>
                </View>
                <View style={styles.data}>
                  <Text style={styles.text}>
                    {PrintFormateNumber(summaryDetail.supplierPayable,0)}
                  </Text>
                </View>
              </View>

              <View style={styles.container}>
                <View style={styles.label}>
                  <Text style={styles.text}>Total Capital Amount</Text>
                </View>
                <View>
                  <Text style={{ color: "grey" }}>|</Text>
                </View>
                <View style={styles.data}>
                  <Text style={styles.text}>{PrintFormateNumber(summaryDetail.capitalBalance,0)}</Text>
                </View>
              </View>

              <View style={styles.container}>
                <View style={styles.label}>
                  <Text style={styles.text}>Total Gross Amount</Text>
                </View>
                <View>
                  <Text style={{ color: "grey" }}>|</Text>
                </View>
                <View style={styles.data}>
                  <Text style={styles.text}>{PrintFormateNumber((summaryDetail.productBalance + summaryDetail.customerReceiveable + summaryDetail.bankBalance + summaryDetail.cashBalance ) - (summaryDetail.supplierPayable + summaryDetail.capitalBalance+summaryDetail.customerPayable),0)}</Text>
                </View>
              </View>

            </View>
          </View>
          <View
            style={{ marginBottom: 60, marginTop: 0, top: 10, bottom: 10 }}
            fixed
          >
            <Text
              style={{ fontSize: "8pt" }}
              render={({ pageNumber, totalPages }) =>
                `Page ${pageNumber} / ${totalPages}`
              }
            />
          </View>
        </View>
        <PrintFooter fixed/>
      </Page>
    </Document>
  );

  return (
    <>
      <div style={{ width: "100%", height: "100vh", overflow: "hidden" }}>
        {loading ? (
          <div
            style={{
              height: "100vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Spin />
          </div>
        ) : (
          <PDFViewer width="100%" height="100%" fileName="sample.pdf">
            <MyDocument />
          </PDFViewer>
        )}
      </div>
    </>
  );
};

export default PrintFinancialSummary;
