import React, { useState, useEffect } from "react";
import config from "../../config";
import axios from "axios";
import { Table, message, Form, Input, Button, Space, Tooltip,Pagination } from "antd";
import { DeleteColumnOutlined, DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons'
import { Link, NavLink } from "react-router-dom";
import { Logos } from "../../components/Shared/Logos"

const FuelProvider = () => {
  const [loading, setLoading] = useState(false);
  const UserID = localStorage.getItem("AdminID");
  const AccessKey = localStorage.getItem("AdminAccessKey");
  const [OrderBy, setOrderBy] = useState("");
  const [listOfAccounts, setListOfAccounts] = useState([]);
  const [Name, setName] = useState('');

  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const [totalRecords, setTotalRecords] = useState(0);

  useEffect(() => {
    document.title = "Fuel Providers";
    setLoading(true);
    const data = {
      UserID: UserID,
      AccessKey: AccessKey,
      PageNo: pageNo,
      PageSize: pageSize,
      OrderBy: OrderBy,
      Name: Name,
    };
    

    var api_config = {
      method: "post",
      url: config.base_url + "FuelProvider/GetFuelProvidersWithPumpCount",
      header: {
        "Content-Type": "application/josn",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        if (response.data.status_code == 1) {
          setListOfAccounts(response.data.listofAccounts);
          setTotalRecords(response.data.totalRecords)
          setLoading(false);
        } else {
          // Handle error
          setLoading(false);
        }
        //   setLoading(false);
      })
      .catch(function (error) {
        message.error("Network Error");
      });
  }, [OrderBy, Name,pageNo,pageSize]);

  const handleTableChange = async (pagination, filters, sorter) => {
    // Make API request with sorting parameters
    const sortField = sorter.field;
    const sortOrder = sorter.order === "descend" ? "desc" : "asc";

    if (sortField === "Name") {
      if (sortOrder === "asc") {
        setOrderBy(1);
      } else {
        setOrderBy(2);
      }
    }
    else if(sortField === "TotalPumps"){
      if (sortOrder === "asc") {
        setOrderBy(3);
      } else {
        setOrderBy(4);
      }
    }
  };

  const columns = [
    // {
    //   title: "SR#",
    //   dataIndex: "Sr",
    //   key: "Sr",
    //   render: (_, record, index) =>
    //     record.ID ? index + 1 + pageSize * (pageNo - 1) : null,
    // },
    {
      title: "ID",
      dataIndex: "ID",
      key: "ID",
    },
    {
      title: "Name",
      dataIndex: "Name",
      key: "Name",
      sorter: true,
      render: (name, record) => {

        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <img
              src={config.images + '/PumpLogos/' + record.Logo_Reports}
              alt={record.Logo_Title}
              style={{ width: '40px', height: '40px', marginRight: '8px' }}
            />
            <span>{name}</span>
          </div>
        );
      },
    },
    {
      title: "TotalPumps",
      dataIndex: "TotalPumps",
      key: "TotalPumps",
      sorter: true,
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => {
        return (
          <>
            <Space size="large">
              <Tooltip title="Edit The Fuel Provider">
                <Link to={`/controlbase/fuelprovider/edit?id=${record.ID}`}>
                  <EditOutlined style={{ color: 'blue' }} />
                </Link>
              </Tooltip>
              <Tooltip title="Delete The Fuel Provider">
                
                  <DeleteOutlined style={{ color: 'red' }} />
              
              </Tooltip>
            </Space>
          </>

        )
      }
    },
  ];

  const onPageChange = (page, pageSize) => {
    setPageNo(page);
  };

  const onPageSizeChange = (current, size) => {
    setPageNo(1); 
    setPageSize(size);
  };
  const handelSearchByName = (e) => {
    setName(e.target.value);
  }

  return (
    <>
      <div className="right-side-contents">
        <div className="page-content control-base-wrap">
          <div className="page-header">
            <h3 className="page-title">Fuel Providers</h3>
            <div className="header-actions">
              <Link to="/controlbase/fuelprovider/addnew">
                <Button
                  type="primary"
                  size="large"
                  shape="round"
                  icon={<PlusOutlined style={{ color: "white" }} />}
                >
                  Add New Fuel Provider
                </Button>
              </Link>
            </div>
          </div>
          <div className="filters-wrap">
            <Form>
              <Form.Item>
                <Input
                  allowClear
                  showSearch
                  placeholder="Search By Name"
                  value={Name}
                  onChange={handelSearchByName}
                />
              </Form.Item>
            </Form>
          </div>
          <Table
            size="small"
            scroll={{
              x: "max-content",
            }}
            loading={loading}
            dataSource={listOfAccounts}
            columns={columns}
            pagination={false}
            onChange={handleTableChange}
            bordered
          />
            <div className="text-end mt-5">
            <Pagination
              current={pageNo}
              pageSize={pageSize}
              size="small"
              total={totalRecords}
              onChange={onPageChange}
              onShowSizeChange={onPageSizeChange} // Handle page size change
              showSizeChanger
              showQuickJumper
              showTotal={(total) => `Total ${total} items`}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default FuelProvider;
