import React, { useState, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import axios from "axios";
import config from "../../config";
import { UploadOutlined } from "@ant-design/icons";
import { ChevronLeftIcon } from "@heroicons/react/24/solid";
import { Row, Col, Button, Form, Input, message, Spin, Upload } from "antd";

const EditFeulProvider = () => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const UserID = localStorage.getItem("AdminID");
  const AccessKey = localStorage.getItem("AdminAccessKey");
  const [messageApi, contextHolder] = message.useMessage();

  const [logoLoginFile, setLogoLoginFile] = useState(null);
  const [logoReportsFile, setLogoReportsFile] = useState(null);
  const [logoTitleFile, setLogoTitleFile] = useState(null);
  const urlParams = new URLSearchParams(window.location.search);
  const ID = +urlParams.get("id");

  useEffect(() => {
    document.title = "Edit Fuel Provider";
    setLoading(true);

    const data = {
      UserID: UserID,
      AccessKey: AccessKey,
      ID: ID,
    };
    console.log(data);
    const api_config = {
      method: "post",
      url: config.base_url + "FuelProvider/GetFuelProviderbyID",
      headers: {
       "Content-Type": "application/json",
      },
      data: data,
    };
    
    axios(api_config)
      .then((response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          const fuelProvider = response.data.data;
          form.setFieldsValue({
            Name: fuelProvider.ProviderName,
            Logo_Login: fuelProvider.Logo_Login, 
            Logo_Reports: fuelProvider.Logo_Reports,
            Logo_Title: fuelProvider.Logo_Title,
          });

          setLogoLoginFile(fuelProvider.Logo_Login);
          setLogoReportsFile(fuelProvider.Logo_Reports);
          setLogoTitleFile(fuelProvider.Logo_Title);
        } else {
          message.error(response.data.status_message || "Something went wrong");
        }
      })
      .catch((error) => {
        console.error(error);
        message.error("An error occurred while fetching the fuel provider.");
      })
      .finally(() => {
        setLoading(false);
      });
  }, [ID, UserID, AccessKey, form]);

  const handleLogoLoginChange = (info) => {
    setLogoLoginFile(info.fileList[0]?.originFileObj || null);
  };

  const handleLogoReportsChange = (info) => {
    setLogoReportsFile(info.fileList[0]?.originFileObj || null);
  };

  const handleLogoTitleChange = (info) => {
    setLogoTitleFile(info.fileList[0]?.originFileObj || null);
  };

  const handleSubmit = (form) => {
    setLoading(true);
    const data={
      UserID:UserID,
      AccessKey:AccessKey,
      ID:ID,
      ...form,
    }
    console.log("Form Values: ", data);

    const api_config = {
      method: "post",
      // url: `${config.base_url}/FuelProvider/UpdateFuelProvider`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
    .then((response) => {
      console.log(response.data);
      if (response.data.status_code === 1) {
        message.success(response.data.status_message);

      } else {
        // error;
      }
    })
    .catch((error) => {
      console.error(error);
      message.error("An error occurred.");
    })
    .finally(() => {
      setLoading(false);
    });
  };

  return (
    <>
      {contextHolder}
      <div className="right-side-contents">
        <div className="page-content">
          <Row>
            <Col span={12}>
              <div className="page-header">
                <h6 className="page-title">
                  <NavLink to="/controlbase/fuelprovider">
                    <ChevronLeftIcon />
                  </NavLink>
                  Edit Fuel Provider
                </h6>
              </div>
            </Col>
          </Row>
          <Row gutter={[24, 24]}>
            <Col xs={24} md={12}>
              <Form
                form={form}
                name="basic"
                layout="horizontal"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                initialValues={{ remember: true }}
                onFinish={handleSubmit}
                autoComplete="off"
                size="large"
              >
                <Form.Item
                  label="Fuel Provider Name"
                  name="Name"
                  rules={[
                    {
                      required: true,
                      message: "Please enter Fuel Provider Name",
                    },
                  ]}
                >
                  <Input placeholder="Enter Fuel Provider Name" />
                </Form.Item>

                <Form.Item
                  label="Logo_Login"
                  name="Logo_Login"
                  rules={[
                    {
                      required: true,
                      message: "Please select a Fuel Provider Logo",
                    },
                  ]}
                >
                  <Upload
                    name="logo"
                    accept="image/*"
                    fileList={logoLoginFile ? [logoLoginFile] : []}
                    onChange={handleLogoLoginChange}
                    beforeUpload={() => false}
                  >
                    <Button icon={<UploadOutlined />}>Select File</Button>
                  </Upload>
                </Form.Item>

                <Form.Item
                  label="Logo_Reports"
                  name="Logo_Reports"
                  rules={[
                    {
                      required: true,
                      message: "Please select a Fuel Provider Logo",
                    },
                  ]}
                >
                  <Upload
                    name="logo"
                    accept="image/*"
                    fileList={logoReportsFile ? [logoReportsFile] : []}
                    onChange={handleLogoReportsChange}
                    beforeUpload={() => false}
                  >
                    <Button icon={<UploadOutlined />}>Select File</Button>
                  </Upload>
                </Form.Item>

                <Form.Item
                  label="Logo_Title"
                  name="Logo_Title"
                  rules={[
                    {
                      required: true,
                      message: "Please select a Fuel Provider Logo",
                    },
                  ]}
                >
                  <Upload
                    name="logo"
                    accept="image/*"
                    fileList={logoTitleFile ? [logoTitleFile] : []}
                    onChange={handleLogoTitleChange}
                    beforeUpload={() => false}
                  >
                    <Button icon={<UploadOutlined />}>Select File</Button>
                  </Upload>
                </Form.Item>

                <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                  <Button
                    type="primary"
                    size="large"
                    shape="round"
                    htmlType="submit"
                    loading={loading}
                  >
                    {loading ? <Spin /> : "Save Changes"}
                  </Button>
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default EditFeulProvider;
