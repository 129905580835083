import React, { useEffect, useState } from "react";
import axios from "axios";
import config from "../../config";
import ProductsDropDown from "../Shared/ProductsDropDown";
import AccountDropDown from "../Shared/AccountDropDown";
import BoxCard from "../Shared/BoxCard";
import {
  Row,
  Col,
  message,
  Form,
  Input,
  Select,
  Checkbox,
  Button,
  Skeleton,
  DatePicker,
  Table,
  Space,
} from "antd";

import moment from "moment";
import {
  DeleteOutlined,
  FullscreenExitOutlined,
  FullscreenOutlined,
} from "@ant-design/icons";
import { NavLink, useNavigate } from "react-router-dom";
import { ChevronLeftIcon } from "@heroicons/react/24/solid";
import LoginChecked from "../Shared/LoginChecked";
import { NumberFormat } from "../Shared/Utility";
import { NozzleOil } from "../UserControls/Icons";
import dayjs from "dayjs";
import { LockClosedIcon, LockOpenIcon } from "@heroicons/react/24/outline";

function AddSales() {
  let navigate = useNavigate();

  const AccessKey = localStorage.getItem("AccessKey");
  const UserID = localStorage.getItem("ID");
  const [ListofNozzels, setListofNozzels] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingAddSale, setLoadingAddSale] = useState(false);
  const [WithoutNozzel, setWithoutNozzel] = useState(false);
  const [Is_Cash, setIs_Cash] = useState(false);
  const [ListofNozzelsCard, setListofNozzelsCard] = useState([]);
  const [Fullscreen, setFullScreen] = useState(false);
  const [ShowNozzles, setShowNozzles] = useState();

  const [CashAccountID, setCashAccountID] = useState(0);

  const [Quantity, setQuantity] = useState({});

  const uniqueProductNames = new Set();

  const accountTypesArray = AccountDropDown();

  const productTypesArray = ProductsDropDown();
  const [ProductName, setProductName] = useState("");
  const [AccountName, setAccountName] = useState("");
  const [Price, setPrice] = useState(0);

  const [form] = Form.useForm();

  const [purchaseList, setPurchaseList] = useState([]);

  const [priceGroup, setpriceGroup] = useState([]);

  const [totals, setTotals] = useState({
    cashSaleTotal: 0,
    creditSaleTotal: 0,
    totalPrice: 0,
  });

  const [Dated, setDated] = useState(null);
  const [Description, setDescription] = useState("");
  const [InvoiceNo, setInvoiceNo] = useState("");

  const [ListofReadings, setListofReadings] = useState([]);
  const [ListOfSummary, setListOfSummary] = useState([]);
  const [groupedSummary, setgroupedSummary] = useState([]);

  const [QtyLocked, setQtyLocked] = useState(false);
  const [PriceLocked, setPriceLocked] = useState(false);
  const [RateLoading,setRateLoading] = useState(false)

  useEffect(() => {
    if (accountTypesArray) {
      let cash = accountTypesArray.find((item) => item.label === "Cash");
      if (cash) {
        setCashAccountID(cash.value);
      }
    }
  }, [accountTypesArray]);

  useEffect(() => {
    document.title = "Add Sale";
    setLoading(true);
    const data = {
      UserID: UserID,
      AccessKey: AccessKey,
    };
    var api_config = {
      method: "post",
      url: config.base_url + "Sale/GetNextSaleInvoiceNo",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        if (response.data.status_code == 1) {
          setLoading(false);
          setListofNozzelsCard(response.data.ListofReading);
          setListofNozzels(response.data.ListofReading);
          setInvoiceNo(response.data.NextInvoiceNo);
          setShowNozzles(response.data.ShowNozzles);
        } else {
          // Handle error
        }
        //   setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    const groupedData = purchaseList.reduce((acc, obj) => {
      const key = obj.Product_ID;
      if (!acc[key]) {
        acc[key] = {
          Product_ID: obj.Product_ID,
          ProductName: obj.ProductName,
          Total_Price: 0,
        };
      }
      acc[key].Total_Price += parseInt(obj.Quantity) * parseInt(obj.Price);
      return acc;
    }, {});

    setpriceGroup(Object.values(groupedData));
  }, [purchaseList]);

  useEffect(() => {
    const cashSaleTotal = purchaseList.reduce((acc, obj) => {
      if (obj.Is_Cash) {
        return acc + parseFloat(obj.Quantity) * parseFloat(obj.Price);
      }
      return acc;
    }, 0);

    const creditSaleTotal = purchaseList.reduce((acc, obj) => {
      if (!obj.Is_Cash) {
        return acc + parseFloat(obj.Quantity) * parseFloat(obj.Price);
      }
      return acc;
    }, 0);

    const totalPrice = purchaseList.reduce((acc, obj) => {
      return acc + parseFloat(obj.Quantity) * parseFloat(obj.Price);
    }, 0);

    setTotals({
      cashSaleTotal,
      creditSaleTotal,
      totalPrice,
    });
  }, [purchaseList]);

  useEffect(() => {
    // Convert Price and Quantity to numbers and calculate the total whenever they change
    const priceValue = parseFloat(Price);
    const quantityValue = parseFloat(Quantity);
    const totalValue = priceValue * quantityValue;
    const total = isNaN(totalValue) ? "" : totalValue.toString();
    form.setFieldsValue({
      total: total,
    });
  }, [Price, Quantity]);

  function handleCheckboxChange(index) {
    setPurchaseList((prevData) => {
      // Create a new copy of the purchase list
      const updatedList = [...prevData];

      // Create a new copy of the object at the given index
      const updatedItem = { ...updatedList[index] };

      // Toggle the Is_Cash value
      updatedItem.Is_Cash = !updatedItem.Is_Cash;

      // Update the list with the modified item
      updatedList[index] = updatedItem;

      return updatedList;
    });
  }

  const handleSubmit = (formData) => {
    // formData["Is_Cash"] = formData["Is_Cash"] == "on" ? true : false;
    formData["Vehicle_No"] =
      formData["Vehicle_No"] == undefined ? "" : formData["Vehicle_No"];
    formData["Receipt_No"] =
      formData["Receipt_No"] == undefined ? "" : formData["Receipt_No"];

  

    const data = {
      Is_Cash: Is_Cash,
      ProductName: ProductName,
      AccountName: AccountName,
      ...formData,
    };


    setPurchaseList((prevPurchaseList) => {
      const newPurchaseList = [...prevPurchaseList, data];
      setIs_Cash(false);
      return newPurchaseList;
    });

    form.resetFields();
  };

  useEffect(() => {
    // Step 1: Reduce purchaseList to get the total quantity sold for each product
    const reducedData = Object.values(
      purchaseList.reduce((acc, item) => {
        if (!acc[item.ProductName]) {
          acc[item.ProductName] = {
            ProductName: item.ProductName,
            Price: item.Price,
            Quantity: 0,
          };
        }
        acc[item.ProductName].Quantity += parseFloat(item.Quantity);
        return acc;
      }, {})
    );

    const nozzel = ListofNozzels.find(
      (item) => item.ProductName === ProductName
    );

    if (nozzel) {
      // Steps 2: Calculate total sales for each product from ListofNozzels
      const productTotals = {};
      ListofNozzels.forEach((item) => {
        const productName = item.ProductName;
        const totalSale = parseFloat(
          item.Current_Reading - item.Last_Reading - item.Returned || 0
        );

        if (productName in productTotals) {
          productTotals[productName] += totalSale;
        } else {
          productTotals[productName] = totalSale;
        }
      });

      // Convert the productTotals object to an array
      const productTotalsArray = Object.entries(productTotals).map(
        ([ProductName, TotalSale]) => ({
          ProductName,
          TotalSale,
        })
      );
    } else {
      
    }
  }, [purchaseList, ListofNozzels, ProductName]);

  const handleTotalChange = () => {
    const total = form.getFieldValue("Total");
    const price = form.getFieldValue("Price");
    const quantity = form.getFieldValue("Quantity");

    if (QtyLocked) {
      if (quantity && quantity > 0) {
        const newPrice = total / quantity;
        if (!isNaN(newPrice) && newPrice > 0) {
          form.setFieldsValue({ Price: NumberFormat(newPrice, 6) });
        } else {
          form.setFieldsValue({ Price: 0 });
        }
      } else {
        form.setFieldsValue({ Price: 0 });
      }
    } else if (total && price) {
      const newQuantity = total / price;
      if (!isNaN(newQuantity) && newQuantity > 0) {
        form.setFieldsValue({ Quantity: NumberFormat(newQuantity, 6) });
      }
    }
  };

  useEffect(() => {
    // Convert Price and Quantity to numbers and calculate the total whenever they change
    const priceValue = parseFloat(Price);
    const quantityValue = parseFloat(Quantity);
    const totalValue = priceValue * quantityValue;
    const total = isNaN(totalValue) ? "" : totalValue.toString();
    form.setFieldsValue({
      total: total,
    });
  }, [Price, Quantity]);

  const handleQuantity = (e) => {
    setQuantity(e.target.value);

    const quantity = form.getFieldValue("Quantity");
    const price = form.getFieldValue("Price");
    if (quantity && price) {
      const total = NumberFormat(quantity * price, 2);
      form.setFieldsValue({ Total: total });
    }
  };

  const handlePriceChange = (e) => {
    const price = e.target.value;
    const quantity = form.getFieldValue("Quantity");

    if (price && quantity) {
      const total = price * quantity;
      form.setFieldsValue({ Total: total.toFixed(2) });
    }
    setPrice(price);
  };

  const handleProductChange = (value, option) => {
    setProductName(option.label);
    form.setFieldValue("Price", NumberFormat(option.price, 6));
  };
  // const handleAccountChange = (value, option) => {

  //   setAccountName(option.label);
  // };

  const handleAccountChange = async (value, option) => {
    setRateLoading(true)
    setAccountName(option.label);

    const productId = form.getFieldValue("Product_ID");
    const accountId = value;

    const data = {
      UserID: UserID,
      AccessKey: AccessKey,
      ProductID: productId,
      AccountID: accountId,
    };
   

    const api_config = {
      method: "post",
      url: config.base_url + "Product/GetSavedProductRateByCustomerID",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        if (response.data.status_code === 1) {
          form.setFieldsValue({
            Price: NumberFormat(response.data.Selling_Rate, 6),
          });
          setRateLoading(false);
        } else {
          form.setFieldsValue({
            Price: 0,
          });
          setRateLoading(false);
        }
      })
      .catch(function (error) {
        console.error("Error fetching price:", error);
        setRateLoading(false);
        form.setFieldsValue({
          Price: 0,
        });
      });
  };

  const handleLastReadingChange = (index, value) => {
    const sanitizedValue = value != null || value.length > 0 ? value : 0;
    const newList = [...ListofNozzels];
    newList[index].Last_Reading = sanitizedValue;

    setListofNozzels(newList);

    const updatedList = [...ListofReadings];

    const existingIndex = updatedList.findIndex(
      (item) => item.PumpMachineID === ListofNozzels[index].PumpMachineID
    );

    if (existingIndex !== -1) {
      updatedList[existingIndex].Last_Reading =
        value != null || value.length > 0 ? value : 0;
    } else {
      updatedList.push({
        PumpMachineID: ListofNozzels[index].PumpMachineID,
        Last_Reading: sanitizedValue,
        Current_Reading: value != null || value.length > 0 ? value : 0,
        Returned: newList[index].Returned,
      });
    }

    setListofReadings(updatedList);
  };

  const handleCurrentReadingChange = (index, value) => {
    const sanitizedValue = value != null && value.length > 0 ? value : 0;

    // Update Current_Reading in ListofNozzels
    const newList = [...ListofNozzels];
    newList[index].Current_Reading = sanitizedValue;
    setListofNozzels(newList);

    // Synchronize ListofReadings with ListofNozzels
    const updatedList = ListofNozzels.map((nozzel) => {
      // Check if the current nozzle exists in ListofReadings
      const existingItem = ListofReadings.find(
        (item) => item.PumpMachineID === nozzel.PumpMachineID
      );

      if (existingItem) {
        // Update the existing record
        return {
          ...existingItem,
          Current_Reading: nozzel.Current_Reading,
        };
      } else {
        // Add a new record if it doesn't exist
        return {
          PumpMachineID: nozzel.PumpMachineID,
          Last_Reading: nozzel.Last_Reading,
          Current_Reading: nozzel.Current_Reading,
          Returned: nozzel.Returned,
        };
      }
    });

    setListofReadings(updatedList);
  };

  const handleReturnedChange = (index, value) => {
    const sanitizedValue = value != null || value.length > 0 ? value : 0;

    const newList = [...ListofNozzels];
    newList[index].Returned = sanitizedValue;
    setListofNozzels(newList);

    const updatedList = [...ListofReadings];
    const existingIndex = updatedList.findIndex(
      (item) => item.PumpMachineID === ListofNozzels[index].PumpMachineID
    );

    if (existingIndex !== -1) {
      updatedList[existingIndex].Returned =
        value != null || value.length > 0 ? value : 0;
    } else {
      updatedList.push({
        PumpMachineID: ListofNozzels[index].PumpMachineID,
        Last_Reading: newList[index].Last_Reading,
        Current_Reading: newList[index].Current_Reading,
        Returned: sanitizedValue,
      });
    }

    setListofReadings(updatedList);
  };

  const calculateQuantity = (item) => {
    return item.Current_Reading - item.Last_Reading - item.Returned;
  };

  const calculateTotalQuantity = (ProductCode) => {
    return ListofNozzels.filter(
      (item) => item.ProductCode === ProductCode
    ).reduce((total, item) => total + calculateQuantity(item), 0);
  };

  const totalsByProductCode = {
    PET: calculateTotalQuantity("PET"),
    SPET: calculateTotalQuantity("SPET"),
    HSD: calculateTotalQuantity("HSD"),
    LDO: calculateTotalQuantity("LDO"),
  };

  const handleDate = (date, value) => {
    setDated(value);
  };

  const handleSaveInvoice = () => {
    if (ShowNozzles === true) {
      if (Dated == null) {
        message.error("Please Select Date");
        return;
      }

      const hasPendingQuantity = ListOfSummary.some(
        (item) => item.PendingQuantity > 0
      );

      if (hasPendingQuantity) {
        message.error("Please add remaining quantity into sale detail");
        return false;
      }

      if (ListofReadings.length == 0) {
        message.error("Please enter machine readings");
        return false;
      }

      if (purchaseList.length == 0) {
        message.error("Please add cash details");
        return false;
      }
    } else {
      if (Dated == null) {
        message.error("Please Select Date");
        return;
      }
      if (purchaseList.length == 0) {
        message.error("Please add cash details");
        return false;
      }
    }

    //  setLoadingAddSale(true);
    const modifiedData = purchaseList.map(
      ({ AccountName, ProductName, ...rest }) => rest
    );

    const newArray = ListofReadings.map(
      ({ PumpMachineID, Last_Reading, Current_Reading, Returned }) => ({
        PumpMachineID,
        Last_Reading,
        Current_Reading,
        Returned,
      })
    );

    const data = {
      UserID: UserID,
      AccessKey: AccessKey,
      Dated: Dated,
      Description: Description,
      ListofReadings: newArray,
      ListofSaleDetails: modifiedData,
    };

    var api_config = {
      method: "post",
      url: config.base_url + "Sale/AddSale",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        if (response.data.status_code == 1) {
          // Handle success
          message.success(response.data.status_message);
          setLoadingAddSale(false);
          navigate("/sales/view-sales");
        } else {
          // Handle error
          setLoadingAddSale(false);
        }
        //   setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const onChangeWithoutNozzelCheck = (e) => {
    setWithoutNozzel(e.target.checked);
  };

  const handleDelete = (index) => {
    const updatedItems = [...purchaseList];
    // Remove the item at the specified index
    updatedItems.splice(index, 1);
    // Update the state with the new array
    setPurchaseList(updatedItems);
  };

  const AddCashSale = () => {
    const productTotalsArray = [];

    // Accumulate total sales for each product
    ListofNozzels.forEach((item) => {
      const productName = item.ProductName;
      const totalSale = parseFloat(
        item.Current_Reading - item.Last_Reading - item.Returned || 0
      );

      const productIndex = productTotalsArray.findIndex(
        (product) => product.ProductName === productName
      );

      if (productIndex > -1) {
        productTotalsArray[productIndex].totalSale += totalSale;
      } else {
        productTotalsArray.push({
          Price: item.Sale_Price,
          ProductID: item.ProductID,
          ProductName: productName,
          totalSale,
        });
      }
    });

    const groupedTotalsArray = [];

    purchaseList.forEach((item) => {
      const { Product_ID, Price, Quantity, ProductName } = item;
      const quantity = parseFloat(Quantity);

      const existingProductIndex = groupedTotalsArray.findIndex(
        (product) => product.Product_ID === Product_ID
      );

      if (existingProductIndex > -1) {
        groupedTotalsArray[existingProductIndex].TotalQuantity += quantity;
      } else {
        groupedTotalsArray.push({
          Product_ID,
          ProductName,
          TotalPrice: Price,
          TotalQuantity: quantity,
        });
      }
    });

    const mergedArray = [];

    groupedTotalsArray.forEach((groupedItem) => {
      const matchingProduct = productTotalsArray.find(
        (product) => product.ProductName === groupedItem.ProductName
      );

      const remainingQuantity = matchingProduct
        ? matchingProduct.totalSale - groupedItem.TotalQuantity
        : 0;

      mergedArray.push({
        ...groupedItem,
        Price: matchingProduct ? matchingProduct.Price : 0,
        totalSale: matchingProduct ? matchingProduct.totalSale : 0,
        remainingQuantity: remainingQuantity,
      });
    });

    productTotalsArray.forEach((productItem) => {
      const isProductInGrouped = groupedTotalsArray.some(
        (groupedItem) => groupedItem.ProductName === productItem.ProductName
      );

      if (!isProductInGrouped) {
        mergedArray.push({
          Product_ID: productItem.ProductID,
          ProductName: productItem.ProductName,
          TotalPrice: 0,
          TotalQuantity: 0,
          totalSale: productItem.totalSale,
          remainingQuantity: productItem.totalSale,
          Price: productItem.Price,
        });
      }
    });

    // Filter out products with remainingQuantity equal to 0
    const newPurchaseList = mergedArray
      .filter((item) => item.remainingQuantity > 0) // Filter condition
      .map((item) => ({
        ProductName: item.ProductName,
        AccountName: "Cash",
        Product_ID: item.Product_ID,
        Account_ID: CashAccountID,
        Vehicle_No: "",
        Receipt_No: item.Receipt_No,
        Quantity: item.remainingQuantity,
        Price: item.Price,
        Is_Cash: true,
      }));

    setPurchaseList((prevList) => [...prevList, ...newPurchaseList]);
  };

  const disabledDate = (current) => {
    return current && current > moment().endOf("day");
  };

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  const columnsPumpReadings = [
    {
      title: "Sr.No.",
      dataIndex: "Sr.No.",
      key: "Sr.No.",
      render: (_, record, index) => (
        <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
          <div className="table-avatar">
            <div className="icon">
              <span>{index + 1}</span>
            </div>
          </div>
          <NozzleOil />
        </div>
      ),
    },
    {
      title: "Product",
      dataIndex: "ProductCode",
      key: "ProductCode",
    },
    {
      title: "Last Reading",
      dataIndex: "Last_Reading",
      key: "Last_Reading",
      render: (_, record, index) => (
        <Input
          value={record.Last_Reading} // Controlled input value
          placeholder="Last Reading"
          onChange={
            (e) => handleLastReadingChange(index, e.target.value) // Pass index and new value
          }
        />
      ),
    },
    {
      title: "Current Reading",
      dataIndex: "Current_Reading",
      key: "Current_Reading",
      render: (_, record, index) => (
        <Input
          value={record.Current_Reading} // Controlled input value
          placeholder="Current Reading"
          onChange={
            (e) => handleCurrentReadingChange(index, e.target.value) // Pass index and new value
          }
        />
      ),
    },
    {
      title: "Returned",
      dataIndex: "Returned",
      key: "Returned",
      render: (_, record, index) => (
        <Input
          value={record.Returned}
          placeholder="Returned"
          onChange={(e) => handleReturnedChange(index, e.target.value)}
        />
      ),
    },
    {
      title: "Quantity",
      dataIndex: "Quantity",
      key: "Quantity",
      render: (_, record) => {
        const quantity = calculateQuantity(record);
        return quantity < 0 ? (
          <span style={{ color: "red", fontSize: "12px" }}>
            {NumberFormat(quantity, 2)}
          </span>
        ) : (
          NumberFormat(quantity, 2)
        );
      },
    },
  ];

  const handleQtyLock = () => {
    setQtyLocked(true);
    setPriceLocked(false);
  };

  const handleQtyUnlock = () => {
    setQtyLocked(false);
  };

  const handlePriceLock = () => {
    setPriceLocked(true);
    setQtyLocked(false);
  };

  const handlePriceUnlock = () => {
    setPriceLocked(false);
  };

  const columnsCreditSale = [
    {
      title: "#",
      dataIndex: "Sr.No.",
      key: "Sr.No.",
      render: (_, record, index) => index + 1,
    },
    {
      title: "Product",
      dataIndex: "ProductName",
      key: "ProductName",
    },
    {
      title: "Account",
      dataIndex: "AccountName",
      key: "AccountName",
    },
    {
      title: "Vehicle No",
      dataIndex: "Vehicle_No",
      key: "Vehicle_No",
    },
    {
      title: "Receipt No",
      dataIndex: "Receipt_No",
      key: "Receipt_No",
    },
    {
      title: "Quantity",
      dataIndex: "Quantity",
      key: "Quantity",
      align: "right",
      render: (_, record) => NumberFormat(record.Quantity, 6),
    },
    {
      title: "Price",
      dataIndex: "Price",
      key: "Price",
      align: "right",
      render: (_, record) => NumberFormat(parseFloat(record.Price), 6),
    },

    {
      title: "Total",
      dataIndex: "Total",
      key: "Total",
      align: "right",
      render: (_, record) =>
        NumberFormat(parseFloat(record.Price) * parseFloat(record.Quantity), 6),
    },
    {
      title: "Cash",
      dataIndex: "Is_Cash",
      key: "Is_Cash",
      align: "center",
      render: (_, record, index) => (
        <Checkbox
          checked={record.Is_Cash}
          onChange={() => handleCheckboxChange(index)}
        />
      ),
    },
    {
      title: "",
      dataIndex: "actions",
      key: "actions",
      render: (_, record, index) => (
        <DeleteOutlined onClick={() => handleDelete(index)} />
      ),
    },
  ];

  const handleFullScreen = () => {
    setFullScreen((prevState) => !prevState);
  };

  return (
    <>
      <LoginChecked />
      <div className={`right-side-contents ${Fullscreen ? "full-screen" : ""}`}>
        <div className="page-content" id="sale-content">
          <div className="page-header">
            <h3 className="page-title">
              <NavLink to="/sales/view-sales">
                <ChevronLeftIcon />
              </NavLink>
              Add Sale
            </h3>
            <div className="header-actions">
              <div className="button-row">
                <Button
                  onClick={handleFullScreen}
                  type="dashed"
                  size="large"
                  shape="round"
                  icon={
                    Fullscreen ? (
                      <FullscreenExitOutlined />
                    ) : (
                      <FullscreenOutlined />
                    )
                  }
                >
                  {Fullscreen ? "Exit Full Screen" : "Full Screen"}
                </Button>
              </div>
            </div>
          </div>

          {loading ? (
            <>
              <Row gutter={[34, 0]}>
                <Col xs={24} md={16}>
                  <Skeleton active />
                  <Skeleton active />
                </Col>
                <Col xs={24} md={8}>
                  <Skeleton active />
                </Col>
              </Row>
            </>
          ) : (
            <>
              <Row gutter={[24, 10]} className="mb-5">
                <Col md={3} xs={24}>
                  <Form.Item className="input-vertical" label="Invoice No">
                    <Input value={InvoiceNo} readOnly />
                  </Form.Item>
                </Col>
                <Col md={4} xs={24}>
                  <Form.Item className="input-vertical" label="Date">
                    <DatePicker
                      style={{ width: "100%" }}
                      onChange={handleDate}
                      disabledDate={disabledDate}
                    />
                  </Form.Item>
                </Col>
                <Col md={17} xs={24}>
                  <Form.Item className="input-vertical" label="Description">
                    <Input onChange={handleDescriptionChange} />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={[24, 24]} align="middle" className="mb-5">
                {ShowNozzles === true ? (
                  <>
                    <Col md={16}>
                      <div className="fb-card">
                        <div className="fb-card-header">
                          <div className="fb-card-title">
                            <h2 style={{ margin: 0 }}>
                              Step 1 Enter Pump Readings
                            </h2>
                          </div>
                          <div className="fb-card-action">
                            <Checkbox onChange={onChangeWithoutNozzelCheck}>
                              Proceed without Nozzle Sale
                            </Checkbox>
                          </div>
                        </div>
                        <div className="fb-card-body table">
                          {!WithoutNozzel && (
                            <Table
                              columns={columnsPumpReadings}
                              dataSource={[...ListofNozzels]}
                              pagination={false}
                              loading={loading}
                              scroll={{ x: "100%" }}
                            />
                          )}
                        </div>
                      </div>
                    </Col>
                    <Col md={8}>
                      {!WithoutNozzel &&
                        ListofNozzelsCard.filter((item) => {
                          const isProductCodeValid = [
                            "PET",
                            "SPET",
                            "HSD",
                            "LDO",
                          ].includes(item.ProductCode);

                          if (
                            isProductCodeValid &&
                            !uniqueProductNames.has(item.ProductName)
                          ) {
                            uniqueProductNames.add(item.ProductName);
                            return true;
                          }
                          return false;
                        }).map((item, index) => (
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              marginTop: "10px",
                            }}
                            key={index}
                          >
                            <Space direction="vertical" size={10}>
                              <BoxCard
                                style={{ minWidth: "300px" }}
                                title={`Total ${item.ProductName} Sale`}
                                loading={loading}
                                amount={NumberFormat(
                                  totalsByProductCode[item.ProductCode],
                                  2
                                )}
                                suffix="Ltr"
                                icon={<NozzleOil />}
                              />
                            </Space>
                          </div>
                        ))}
                    </Col>
                  </>
                ) : (
                  ""
                )}

                <Col md={24}>
                  <div className="" style={{ marginTop: 20 }}>
                    <div className="fb-card-header">
                      <div className="fb-card-title">
                        <h2>
                          {ShowNozzles === true
                            ? "Step 2 Enter Credit Sale Details"
                            : "Enter Credit Sale Details"}
                        </h2>
                      </div>
                    </div>
                    <Row gutter={20}>
                      <Col md={7} sm={24} xs={24}>
                        <div className="fb-card">
                          <Form
                            onFinish={handleSubmit}
                            form={form}
                            layout="vertical"
                          >
                            <Row gutter={[10, 5]} align="middle">
                              <Col md={24} sm={24} xs={24}>
                                <Form.Item
                                  name="Product_ID"
                                  label="Select Product"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please select a product!",
                                    },
                                  ]}
                                >
                                  <Select
                                    showSearch
                                    style={{ minWidth: "150px" }}
                                    placeholder="Select Product"
                                    loading={!productTypesArray}
                                    options={productTypesArray}
                                    onChange={handleProductChange}
                                    optionFilterProp="label"
                                    filterOption={(input, option) =>
                                      option.label
                                        .toLowerCase()
                                        .includes(input.toLowerCase())
                                    }
                                  />
                                </Form.Item>
                              </Col>
                              <Col md={24} sm={24} xs={24}>
                                <Form.Item
                                  name="Account_ID"
                                  label="Select Account"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please select an account!",
                                    },
                                  ]}
                                >
                                  <Select
                                    showSearch
                                    style={{ minWidth: "160px" }}
                                    placeholder="Select Account"
                                    loading={!accountTypesArray}
                                    options={accountTypesArray}
                                    onChange={handleAccountChange}
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                      option.label
                                        .toLowerCase()
                                        .includes(input.toLowerCase())
                                    }
                                  />
                                </Form.Item>
                              </Col>
                              <Col md={12} sm={24} xs={24}>
                                <Form.Item name="Vehicle_No" label="Vehicle No">
                                  <Input placeholder="Vehicle No" />
                                </Form.Item>
                              </Col>
                              <Col md={12} sm={24} xs={24}>
                                <Form.Item name="Receipt_No" label="Receipt No">
                                  <Input placeholder="Receipt No" />
                                </Form.Item>
                              </Col>
                              <Col md={12} sm={24} xs={24}>
                                <Form.Item
                                  name="Quantity"
                                  label={
                                    <span
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      Quantity
                                      {QtyLocked ? (
                                        <LockClosedIcon
                                          onClick={handleQtyUnlock}
                                          style={{
                                            width: 16,
                                            cursor: "pointer",
                                            marginLeft: "5px",
                                          }}
                                        />
                                      ) : (
                                        <LockOpenIcon
                                          onClick={handleQtyLock}
                                          style={{
                                            width: 16,
                                            cursor: "pointer",
                                            marginLeft: "5px",
                                          }}
                                        />
                                      )}
                                    </span>
                                  }
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please enter quantity!",
                                    },
                                  ]}
                                >
                                  <Input
                                    placeholder="Quantity"
                                    onChange={handleQuantity}
                                    style={{ minWidth: "70px" }}
                                    disabled={QtyLocked}
                                  />
                                </Form.Item>
                              </Col>

                              <Col md={12} sm={24} xs={24}>
                                <Form.Item
                                  name="Price"
                                  label={
                                    <span
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      Price
                                      {PriceLocked ? (
                                        <LockClosedIcon
                                          onClick={handlePriceUnlock}
                                          style={{
                                            width: 16,
                                            cursor: "pointer",
                                            marginLeft: "5px",
                                          }}
                                        />
                                      ) : (
                                        <LockOpenIcon
                                          onClick={handlePriceLock}
                                          style={{
                                            width: 16,
                                            cursor: "pointer",
                                            marginLeft: "5px",
                                          }}
                                        />
                                      )}
                                    </span>
                                  }
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please enter price!",
                                    },
                                  ]}
                                >
                                  <Input
                                    placeholder="Price"
                                    onChange={handlePriceChange}
                                    disabled={PriceLocked}
                                  />
                                </Form.Item>
                              </Col>
                              <Col md={12} sm={24} xs={24}>
                                <Form.Item name="Total" label="Total">
                                  <Input
                                    placeholder="Total"
                                    onChange={handleTotalChange}
                                  />
                                </Form.Item>
                              </Col>
                              <Col md={12}>
                                <Form.Item label="" style={{ marginBottom: 0 }}>
                                  <Checkbox
                                    checked={Is_Cash}
                                    onChange={(e) =>
                                      setIs_Cash(e.target.checked)
                                    }
                                  >
                                    Cash
                                  </Checkbox>
                                </Form.Item>
                              </Col>
                              <Col md={24} sm={24} xs={24}>
                                <Button
                                  type="primary"
                                  shape="round"
                                  htmlType="submit"
                                  size="large"
                                  style={{ width: "100%" }}
                                >
                                  Add
                                </Button>
                              </Col>
                            </Row>
                          </Form>
                        </div>
                      </Col>
                      <Col md={17} sm={24}>
                        <Table
                          columns={columnsCreditSale}
                          dataSource={purchaseList}
                          pagination={false}
                          loading={loading}
                          scroll={{ x: "100%" }}
                          summary={() =>
                            totals.totalPrice > 0 && (
                              <>
                                <Table.Summary.Row className="table-footer">
                                  <Table.Summary.Cell colSpan={7} align="right">
                                    <b>Total Sale</b>
                                  </Table.Summary.Cell>
                                  <Table.Summary.Cell align="left" colSpan={3}>
                                    {NumberFormat(totals.totalPrice, 6)}
                                  </Table.Summary.Cell>
                                </Table.Summary.Row>
                                <Table.Summary.Row>
                                  <Table.Summary.Cell colSpan={7} align="right">
                                    <b>Total Cash Sale</b>
                                  </Table.Summary.Cell>
                                  <Table.Summary.Cell align="left" colSpan={3}>
                                    {NumberFormat(totals.cashSaleTotal, 6)}
                                  </Table.Summary.Cell>
                                </Table.Summary.Row>
                                <Table.Summary.Row>
                                  <Table.Summary.Cell colSpan={7} align="right">
                                    <b>Total Credit Sale</b>
                                  </Table.Summary.Cell>
                                  <Table.Summary.Cell align="left" colSpan={3}>
                                    {NumberFormat(totals.creditSaleTotal, 6)}
                                  </Table.Summary.Cell>
                                </Table.Summary.Row>
                              </>
                            )
                          }
                        />
                        <Row justify="end" style={{ marginTop: "20px" }}>
                          <Space size={10}>
                            <Button
                              type="default"
                              size="large"
                              shape="round"
                              onClick={AddCashSale}
                            >
                              Add Cash Sale
                            </Button>
                            <Button
                              onClick={handleSaveInvoice}
                              type="primary"
                              size="large"
                              shape="round"
                              loading={loadingAddSale}
                              // disabled={
                              //    ShowNozzles === false ? ListofReadings.length == 0 || purchaseList.length == 0 || ListOfSummary.some(item => item.PendingQuantity < 0 : "")
                              // }
                              disabled={
                                ShowNozzles === true
                                  ? ListofReadings.length === 0 ||
                                    purchaseList.length === 0 ||
                                    ListOfSummary.some(
                                      (item) => item.PendingQuantity < 0
                                    )
                                  : false
                              }
                            >
                              Save Invoice
                            </Button>
                          </Space>
                        </Row>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default AddSales;
