import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Button,
  Form,
  Input,
  Select,
  Space,
  Card,
  message,
  Skeleton,
} from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { NavLink } from "react-router-dom";

import config from "../../config";
import axios from "axios";
import { ChevronLeftIcon } from "@heroicons/react/24/outline";

import jazzcash from "../../assets/images/payment/jazzcash.jpg";
import LoginChecked from "../Shared/LoginChecked";

function Billing() {
  const AccessKey = localStorage.getItem("AccessKey");
  const UserID = localStorage.getItem("ID");
  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);
  const [Step, setStep] = useState(1);
  const [SelectedPackage, setSelectedPackage] = useState({ Name: "Free" });
  const [packages, setPackages] = useState([]);

  useEffect(() => {
    document.title = "Manage Billing";
    setLoading(true);
    const data = {
      UserID: UserID,
      AccessKey: AccessKey,
    };

    const api_config = {
      method: "post",
      url: config.base_url + "Package/GetAllPackges",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
    
        if (response.data.status_code == 1) {
          setPackages(response.data.listofRecords);
          setLoading(false);

          // Handle success
        } else {
          // Handle error
          setLoading(false);
        }
      })
      .catch(function (error) {
        // setValidated(true);

        console.log(error);
      });
  }, []);

  const handleSubmit = (values) => {
    

    setLoading(true);
    const data = {
      UserID: UserID,
      AccessKey: AccessKey,
      ...values,
    };

    const api_config = {
      method: "post",
      url: config.base_url + "Users/AddNewUser",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        
        if (response.data.status_code == 1) {
          setLoading(false);

          // Handle success
        } else {
          // Handle error
          setLoading(false);
        }
      })
      .catch(function (error) {
        // setValidated(true);

        console.log(error);
      });
  };

  const handlePackage = (item) => {

    setSelectedPackage(item);
  };

  // const AddPackage = () => {
  //     const data = {
  //         UserID: UserID,
  //         AccessKey: AccessKey,
  //         Name: "Yearly",
  //         Tags: "Hot Sale",
  //         Price: "20000",
  //         Title: "Yearly Plan",
  //         Description: "Maximize your savings with our best-value annual subscription and get access to all features.",
  //         Savings: "4000",

  //     };

  //     const api_config = {
  //         method: 'post',
  //         url: config.base_url + 'Package/AddNewPackage',
  //         headers: {
  //             'Content-Type': 'application/json',
  //         },
  //         data: data,
  //     };

  //     axios(api_config)
  //         .then(function (response) {
  //            

  //         })
  //         .catch(function (error) {
  //             // setValidated(true);

  //             console.log(error);
  //         });

  // }

  const UpdatePackage = () => {
    const data = {
      UserID: UserID,
      AccessKey: AccessKey,
      ID: "4",
      Name: "Yearly",
      Tags: "Hot Sale",
      Price: "15000",
      Title: "Yearly Plan",
      Description:
        "Maximize your savings with our best-value annual subscription and get access to all features.",
      Savings: "9000",
    };

    const api_config = {
      method: "post",
      url: config.base_url + "Package/UpdatePackage",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
    
      })
      .catch(function (error) {
        // setValidated(true);

        console.log(error);
      });
  };

  

  return (
    <>
      <LoginChecked />
      <div className="right-side-contents">
        <div className="page-content">
          <div className="page-header">
            <h3 className="page-title">
              <NavLink to="/dashboard/analytics">
                <ChevronLeftIcon />
              </NavLink>
              Billing
            </h3>
            <div className="page-actions"></div>
          </div>

         
          <Row gutter={[24, 24]}>
            <Col span={12} offset={6}>
              {loading ? (
                <div className="skeleton-packages">
                  <Skeleton.Input block active style={{ height: 100 }} />
                  <Skeleton.Input block active style={{ height: 100 }} />
                  <Skeleton.Input block active style={{ height: 100 }} />
                  <Skeleton.Input block active style={{ height: 100 }} />
                </div>
              ) : Step == 1 ? (
                <>
                  <ul className="packages">
                    {packages.map((item, index) => (
                      <li
                        key={index}
                        className={
                          SelectedPackage.Name === item.Name ? "active" : ""
                        }
                        onClick={() => handlePackage(item)}
                      >
                        <div className="left">
                          <div className="radio-button">
                            <span></span>
                          </div>
                          <div className="package-detail">
                            <h4>{item.Title}</h4>
                            <p>{item.Description}</p>
                          </div>
                        </div>
                        <div className="right">
                          <div className="price">Rs. {item.Price}</div>
                          {item.Savings > 0 && <p>Save Rs. {item.Savings}</p>}
                          {item.Tags && <span>{item.Tags}</span>}
                        </div>
                      </li>
                    ))}
                  </ul>
                  <div className="text-end">
                    <Button
                      type="primary"
                      size="large"
                      shape="round"
                      onClick={() => setStep(2)}
                    >
                      Next
                    </Button>
                  </div>
                </>
              ) : (
                <>
                  <ul className="packages">
                    <li className="active">
                      <div className="left">
                        <div className="radio-button">
                          <span></span>
                        </div>
                        <div className="package-detail">
                          <h4>{SelectedPackage.Title}</h4>
                          <p>{SelectedPackage.Description}</p>
                        </div>
                      </div>
                      <div className="right">
                        <div className="price">Rs. {SelectedPackage.Price}</div>
                        {SelectedPackage.Savings > 0 && (
                          <p>Save Rs. {SelectedPackage.Savings}</p>
                        )}
                        {SelectedPackage.Tasg && (
                          <span>{SelectedPackage.Tags}</span>
                        )}
                      </div>
                    </li>
                  </ul>
                  <div className="payment-item">
                    <div className="logo">
                      <img src={jazzcash} />
                    </div>
                    <div className="info">
                      <h4>Account # 03006590065</h4>
                      <p>
                        Send Rs. {SelectedPackage.price} on JazzCash Account
                      </p>
                      <p>
                        Take screenshot of transaction and send it to WhatsApp
                        number 03006590065. Your account will be active after
                        verification of payment.
                      </p>
                    </div>
                  </div>
                  <div style={{ marginTop: 50 }}>
                    <Button
                      onClick={() => setStep(1)}
                      shape="round"
                      size="large"
                      icon={<ArrowLeftOutlined />}
                    >
                      Change Package
                    </Button>
                  </div>
                </>
              )}
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}

export default Billing;
