import React, { useEffect, useState } from 'react'
import LeftMenu from './LeftMenu'
import { Outlet } from 'react-router-dom'
import { NavLink, useNavigate } from 'react-router-dom';
import { Button, Col, Form, Input, Row, Upload, Dropdown, Tooltip } from 'antd';
import ControlHeader from './ControlHeader';


function ControlMain() {

  const AccessKey = localStorage.getItem("AdminAccessKey");


  let navigate = useNavigate();

  useEffect(() => {

    if (AccessKey == null) {
      navigate("/controlbase/login");
    }

  }, []);


 

  const closeMenu = () => {
    var side_bar = document.getElementById("side-bar");
    var overlay = document.getElementById("overlaymenu");
    var menubutton = document.getElementById("menubutton");
    var body = document.getElementById("body");
    body.classList.remove("active");
    if (side_bar) {
      side_bar.classList.remove("active");
    }

    if (overlay) {
      overlay.classList.remove("active");
    }

    if (menubutton) {
      menubutton.classList.remove("active");
    }
  };

  return (
    <>

      <main id="main">
        <ControlHeader />
        <div className="wrapper">
          <div onClick={closeMenu} id="overlaymenu"></div>
          <LeftMenu />
          <div className="right-side">
            <Outlet />
          </div>
        </div>
      </main>
    </>
  )
}

export default ControlMain
