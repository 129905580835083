import axios from "axios";
import React, { useEffect, useState } from "react";
import config from "../../config";
import LoginChecked from "../Shared/LoginChecked";
import {
  Spin,
  message,
  Form,
  Row,
  Col,
  InputNumber,
  Button,
  Space,
  Card,
} from "antd";
import { NavLink } from "react-router-dom";

const EditAndDeleteSetting = () => {
  const [messageApi, contextHolder] = message.useMessage();
  const [loading, setLoading] = useState(false);
  const [ListofEditDelete, setListofEditDelete] = useState([]);
  const [form] = Form.useForm();
  const UserID = localStorage.getItem("ID");
  const AccessKey = localStorage.getItem("AccessKey");

  useEffect(() => {
    setLoading(true);
    const data = {
      UserID: UserID,
      AccessKey: AccessKey,
    };
    var api_config = {
      method: "post",
      url: config.base_url + "EditDelete/GetEditDeleteSetting",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(api_config)
      .then(function (response) {
        if (response.data.status_code === 1) {
          setListofEditDelete(response.data.ListofSettings);
        }
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
      });
  }, []);

  const handleUpdateSetting = (formData) => {
    setLoading(true);

   
    const updatedList = ListofEditDelete.map((item) => {
      return {
        ...item,
        VoucherEdit: formData[`VoucherEdit_${item.ID}`], 
        VoucherDelete: formData[`VoucherDelete_${item.ID}`],
        SaleEdit: formData[`SaleEdit_${item.ID}`],
        SaleDelete: formData[`SaleDelete_${item.ID}`],
        PurchaseEdit: formData[`PurchaseEdit_${item.ID}`],
        PurchaseDelete: formData[`PurchaseDelete_${item.ID}`],
        TankStockEdit: formData[`TankStockEdit_${item.ID}`],
        TankStockDelete: formData[`TankStockDelete_${item.ID}`],
      };
    });

   

    const data = {
      UserID: UserID,
      AccessKey: AccessKey,
      ListofEditDelete: updatedList,
    };

    var api_config = {
      method: "post",
      url: config.base_url + "EditDelete/UpdateEditDeleteSetting",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        if (response.data.status_code === 1) {
          setLoading(false);
          message.success(response.data.status_message);
        } else {
          setLoading(false);
          message.error(response.data.status_message);
        }
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
      });
};


  return (
    <>
      <LoginChecked />
      {contextHolder}

      <div className="right-side-contents">
        {loading ? (
          <div className="spin-center">
            <Space size="large">
              <Spin size="large" />
            </Space>
          </div>
        ) : (
          <div className="page-content">
            <div className="page-header">
              <h6 className="page-title">Edit And Delete Settings</h6>
            </div>
            <Form
              form={form}
              name="basic"
              layout="horizontal"
              size="large"
              onFinish={handleUpdateSetting}
              autoComplete="off"
              labelCol={{ span: 8 }} 
              wrapperCol={{ span: 16 }}
            >
                <Row gutter={[24, 24]} justify="space-between"  >
              {ListofEditDelete.map((record) => (
                
                
                  <Col md={12} xs={24} key={record.ID}>
                    <h3>{record.User_Type} Settings</h3>
                    <Card>
                    <Form.Item
                      label="Voucher Edit"
                      name={`VoucherEdit_${record.ID}`}
                      initialValue={record.VoucherEdit}
                      rules={[
                        {
                          required: true,
                          message: "Please input the Voucher Edit Days!",
                        },
                      ]}
                    >
                      <InputNumber
                        style={{
                          width: "100%",
                        }}
                        min={0}
                        max={30}
                      />
                    </Form.Item>
                    <Form.Item
                      label="Voucher Delete"
                      name={`VoucherDelete_${record.ID}`}
                      initialValue={record.VoucherDelete}
                      rules={[
                        {
                          required: true,
                          message: "Please input the Voucher Delete Days!",
                        },
                      ]}
                    >
                      <InputNumber
                        style={{
                          width: "100%",
                        }}
                        min={0}
                        max={30}
                      />
                    </Form.Item>
                    <Form.Item
                      label="Sale Edit"
                      name={`SaleEdit_${record.ID}`}
                      initialValue={record.SaleEdit}
                      rules={[
                        {
                          required: true,
                          message: "Please input the Sale Edit Days!",
                        },
                      ]}
                    >
                      <InputNumber
                        style={{
                          width: "100%",
                        }}
                        min={0}
                        max={30}
                      />
                    </Form.Item>
                    <Form.Item
                      label="Sale Delete"
                      name={`SaleDelete_${record.ID}`}
                      initialValue={record.SaleDelete}
                      rules={[
                        {
                          required: true,
                          message: "Please input the Sale Delete Days!",
                        },
                      ]}
                    >
                      <InputNumber
                        style={{
                          width: "100%",
                        }}
                        min={0}
                        max={30}
                      />
                    </Form.Item>
                    <Form.Item
                      label="Purchase Edit"
                      name={`PurchaseEdit_${record.ID}`}
                      initialValue={record.PurchaseEdit}
                      rules={[
                        {
                          required: true,
                          message: "Please input the Purchase Edit Days!",
                        },
                      ]}
                    >
                      <InputNumber
                        style={{
                          width: "100%",
                        }}
                        min={0}
                        max={30}
                      />
                    </Form.Item>
                    <Form.Item
                      label="Purchase Delete"
                      name={`PurchaseDelete_${record.ID}`}
                      initialValue={record.PurchaseDelete}
                      rules={[
                        {
                          required: true,
                          message: "Please input the Purchase Delete Days!",
                        },
                      ]}
                    >
                      <InputNumber
                        style={{
                          width: "100%",
                        }}
                        min={0}
                        max={30}
                      />
                    </Form.Item>
                    <Form.Item
                      label="Tank Stock Edit"
                      name={`TankStockEdit_${record.ID}`}
                      initialValue={record.TankStockEdit}
                      rules={[
                        {
                          required: true,
                          message: "Please input the Tank Stock Edit Days!",
                        },
                      ]}
                    >
                      <InputNumber
                        style={{
                          width: "100%",
                        }}
                        min={0}
                        max={30}
                      />
                    </Form.Item>
                    <Form.Item
                      label="Tank Stock Delete"
                      name={`TankStockDelete_${record.ID}`}
                      initialValue={record.TankStockDelete}
                      rules={[
                        {
                          required: true,
                          message: "Please input the Tank Stock Delete Days!",
                        },
                      ]}
                    >
                      <InputNumber
                        style={{
                          width: "100%",
                        }}
                        min={0}
                        max={30}
                      />
                    </Form.Item>
                    </Card>
                    </Col>
                  
                    
                
              ))}
              </Row>
              <div style={{margin:'20px 60px', display:'flex', alignItems:'flex-end', justifyContent:'flex-end'}}>
              <Form.Item
                wrapperCol={{
                  offset: 8,
                  span: 16,
                }}
              >
                <Button
                  type="primary"
                  size="large"
                  shape="round"
                  htmlType="submit"
                  loading={loading}
                >
                  Update The Setting
                </Button>
              </Form.Item>
              </div>
            </Form>
          </div>
        )}
      </div>
    </>
  );
};

export default EditAndDeleteSetting;
