import React, { useEffect, useState } from "react";
import LoginChecked from "../Shared/LoginChecked";
import ProductDropDown from "../Shared/ProductsDropDown";
import { Form, Select, Table, Button,Row,Col,message,Pagination } from "antd";
import BoxCard from "../Shared/BoxCard";
import axios from "axios";
import config from "../../config";
import dayjs from "dayjs";

function RateAdjustForIncome() {
  const UserID = localStorage.getItem("ID");
  const AccessKey = localStorage.getItem("AccessKey");
  
  const [loading, setLoading] = useState(false);
  const [ProductID, setProductID] = useState(0);
  const [ReportLoading,setReportLoading] = useState(false);
  const[ReportData,setReportData] = useState([]);
  const[data,setData] = useState({});
  const ProductType = ProductDropDown();
  const { Option } = Select;

  //for pagination
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const [totalRecords, setTotalRecords] = useState(0);

  // useEffect(() => {
  //   document.title = "Datewise Product Sale";

  //   if (!ProductID) return; // Exit if no ProductID is selected

  //   setReportLoading(true);

  //   const data = {
  //     UserID: UserID,
  //     AccessKey: AccessKey,
  //     ProductID: ProductID,
  //     StartDate: StartDate,
  //     EndDate: EndDate,
  //     AccountID: AccountID,
  //   };

  //   const api_config = {
  //     method: "post",
  //     url: "YOUR_API_ENDPOINT", // Replace with your API endpoint
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //     data: data,
  //   };

  //   axios(api_config)
  //     .then(response => {
  
  //       if (response.data.status_code === 1) {
  //         setReportData(response.data.TableDetails);
  //       } else {
  //         // Handle error case here
  //         console.error("Error fetching data:", response.data.message);
  //       }
  //     })
  //     .catch(error => {
  //       console.error("API call failed:", error);
  //     })
  //     .finally(() => {
  //       setReportLoading(false);
  //     });

  // }, [ProductID]);

  const fetchAdjustmentReport = () => {
    setReportLoading(true);

    const data = {
      UserID: UserID,
      AccessKey: AccessKey,
      ProductID: ProductID,
      PageNo:pageNo,
      pageSize:pageSize,
    };



    var api_config = {
      method: "post",
      url: config.base_url + "Product/GetAdjustmentReport",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

      axios(api_config).then(response => {
      
        if (response.data.status_code === 1) {
          setReportData(response.data.listofRateDetails);
          setData(response.data)
        } else {
          console.error("Error fetching data:", response.data.message);
        }
      })
      .catch(error => {
        console.error("API call failed:", error);
      })
      .finally(() => {
        setReportLoading(false);
      });
  };

  const handelSubmit=()=>{
    const data={
      UserID:UserID, 
    AccessKey:AccessKey,
    ProductID:ProductID,
    }
    

    var api_config = {
      method: "post",
      url: config.base_url + "Product/AdjustRatesforProfit",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(api_config).then((response) => {
      if (response.data.status_code === 1) {
        setLoading(false);
        message.success("Update Rate");
      } else {
        message.error("Failed to update prices.");
      }
    })
    .catch((error) => {
      console.log(error);
      message.error("An error occurred while updating prices.");
    });
  }

  


  const columns = [
    {
      title: "Sr. #",
      dataIndex: "Sr",
      key: "Sr",
      render: (_, record, index) => index + 1 + pageSize * (pageNo - 1),
    },
    {
      title: "ProductName",
      dataIndex: "ProductName",
      key: "productname",
    },
    {
      title: "Dated",
      dataIndex: "Dated",
      key: "dated",
      render: (_, record) => dayjs(record.Dated).format(config.date_format),
    },
    {
      title: "TransactionID",
      dataIndex: "TransactionID",
      key: "transectionid",
    },
    {
      title: "TransactionType ",
      dataIndex: "TransactionType",
      key: "transactiontype",
    },
    {
      title: "Quantity",
      dataIndex: "Quantity",
      key: "quantity",
     
    },
    {
      title: "Remaining",
      dataIndex: "RemainingQTY",
      key: "remainingqty",
     
    },
    {
      title: "Purchase_Rate",
      dataIndex: "Purchase_Rate",
      key: "purchase_rate",
      render: value => value.toFixed(2),
    },
    {
      title: "AVG Purchase Rate",
      dataIndex: "AVGPurchaseRate",
      key: "avgpurchaserate",
      render: value => value.toFixed(2),
    },
    {
      title: "Sale_Rate",
      dataIndex: "Sale_Rate",
      key: "sale_rate",
      render: value => value.toFixed(2),
    },
    {
      title: "Ex. P. Rate",
      dataIndex: "ExistingPurchaseRateinSaleTable",
      key: "existingpurchaserateinsaletable",
      render: value => value.toFixed(2),
    },
    {
      title: "Status",
      dataIndex: "Status",
      key: "status",
    },
    
  ];

  const onPageChange = (page, pageSize) => {
    setPageNo(page);
  };

  const onPageSizeChange = (current, size) => {
    setPageNo(1);
    setPageSize(size);
  };

  return (
    <>
      <LoginChecked />
      <div className="right-side-contents">
        <div className="page-content">
          <div className="page-header">
            <h3 className="page-title">
              Rate Adjustment for Income Generation
            </h3>
          </div>
          <div className="card-total-detal">
            <Row gutter={[16, 16]} style={{ marginBottom: "16px" }}>
              <Col md={24}>
                <BoxCard
                  loading={loading}
                  title={
                    <p><strong> To Implement FIFO method of selling, you need to adjust
                    the rates, as in this software we have allowed to post
                    purchases and sales in back dates as well. Please press
                    the bellow button to adjust the rates.</strong>
                     
                    </p>
                  }
                />
              </Col>
            </Row>
          </div>
          <div className="filters-wrap">
            <Form>
              <Form.Item>
                <Select
                  showSearch
                  style={{
                    width: "200px",
                  }}
                  placeholder="All Product"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.label.toLowerCase().includes(input.toLowerCase())
                  }
                  onChange={(value) => setProductID(value)}
                  options={ProductType}
                />
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  size="medium"
                  shape="round"
                  onClick={fetchAdjustmentReport}
                  // style={{ marginBottom: 10 }}
                >
                  View Adjustment Report
                </Button>
              </Form.Item>
            </Form>
          </div>

          <Table
            size="small"
            scroll={{
              x: "100%",
            }}
            loading={loading}
            columns={columns}
            dataSource={ReportData}
            // onChange={handleTableChange}
            // footer={getFooterContent}
            pagination={false}
            bordered
          />
          <div
            className="header-actions"
            style={{
              marginTop: "20px",
              marginRight: "60px",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button type="primary" size="large" shape="round" loading={loading} onClick={handelSubmit}>
              Update Rate
            </Button>
          </div>
          <div className="text-end mt-5">
            <Pagination
              current={pageNo}
              pageSize={pageSize}
              size="small"
              total={data.totalRecords}
              onChange={onPageChange}
              onShowSizeChange={onPageSizeChange} 
              showSizeChanger
              // showQuickJumper
              showTotal={(total) => `Total ${total} items`}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default RateAdjustForIncome;
