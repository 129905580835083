import React, { useEffect, useState } from 'react'
import { PDFViewer, Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer';
import moment from 'moment';
import axios from 'axios';
import config from '../../config';
import PrintHeaderAccount from '../../Pdf/PrintHeaderAccount';

function PrintInvoice() {
  const urlParams = new URLSearchParams(window.location.search);
  const purchase_id = urlParams.get('purchase_id');
  const AccessKey = localStorage.getItem("AccessKey");
  const UserID = localStorage.getItem("ID");

  const Dated = urlParams.get('Dated'); // Extracting the 'Dated' value from URL parameters

  const [ListofPurchase, SetListofPurchase] = useState([]);
  const [loading, setLoading] = useState(false);

  const [StartDate, SetStartDate] = useState([]);
  const [EndDate, SetEndDate] = useState([]);

  const today = new Date();
  const date = moment().format('DD MMM YYYY');

  const [Cash_Total, setCashTotal] = useState(0);
  const [Credit_Total, setCreditTotal] = useState(0);
  const [Description, setDescription] = useState(0);
  

  useEffect(() => {

    setLoading(true);
    const data = {
      UserID: UserID,
      AccessKey: AccessKey,
      ID: purchase_id,

    }
    
    var api_config = {
      method: 'post',
      url: config.base_url + 'Purchase/GetPurchaseByID',
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };
    axios(api_config)
      .then(function (response) {
      
        if (response.data.status_code == 1) {
          // Handle success
          SetListofPurchase(response.data.listofPurchase);
          setCashTotal(response.data.Cash_Total);
          setCreditTotal(response.data.Credit_Total);
          setDescription(response.data.Description)
          setLoading(false);

        } else {
          setLoading(false);
        }
      })
      .catch(function (error) {
        console.log(error);
      });

  }, []);


  const styles = StyleSheet.create({
    page: {
      //flexDirection: 'row',
      backgroundColor: '#FFF'
    },
    section: {
      margin: 10,
      padding: 10,
      flexDirection: 'column',
      marginBottom: 20
      //flexGrow: 1
    },
    header: {
      margin: 10,
      padding: 10,
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      borderBottomWidth: .5

    },
    title: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: 10
    },
    table: {
      display: 'table',
      width: '100%',
      borderStyle: 'solid',
      borderWidth: .1,
      borderColor: '#000',
      marginBottom: 10,
    },
    tableHead: {
      flexDirection: 'row',
      backgroundColor: 'rgba(0,0,0,0.05)'
    },
    th: {
      flex: 1,
      padding: 5,
      borderStyle: 'solid',
      borderWidth: .1,
      borderColor: '#000',
    },
    thValue: {
      fontSize: "8pt",
      fontWeight: "bold"
    },

    row: {
      flexDirection: 'row',
    },
    td: {
      flex: 1,
      padding: 5,
      borderStyle: 'solid',
      borderWidth: .1,
      borderColor: '#000',
    },
    tdValue: {
      fontSize: "8pt"
    }


  });


  const generateData = () => {
    if (ListofPurchase?.length > 0) {
      return (
        ListofPurchase.map((item, index) => {
          const total = item.Quantity * item.Price;
          return (
            <View key={index} style={styles.row}>
              <View style={[styles.td, { flexGrow: 0, paddingRight: 20 }]}><Text style={styles.tdValue}>{index + 1}</Text></View>
              <View style={styles.td}><Text style={styles.tdValue}>{item.Is_Cash ? "Cash" : "Credit"}</Text></View>
              <View style={styles.td}><Text style={styles.tdValue}>{item.Product_BE?.Name}</Text></View>
              <View style={styles.td}><Text style={styles.tdValue}>{item.Account_BE?.Name}</Text></View>
              <View style={styles.td}><Text style={styles.tdValue}>{item.Vehicle_No}</Text></View>
              <View style={styles.td}><Text style={styles.tdValue}>{item.Quantity}</Text></View>
              <View style={styles.td}><Text style={styles.tdValue}>{item.Price}</Text></View>
              <View style={styles.td}><Text style={styles.tdValue}>{total}</Text></View>


            </View>
          )
        })
      )
    }

  };

  const MyDocument = () => (
    <Document>
      <Page size="A4" style={styles.page}>
        <PrintHeaderAccount />
        <View style={styles.section}>
          <View style={styles.title}>
            <Text style={{ fontSize: "12pt" }}>PURCHASE INVOICE</Text>
            <Text style={{ fontSize: "12pt" }}>Invoice No. {purchase_id} </Text>
            <Text style={{ fontSize: 12 }}>{date}</Text>
          </View>
          <View style={styles.table}>
            <View style={styles.tableHead}>
              <View style={[styles.th, { flexGrow: 0, paddingRight: 20 }]}><Text style={styles.thValue}>Sr.#</Text></View>
              <View style={styles.th}><Text style={styles.thValue}>Type</Text></View>
              <View style={styles.th}><Text style={styles.thValue}>Product</Text></View>
              <View style={styles.th}><Text style={styles.thValue}>Customer</Text></View>
              <View style={styles.th}><Text style={styles.thValue}>Vehicle No</Text></View>
              <View style={styles.th}><Text style={styles.thValue}>Quantity</Text></View>
              <View style={styles.th}><Text style={styles.thValue}>Price</Text></View>
              <View style={styles.th}><Text style={styles.thValue}>Total</Text></View>

            </View>
            {generateData()}
          </View>

          <View style={{ borderBottomWidth: 1, borderBottomColor: '#ccc', marginTop: 8 }} />
          <View style={{ fontSize: 8, marginTop: 8 }}>
            <Text style={{fontWeight: 800}}>Description: {Description}</Text>
          </View>
          <View style={{ borderBottomWidth: 1, borderBottomColor: '#ccc', marginTop: 8 }} />
          <View style={{ fontSize: 8, marginTop: 8 , alignSelf: 'flex-end'}}>
            <Text>Cash Total:                  {Cash_Total}</Text>
          </View>
          <View style={{ borderBottomWidth: 1, borderBottomColor: '#ccc', marginTop: 8 }} />
          <View style={{ fontSize: 8, marginTop: 8 , alignSelf: 'flex-end'}}>
            <Text>Credit Total:                {Credit_Total}</Text>
          </View>

          <View style={{ marginBottom: 20, marginTop: 20, top: 10, bottom: 10 }} fixed>
            <Text style={{ fontSize: '8pt' }} render={({ pageNumber, totalPages }) => (
              `${pageNumber} / ${totalPages}`
            )} />
          </View>



        </View>
      </Page>
    </Document>
  );


  return (
    <>

      <div style={{ width: '100%', height: '100vh', overflow: 'hidden' }}>
        <PDFViewer width="100%" height="100%" fileName="sample.pdf">
          <MyDocument />
        </PDFViewer>


      </div>

    </>
  )
}

export default PrintInvoice
