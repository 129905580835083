import React, { useEffect, useState } from "react";
import axios from "axios";
import config from "../../config";
import { Row, Col, Button, Skeleton, DatePicker, Form, Result } from "antd";
import { NavLink } from "react-router-dom";
import { PrinterOutlined, InboxOutlined } from "@ant-design/icons";
import StatBox from "./StatBox";
import moment from "moment";
import dayjs from "dayjs";
import LoginChecked from "../Shared/LoginChecked";
import { toPadding } from "chart.js/helpers";
function TradingAccounts() {
  const { RangePicker } = DatePicker;


  const [loading, setLoading] = useState(false);
  const AccessKey = localStorage.getItem("AccessKey");
  const UserID = localStorage.getItem("ID");
  const [StartDate, SetStartDate] = useState(null);
  const [EndDate, SetEndDate] = useState(null);
  const [listTradingAccount, setListTradingAccount] = useState({});
  const [tradingData, setTradingData] = useState({});

  useEffect(() => {
    document.title = "Trading Account";
    const data = {
      UserID: UserID,
      AccessKey: AccessKey,
      StartDate: StartDate,
      EndDate: EndDate,
    };



    var api_config = {
      method: "post",
      url: config.base_url + "Report/GetTradingAccountReport",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
      
        if (response.data.status_code == 1) {
          setTradingData(response.data);
          setListTradingAccount(response.data.Trading);

          setLoading(false);
        } else {
          // Handle error
          setLoading(false);
        }
        //   setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [StartDate, EndDate]);

  // const columns = [
  //   {
  //     title: "Serial No",
  //     dataIndex: "SerialNo",
  //     key: "SerialNo",
  //   },
  //   {
  //     title: "Dated",
  //     dataIndex: "Dated",
  //     key: "Dated",
  //   },
  //   {
  //     title: "Customer",
  //     dataIndex: "Customer",
  //     key: "Customer",
  //   },
  //   {
  //     title: "Product",
  //     dataIndex: "Product",
  //     key: "Product",
  //   },
  //   {
  //     title: "Receipt",
  //     dataIndex: "Receipt",
  //     key: "Receipt",
  //   },
  //   {
  //     title: "Vehicle",
  //     dataIndex: "Vehicle",
  //     key: "Vehicle",
  //   },
  //   {
  //     title: "Price",
  //     dataIndex: "Price",
  //     key: "Price",
  //   },
  //   {
  //     title: "	Quantity",
  //     dataIndex: "	Quantity",
  //     key: "	Quantity",
  //   },
  //   {
  //     title: "Amount",
  //     dataIndex: "Amount",
  //     key: "Amount",
  //   },
  // ];

  const handleDateChange = (date, value) => {
   
    SetStartDate(value[0]);
    SetEndDate(value[1]);
  };

  const disableDate=(current)=>{
    return current && current>moment().endOf('day');
  }

  return (
    <>
      <LoginChecked />
      <div className="right-side-contents">
        <div className="page-content">
          {/* <Row justify="space-around" align="middle" >
            <Col span={7}>
             
              <div className="page-header">
                <h6 className="page-title">Trading Accounts</h6>
              </div>
            </Col>

            <Col span={17}>
              <Row justify="end">
                <Col span={4}>
                  <NavLink to="#" target='_blank'>
                    <Button
                      type='dashed'
                      size='large'
                      shape='round'
                    // style={{ marginBottom: 10 }}
                    ><PrinterOutlined />Print Report</Button>
                  </NavLink>
                </Col>
              </Row>
            </Col>
          </Row> */}
          <div className="page-header">
            <h3 className="page-title">Trading Accounts</h3>

            <div className="header-actions">
              <NavLink
                to={`/report-trading/print-list/?StartDate=${StartDate}&EndDate=${EndDate}`}
                target="_blank"
              >
                <Button
                  type="dashed"
                  size="large"
                  shape="round"
                  // style={{ marginBottom: 10 }}
                >
                  <PrinterOutlined />
                  Print Report
                </Button>
              </NavLink>
            </div>
          </div>

          <div className="filter-wrap">
            <Form>
              <Form.Item>
                <RangePicker allowClear onChange={handleDateChange}
                disabledDate={disableDate} />
              </Form.Item>
            </Form>
          </div>
          

          {loading ? (
            <>
              <Row>
                <Col xs={24} md={16}>
                  <Skeleton active />
                  <Skeleton active />
                </Col>
              </Row>
            </>
          ) : StartDate != null && EndDate != null ? (
            <>
              <Row gutter={[20, 20]}>
                <Col xs={24} md={12}>
                  <StatBox
                    label="Opening Stock"
                    style={{display:"flex", alignItems:'center', margin:'0px'}}
                    value={
                      listTradingAccount.OpeningStock
                        ? listTradingAccount.OpeningStock
                        : 0
                    }
                  />
                </Col>
                <Col xs={24} md={12}>
                  <StatBox
                    label="Closing Stock"
                    style={{display:"flex", alignItems:'center', margin:'0px'}}
                    value={Math.ceil(
                      listTradingAccount.ClosingStock
                        ? listTradingAccount.ClosingStock
                        : 0
                    )}
                  />
                </Col>
                <Col xs={24} md={12}>
                  <StatBox
                    label="Total Purchase"
                    style={{display:"flex", alignItems:'center', margin:'0px'}}
                    value={
                      listTradingAccount.TotalPurchase
                        ? listTradingAccount.TotalPurchase
                        : 0
                    }
                  />
                </Col>
                <Col xs={24} md={12} >
                  <StatBox
                    label="Total Sale"
                    style={{display:"flex", alignItems:'center', margin:'0px'}}
                    value={Math.ceil(
                      listTradingAccount.TotalSales
                        ? listTradingAccount.TotalSales
                        : 0
                    )}
                  />
                </Col>
                <Col xs={24} md={12}>
                  <StatBox
                    label="Gross Profit"
                    style={{display:"flex", alignItems:'center', margin:'0px'}}
                    value={
                      tradingData.GrossProfit ? tradingData.GrossProfit : 0
                    }
                  />
                </Col>

                <Col xs={24} md={12}>
                  <StatBox
                    label="Loss Suffered"
                    style={{display:"flex", alignItems:'center', margin:'0px'}}
                    value={
                      listTradingAccount.LossSuffered
                        ? listTradingAccount.LossSuffered
                        : 0
                    }
                  />
                </Col>
                <Col xs={24} md={12}>
                  <StatBox
                    label="Total Amount"
                    style={{display:"flex", alignItems:'center', margin:'0px'}}
                    value={Math.ceil(
                      tradingData.TotalAmountLeft
                        ? tradingData.TotalAmountLeft
                        : 0
                    )}
                  />
                </Col>

                <Col xs={24} md={12}>
                  <StatBox
                    label="Total Amount"
                    style={{display:"flex", alignItems:'center', margin:'0px'}}
                    value={
                      tradingData.TotalAmountRight
                        ? tradingData.TotalAmountRight
                        : 0
                    }
                  />
                </Col>
              </Row>
            </>
          ) : (
            <Result
              icon={<InboxOutlined style={{ color: "gray" }} />}
              title={
                <span style={{ color: "gray", fontSize: "16px" }}>No Data</span>
              }
            />
          )}
        </div>
      </div>
    </>
  );
}

export default TradingAccounts;
