import React, { useEffect, useState } from "react";
import axios from "axios";
import StatBox from "./StatBox";
import config from "../../config";
import moment from "moment";
import {
  Row,
  Col,
  Button,
  DatePicker,
  Form,
  Skeleton,
  Card,
  Table,
} from "antd";
import { NavLink } from "react-router-dom";

import { PrinterOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import LoginChecked from "../Shared/LoginChecked";
import { IdentificationIcon } from "@heroicons/react/24/outline";
import { NumberFormat } from "../Shared/Utility";
function SummarySheet() {
  const AccessKey = localStorage.getItem("AccessKey");
  const UserID = localStorage.getItem("ID");
  const [loading, setLoading] = useState(false);
  const [StartDate, SetStartDate] = useState(dayjs().subtract(1, "month"));
  const [summaryData, setSummaryData] = useState({});
  const [EndDate, SetEndDate] = useState(dayjs());

  useEffect(() => {
    document.title = "Summary Sheet";

    setLoading(true);
    const data = {
      UserId: UserID,
      AccessKey: AccessKey,
      StartDate: StartDate,
      EndDate: EndDate,
    };
   

    var api_config = {
      method: "post",
      url: config.base_url + "Report/GetSummarySheet",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        if (response.data.status_code == 1) {
          setSummaryData(response.data);
          // SetStartDate(response.data)
          setLoading(false);
        } else {
          setLoading(false);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [EndDate, StartDate]);


  const { RangePicker } = DatePicker;

  const handleDateChange = (date, value) => {
    SetStartDate(value[0]);
    SetEndDate(value[1]);
  };

  const disabledDate = (current) => {
    return current && current > moment().endOf("day");
  };

  return (
    <>
      <LoginChecked />
      <div className="right-side-contents">
        <div className="page-content">
          <div className="page-header">
            <h3 className="page-title">
              {/* <NavLink to="/company/manage">
                <ChevronLeftIcon />
              </NavLink> */}
              Summary Sheet
            </h3>

            <div className="header-actions">
              {/* <NavLink to={`/accounts/print-account-list/?UserID=${UserID}&AccessKey=${AccessKey}&OrderBy=${orderby}&PageNo=${currentPage}&PageSize=${pageSize}&BalanceType=${BalanceType}&Account_Type_ID=${AccountTypeID}&Dated=${Dated}&Name=${searchedName}`} target='blank'> */}
              <NavLink
                to={`/summary-report/print-list/?StartDate=${StartDate}&EndDate=${EndDate}`}
                target="blank"
              >
                <Button
                  type="dashed"
                  shape="round"
                  size="large"
                  icon={<PrinterOutlined />}
                >
                  Print Report
                </Button>
              </NavLink>
            </div>
          </div>
          <div className="filter-wrap">
            <Form>
              <Form.Item>
                <RangePicker
                  allowClear
                  onChange={handleDateChange}
                  disabledDate={disabledDate}
                />
              </Form.Item>
            </Form>
          </div>
          {loading ? (
            <>
              <Row>
                <Col xs={24} md={16}>
                  <Skeleton active />
                  <Skeleton active />
                </Col>
              </Row>
            </>
          ) : (
            <div style={{margin:'10px 0px 50px 0px'}}>
            <Row justify={"center"}>
              <Col md={20} sm={24}>
                <div className="fb-card">
                  <div className="fb-card-header">
                    <div className="fb-card-title">
                      <span>
                        <IdentificationIcon />
                      </span>
                      <h4>SUMMARY SHEET DETAILS</h4>
                    </div>
                  </div>

                  <div
                    className="fb-card-body table "
                    style={{ "--cardheight": "470px" }}
                  >
                    <ul className="profit-list">
                      <li>
                        <h4>Opening Cash:</h4>
                        <p>
                          {summaryData.OpeningCash
                            ? summaryData.OpeningCash
                            : 0}
                        </p>
                      </li>
                      <li>
                        <h4>Opening Cash at Banks :</h4>
                        <p>
                          {" "}
                          {summaryData.OpeningCashatBanks
                            ? summaryData.OpeningCashatBanks
                            : 0}
                        </p>
                      </li>
                      <li>
                        <h4>Cash Sales:</h4>
                        <p>
                          {summaryData.CashSales
                            ? NumberFormat(summaryData.CashSales, 0)
                            : 0}
                        </p>
                      </li>
                      <li>
                        <h4>Credit Sales:</h4>
                        <p>
                          {summaryData.CreditSales
                            ? NumberFormat(summaryData.CreditSales, 0)
                            : 0}
                        </p>
                      </li>
                      <li>
                        <h4>Cash Purchase:</h4>
                        <p>
                          {summaryData.CashPurchase
                            ? NumberFormat(summaryData.CashPurchase, 0)
                            : 0}
                        </p>
                      </li>
                      <li>
                        <h4>Credit Purchase:</h4>
                        <p>
                          {summaryData.CreditPurchase
                            ? NumberFormat(summaryData.CreditPurchase, 0)
                            : 0}
                        </p>
                      </li>
                      <li>
                        <h4>Total Received:</h4>
                        <p>
                          {summaryData.TotalReceived
                            ? summaryData.TotalReceived
                            : 0}
                        </p>
                      </li>
                      <li>
                        <h4>Total Payments:</h4>
                        <p>
                          {summaryData.TotalPayments
                            ? NumberFormat(summaryData.TotalPayments)
                            : 0}
                        </p>
                      </li>
                      <li>
                        <h4>Total Expenses:</h4>
                        <p>
                          {summaryData.TotalExpenses
                            ? NumberFormat(summaryData.TotalExpenses)
                            : 0}
                        </p>
                      </li>
                      <li>
                        <h4>Total Discounts:</h4>
                        <p>
                          {Math.ceil(
                            summaryData.TotalDiscounts
                              ? NumberFormat(summaryData.TotalDiscounts, 0)
                              : 0
                          )}
                        </p>
                      </li>
                      <li>
                        <h4>Closing Cash:</h4>
                        <p>
                          {summaryData.ClosingCash
                            ? summaryData.ClosingCash
                            : 0}
                        </p>
                      </li>
                      <li>
                        <h4>Closing Cash at Banks:</h4>
                        <p>
                          {summaryData.ClosingCashatBanks
                            ? summaryData.ClosingCashatBanks
                            : 0}
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </Col>
            </Row>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default SummarySheet;
