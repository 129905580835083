import React, { useEffect, useState } from "react";
import {
  PDFViewer,
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import { Spin } from "antd";
import moment from "moment";
import axios from "axios";
import config from "../../config";
import PrintHeaderAccount from "../../Pdf/PrintHeaderAccount";
import { PrintFormateNumber } from "../../Pdf/PrintFormateNumber";

import pdfStyles from "../../Pdf/pdfStyles";
import PrintFooter from "../../Pdf/PrintFooter";

function PrintSaleList() {
  const urlParams = new URLSearchParams(window.location.search);
  const UserID = localStorage.getItem("ID");
  const AccessKey = localStorage.getItem("AccessKey");
  const currentPage = urlParams.get("PageNo");
  const pageSize = urlParams.get("PageSize");
  const StartDate = urlParams.get("StartDate");
  const EndDate = urlParams.get("EndDate");
  

  const [listofSale, SetlistofSale] = useState([]);
  const [loading, setLoading] = useState(false);

 
  const [data, setData] = useState([]);


  useEffect(() => {
    setLoading(true);

    const data = {
      UserID: UserID,
      AccessKey: AccessKey,
      StartDate: StartDate,
      EndDate: EndDate,
      PageNo: 1,
      PageSize: 5000,
    };
  
    var api_config = {
      method: "post",
      url: config.base_url + "Sale/GetSales",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(api_config)
      .then(function (response) {

        if (response.data.status_code == 1) {
          // Handle success
          SetlistofSale(response.data.listofSale);
          setData(response.data);
          setLoading(false);
        } else {
          setLoading(false);
          // Handle error
        }
        //   setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);



  

  const generateData = () => {
    if (listofSale.length > 0) {
      return listofSale.map((item, index) => {
        return (
          <View key={index} style={pdfStyles.row}>
            <View style={[pdfStyles.td, { width: 50 }]}>
              <Text style={pdfStyles.tdValue}>{index + 1}</Text>
            </View>
            <View style={[pdfStyles.td, { width: 50 }]}>
              <Text style={pdfStyles.tdValue}>{item.InvoiceNo}</Text>
            </View>
            <View style={[pdfStyles.td, { width: 100 }]}>
              <Text style={pdfStyles.tdValue}>
                {moment(item.Dated).format("DD MMM YYYY")}
              </Text>
            </View>
            <View style={[pdfStyles.td, { width: 100 }]}>
              <Text style={pdfStyles.tdValue}>{item.Description}</Text>
            </View>
            <View style={[pdfStyles.td, { width: 90, textAlign: "right" }]}>
              <Text style={pdfStyles.tdValue}>{PrintFormateNumber(item.Cash_Total,0)}</Text>
            </View>
            <View style={[pdfStyles.td, { width: 90, textAlign: "right" }]}>
              <Text style={pdfStyles.tdValue}>{PrintFormateNumber(item.Credit_Total,0)}</Text>
            </View>
            <View style={[pdfStyles.td, { width: 90, textAlign: "right" }]}>
              <Text style={pdfStyles.tdValue}>{PrintFormateNumber(item.TotalSales,0)}</Text>
            </View>
          </View>
        );
      });
    }
  };

  const MyDocument = () => (
    <Document>
      <Page size="A4" style={pdfStyles.page}>
        <PrintHeaderAccount />
        <View style={pdfStyles.section}>
          <View style={pdfStyles.title}>
            <Text style={pdfStyles.pagetitle}>SALE REPORT</Text>
          </View>
          <View style={pdfStyles.table}>
            <View style={pdfStyles.tableHead}>
              <View style={[pdfStyles.th, { width: 50 }]}>
                <Text style={pdfStyles.thValue}>Sr.#</Text>
              </View>
              <View style={[pdfStyles.th, { width: 50 }]}>
                <Text style={pdfStyles.thValue}>Invoice</Text>
              </View>
              <View style={[pdfStyles.th, { width: 100 }]}>
                <Text style={pdfStyles.thValue}>Dated</Text>
              </View>
              <View style={[pdfStyles.th, { width: 100 }]}>
                <Text style={pdfStyles.thValue}>Description</Text>
              </View>
              <View style={[pdfStyles.th, { width: 90, textAlign: "right" }]}>
                <Text style={pdfStyles.thValue}>Cash</Text>
              </View>
              <View style={[pdfStyles.th, { width: 90, textAlign: "right" }]}>
                <Text style={pdfStyles.thValue}>Credit</Text>
              </View>
              <View style={[pdfStyles.th, { width: 90, textAlign: "right" }]}>
                <Text style={pdfStyles.thValue}>Total</Text>
              </View>
            </View>
            {generateData()}
            <View style={pdfStyles.tableHead}>
              <View style={[pdfStyles.th, { width: 300, textAlign: "right" }]}>
                <Text style={pdfStyles.thValue}>Total</Text>
              </View>
              <View style={[pdfStyles.th, { width: 90, textAlign: "right" }]}>
                <Text style={pdfStyles.thValue}>{PrintFormateNumber(data.CashTotal,0)}</Text>
              </View>
              <View style={[pdfStyles.th, { width: 90, textAlign: "right" }]}>
                <Text style={pdfStyles.thValue}>{PrintFormateNumber(data.CreditTotal,0)}</Text>
              </View>
              <View style={[pdfStyles.th, { width: 90, textAlign: "right" }]}>
                <Text style={pdfStyles.thValue}>{PrintFormateNumber(data.TotalSales,0)}</Text>
              </View>
            </View>
          </View>
          <View
            style={{ marginBottom: 60, marginTop: 0, top: 10, bottom: 10 }}
            fixed
          >
            <Text
              style={{ fontSize: "8pt" }}
              render={({ pageNumber, totalPages }) =>
                `Page ${pageNumber} / ${totalPages}`
              }
            />
          </View>
        </View>
        <PrintFooter fixed/>
      </Page>
    </Document>
  );

  return (
    <>
      <div style={{ width: "100%", height: "100vh", overflow: "hidden" }}>
        {loading ? (
          <div
            style={{
              height: "100vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Spin />
          </div>
        ) : (
          <PDFViewer width="100%" height="100%" fileName="sample.pdf">
            <MyDocument />
          </PDFViewer>
        )}
      </div>
    </>
  );
}

export default PrintSaleList;
