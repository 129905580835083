import React, { useEffect, useState } from "react";
import HeaderPrint from "../../../Pdf/PrintHeaderAccount";
import axios from "axios";
import config from "../../../config";
import { calculateColumnTotal } from "../../Shared/Utility";
import dayjs from "dayjs";
import pdfStyles from "../../../Pdf/pdfStyles";
import PrintFooter from "../../../Pdf/PrintFooter";
import { PrintFormateNumber } from "../../../Pdf/PrintFormateNumber";
import {
  PDFViewer,
  Page,
  Text,
  View,
  Document,
  StyleSheet,
} from "@react-pdf/renderer";
import { locale } from "dayjs";
import { Spin } from "antd";

const PrintProductWiseSummary = () => {
  const UserID = localStorage.getItem("ID");
  const AccessKey = localStorage.getItem("AccessKey");
  const urlParams = new URLSearchParams(window.location.search);

  const StartDate = urlParams.get("StartDate");
  const EndDate = urlParams.get("EndDate");
  const ProductID = urlParams.get("ProductID");

  const [ProductList, setProductList] = useState([]);
  const [loading, setLoading] = useState(false);
  const[data,setData]=useState({});

  useEffect(() => {
    setLoading(true);
    const data = {
      UserID: UserID,
      AccessKey: AccessKey,
      PageNo: 1,
      PageSize: 10000,
      StartDate: StartDate,
      EndDate: EndDate,
      ProductID: ProductID,
    };

    var api_config = {
      method: "post",
      url: config.base_url + "Report/GetDateWiseProductSummary",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
   
        if (response.data.status_code == 1) {
          setProductList(response.data.ListofSummary);
          setData(response.data);

          setLoading(false);
        } else {
          setLoading(false);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  
  const generateData = () => {
    if (ProductList.length > 0) {
      return ProductList.map((item, index) => {
        return (
          <View key={index} style={pdfStyles.row}>
            <View style={[pdfStyles.td, { width: 30 }]}>
              <Text style={pdfStyles.tdValue}>{index + 1}</Text>
            </View>
            <View style={[pdfStyles.td, { width: 100 }]}>
              <Text style={pdfStyles.tdValue}>
                {dayjs(item.Dated).format(config.date_format)}
              </Text>
            </View>
            <View style={[pdfStyles.td, { width: 80 }]}>
              <Text style={pdfStyles.tdValue}>{PrintFormateNumber(item.OpeningStock,0)}</Text>
            </View>

            <View style={[pdfStyles.td, { width: 100 }]}>
              <Text style={pdfStyles.tdValue}>{PrintFormateNumber(item.OpeningStockValue,0)}</Text>
            </View>
            <View style={[pdfStyles.td, { width: 70 }]}>
              <Text style={pdfStyles.tdValue}>{PrintFormateNumber(item.Purchase,0)}</Text>
            </View>
            <View style={[pdfStyles.td, { width: 90 }]}>
              <Text style={[pdfStyles.tdValue, { textAlign: "right" }]}>
                {PrintFormateNumber(item.PurchaseValue,0)}
              </Text>
            </View>
            <View style={[pdfStyles.td, { width: 80 }]}>
              <Text style={pdfStyles.tdValue}>{PrintFormateNumber(item.Sales,0)}</Text>
            </View>
            <View style={[pdfStyles.td, { width: 100 }]}>
              <Text style={pdfStyles.tdValue}>{PrintFormateNumber(item.SalesValue,0)}</Text>
            </View>
            <View style={[pdfStyles.td, { width: 80 }]}>
              <Text style={pdfStyles.tdValue}>{PrintFormateNumber(item.ClosingStock,0)}</Text>
            </View>
            <View style={[pdfStyles.td, { width: 80 }]}>
              <Text style={pdfStyles.tdValue}>{PrintFormateNumber(item.ClosingStockValue,0)}</Text>
            </View>
          </View>
        );
      });
    }
  };

  const MyDocument = () => (
    <Document>
      <Page size="A4" style={pdfStyles.page}>
        <HeaderPrint />

        <View style={pdfStyles.section}>
          <View style={pdfStyles.title}>
            <Text style={pdfStyles.pagetitle}>Datewise Product Summary</Text>
          </View>
          <View style={pdfStyles.table}>
            <View style={pdfStyles.tableHead}>
              <View style={[pdfStyles.th, { width: 30 }]}>
                <Text style={pdfStyles.thValue}>SR.</Text>
              </View>
              <View style={[pdfStyles.th, { width: 100 }]}>
                <Text style={pdfStyles.thValue}>Dated</Text>
              </View>
              <View style={[pdfStyles.th, { width: 80 }]}>
                <Text style={pdfStyles.thValue}>Op. Stock</Text>
              </View>

              <View style={[pdfStyles.th, { width: 100 }]}>
                <Text style={pdfStyles.thValue}>Op.Value</Text>
              </View>
              <View style={[pdfStyles.th, { width: 70 }]}>
                <Text style={pdfStyles.thValue}>Purchases</Text>
              </View>
              <View style={[pdfStyles.th, { width: 90 }]}>
                <Text style={[pdfStyles.thValue, { textAlign: "right" }]}>
                  Purchases Value
                </Text>
              </View>
              <View style={[pdfStyles.th, { width: 80 }]}>
                <Text style={pdfStyles.thValue}>Sales</Text>
              </View>
              <View style={[pdfStyles.th, { width: 100 }]}>
                <Text style={pdfStyles.thValue}>Sales Value</Text>
              </View>
              <View style={[pdfStyles.th, { width: 80 }]}>
                <Text style={pdfStyles.thValue}>Closing Stock</Text>
              </View>
              <View style={[pdfStyles.th, { width: 80 }]}>
                <Text style={pdfStyles.thValue}>Closing Stock Value</Text>
              </View>
            </View>

            {generateData()}
            <View style={pdfStyles.tableHead}>
              <View style={[pdfStyles.th, { width: 310 }]}>
                <Text style={pdfStyles.thValue}>Total</Text>
              </View>
              
              <View style={[pdfStyles.th, { width: 70 }]}>
                <Text style={pdfStyles.thValue}>{PrintFormateNumber(Number(data.TotalPurchaseQty),0)}</Text>
              </View>
              <View style={[pdfStyles.th, { width: 90 }]}>
                <Text style={[pdfStyles.thValue, { textAlign: "right" }]}>
                {data.TotalPurchaseValue}
                </Text>
              </View>
              <View style={[pdfStyles.th, { width: 80 }]}>
                <Text style={pdfStyles.thValue}>{PrintFormateNumber(Number(data.TotalSaleQty),0)}</Text>
              </View>
              <View style={[pdfStyles.th, { width: 100 }]}>
                <Text style={pdfStyles.thValue}>{data.TotalSaleValue}</Text>
              </View>
              <View style={[pdfStyles.th, { width: 80 }]}>
                <Text style={pdfStyles.thValue}></Text>
              </View>
              <View style={[pdfStyles.th, { width: 80 }]}>
                <Text style={pdfStyles.thValue}></Text>
              </View>
            </View>
          </View>
          <View
            style={{ marginBottom: 50, marginTop: 0, top: 10, bottom: 10 }}
            fixed
          >
            <Text
              style={{ fontSize: "8pt" }}
              render={({ pageNumber, totalPages }) =>
                `Page ${pageNumber} / ${totalPages}`
              }
            />
          </View>
        </View>
        <PrintFooter fixed />
      </Page>
    </Document>
  );

  return (
    <>
      <div style={{ width: "100%", height: "100vh", overflow: "hidden" }}>
        {loading ? (
          <div
            style={{
              height: "100vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Spin />
          </div>
        ) : (
          <PDFViewer width="100%" height="100%" fileName="CashReceivable.pdf">
            <MyDocument />
          </PDFViewer>
        )}
      </div>
    </>
  );
};

export default PrintProductWiseSummary;
