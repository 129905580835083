import React, { useState, useEffect } from "react";
import config from "../../config";
import axios from "axios";
import {
  Table,
  message,
  Form,
  Input,
  DatePicker,
  Select,
  Pagination,
  Tooltip,
  Button,
  Modal,
  Row,
  Col,
  Space,
} from "antd";
import {
  PlusOutlined,
  DeleteOutlined,
  IdcardOutlined,
  PushpinOutlined,
} from "@ant-design/icons";
import { NavLink } from "react-router-dom";
import dayjs from "dayjs";
import moment from "moment";
import Map from "../Map";

const PetrolPumps = () => {
  const [loading, setLoading] = useState(false);
  const UserID = localStorage.getItem("AdminID");
  const AccessKey = localStorage.getItem("AdminAccessKey");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [Package, setPackage] = useState("");
  const [PumpCode, setPumpCode] = useState("");
  const [Email, setEmail] = useState("");
  const [Mobile, setMobile] = useState("");
  const [Name, setName] = useState("");
  const [NozzleSale, setNozzleSale] = useState("");
  const [listOfLedger, setListOdLedger] = useState([]);
  const [ListOfPackages, setListOfPackages] = useState([]);

  // pagination
  const [PageNo, setPageNo] = useState(1);
  const [PageSize, setPageSize] = useState(50);
  const [totalRecords, setTotalRecords] = useState(0);
  const [selectedPumpID, setSelectedPumpID] = useState(null);
  const [adressLoading, setAddressLoading] = useState(false);
  const [longitude, setLongitude] = useState("");
  const [latitude, setLatitude] = useState("");

  const [form] = Form.useForm();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = (id) => {
    form.setFieldsValue({ PumpID: id });

    setSelectedPumpID(id);
    setIsModalOpen(true);
  };

  const handelOk = () => setIsModalOpen(false);

  const handelCancel = () => {
    form.resetFields();
    setSelectedPumpID("");
    setIsModalOpen(false);
  };

  useEffect(() => {
    setLoading(true);
    const data = {
      UserID: UserID,
      AccessKey: AccessKey,
      startDate: startDate,
      endDate: endDate,
      PageNo: PageNo,
      PageSize: PageSize,
      Package: Package,
      PumpCode: PumpCode,
      Email: Email,
      Mobile: Mobile,
      NozzleSale: NozzleSale,
      Name: Name,
    };

    var api_config = {
      method: "post",
      url: config.base_url + "AdminUser/GetPetrolPumps",
      header: {
        "Content-Type": "application/josn",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        if (response.data.status_code == 1) {
          setListOdLedger(response.data.listofLedger);
          setTotalRecords(response.data.totalRecords);

          setLoading(false);
        } else {
          // Handle error
          setLoading(false);
        }
        //   setLoading(false);
      })
      .catch(function (error) {
        message.error("Network Error");
        console.log(error);
      });
  }, [
    startDate,
    endDate,
    Package,
    PumpCode,
    Email,
    Mobile,
    PageSize,
    PageNo,
    Name,
  ]);

  useEffect(() => {
    const data = {
      UserID: UserID,
      AccessKey: AccessKey,
    };
    var api_config = {
      method: "post",
      url: config.base_url + "AdminUser/GetPackagesList",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(api_config)
      .then(function (response) {
        if (response.data.status_code == 1) {
          setListOfPackages(
            response.data.ListofRecords.map((item) => ({
              value: item.Name,
              label: item.Name,
            }))
          );
        } else {
          // Handle error
          message.error(response.data.status_message);
        }
      })
      .catch(function (error) {
        message.error("Network Error");
        console.log(error);
      });
  }, []);

  const columns = [
    {
      title: "ID",
      dataIndex: "ID",
      key: "ID",
    },
    {
      title: "Pump Code",
      dataIndex: "PumpCode",
      key: "PumpCode",
    },
    {
      title: "Dated",
      dataIndex: "Created_Date",
      key: "Created_Date",
      render: (_, record) =>
        dayjs(record.Created_Date).format(config.date_format),
    },

    {
      title: "Name",
      dataIndex: "Name",
      key: "Name",
    },

    {
      title: "Email",
      dataIndex: "Email",
      key: "Email",
    },
    {
      title: "Phone",
      dataIndex: "Phone",
      key: "Phone",
    },
    {
      title: "Mobile",
      dataIndex: "Mobile",
      key: "Mobile",
    },
    {
      title: "Type",
      dataIndex: "PackageName",
      key: "PackageName",
    },
    {
      title: "Action",
      key: "Action",
      fixed: "right",
      width: 100,
      render: (_, record) => {
        return (
          <div style={{ textAlign: "center", display: "flex", gap: 10 }}>
            <Tooltip title="Suspend">
              <DeleteOutlined />
            </Tooltip>
            <Tooltip title="View Detils">
              <NavLink to={`/controlbase/petrolpump/detail/${record.ID}`}>
                <IdcardOutlined />
              </NavLink>
            </Tooltip>
            <Tooltip title="Add Subscription">
              <NavLink
                to={`/controlbase/petrolpump/add-subscription/${record.ID}`}
              >
                <PlusOutlined />
              </NavLink>
            </Tooltip>
            <Tooltip title="Add Location">
              <PushpinOutlined onClick={(id) => showModal(record.ID)} />
            </Tooltip>
          </div>
        );
      },
    },
  ];

  const handleLocationSelect = (lat, lng) => {
    setLatitude(lat);
    setLongitude(lng);
    form.setFieldsValue({ Latitude: lat, Longitude: lng });
  };

  const handelAddressSubmit = (formData) => {
    setAddressLoading(true);
    const data = {
      UserID: UserID,
      AccessKey: AccessKey,
      ...formData,
    };
    

    var api_config = {
      method: "post",
      url: config.base_url + "AdminUser/UpdatePumpLocation",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        
        if (response.data.status_code == 1) {
          message.success(response.data.status_message);
          form.resetFields();
          setIsModalOpen(false);
          setAddressLoading(false);
          handelCancel();
        } else {
          message.error(response.data.status_message);
          // console.log("Error in API response: ", response.data_status);
        }
      })
      .catch(function (error) {
        message.error("Network Error");
      });
  };

  const { RangePicker } = DatePicker;
  const handleDateChange = (date, value) => {
    setStartDate(value[0]);
    setEndDate(value[1]);
  };
  const disabledDate = (current) => {
    return current && current > moment().endOf("day");
  };
  const handleSearchByCode = (e) => {
    setPumpCode(e.target.value);
  };

  const handleSearchByEmail = (e) => {
    setEmail(e.target.value);
  };

  const handleSearchByMobile = (e) => {
    setMobile(e.target.value);
  };

  const handleSearchByName = (e) => {
    setName(e.target.value);
  };

  const onPageChange = (page, pageSize) => {
    setPageNo(page);
  };

  const onPageSizeChange = (current, size) => {
    setPageNo(1);
    setPageSize(size);
  };

  return (
    <>
      <div className="right-side-contents">
        <div className="page-content control-base-wrap">
          <div className="page-header">
            <h3 className="page-title">Petrol Pumps</h3>
            <div className="header-actions">
              <NavLink to="/controlbase/addpump">
                <Button
                  type="primary"
                  size="large"
                  shape="round"
                  icon={<PlusOutlined style={{ color: "white" }} />}
                >
                  Add Pump
                </Button>
              </NavLink>
            </div>
          </div>
          <div className="filters-wrap">
            <Form>
              <Form.Item>
                <RangePicker
                  allowClear
                  onChange={handleDateChange}
                  disabledDate={disabledDate}
                />
              </Form.Item>
              <Form.Item>
                <Input
                  allowClear
                  showSearch
                  placeholder="Name"
                  value={Name}
                  onChange={handleSearchByName}
                />
              </Form.Item>
              <Form.Item>
                <Select
                  allowClear
                  showSearch
                  style={{
                    width: "150px",
                  }}
                  placeholder="Packge"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.label.toLowerCase().includes(input.toLowerCase())
                  }
                  onChange={(value) => setPackage(value)}
                  options={ListOfPackages}
                />
              </Form.Item>
              <Form.Item>
                <Input
                  allowClear
                  showSearch
                  style={{
                    width: "100px",
                  }}
                  placeholder="Pump Code"
                  value={PumpCode}
                  onChange={handleSearchByCode}
                />
              </Form.Item>
              <Form.Item>
                <Input
                  allowClear
                  showSearch
                  placeholder="Search By Email"
                  value={Email}
                  onChange={handleSearchByEmail}
                />
              </Form.Item>
              <Form.Item>
                <Input
                  allowClear
                  showSearch
                  placeholder="Mobile No"
                  value={Mobile}
                  onChange={handleSearchByMobile}
                />
              </Form.Item>
            </Form>
          </div>
          <Table
            size="small"
            scroll={{
              x: "max-content",
            }}
            loading={loading}
            dataSource={listOfLedger}
            columns={columns}
            pagination={false}
            bordered
          />
          <div className="text-end mt-5">
            <Pagination
              current={PageNo}
              pageSize={PageSize}
              size="small"
              total={totalRecords}
              onChange={onPageChange}
              onShowSizeChange={onPageSizeChange}
              showSizeChanger
              showQuickJumper
              showTotal={(total) => `Total ${total} items`}
            />
          </div>
        </div>
      </div>

      <Modal
        title="Add Location"
        visible={isModalOpen}
        onCancel={handelCancel}
        footer={null}
      >
        <Row>
          <Form form={form} layout="vertical" onFinish={handelAddressSubmit}>
            <Col md={24} sm={24}>
              <div
                style={{
                  margin: "20px 0px",
                  height: "300px",
                  overflow: "hidden",
                }}
              >
                <Map onLocationSelect={handleLocationSelect} />
              </div>
            </Col>
            <Row gutter={16}>
              <Col md={4} sm={24}>
                <Form.Item label="ID" name="PumpID">
                  <Input disabled value={selectedPumpID} />
                </Form.Item>
              </Col>

              <Col md={10} sm={24}>
                <Form.Item
                  label="Address Longitude"
                  name="Longitude"
                  rules={[
                    {
                      required: true,
                      message: "Please enter the Longitude",
                    },
                  ]}
                >
                  <Input placeholder="Enter the Longitude" />
                </Form.Item>
              </Col>

              <Col md={10} sm={24}>
                <Form.Item
                  label="Address Latitude"
                  name="Latitude"
                  rules={[
                    {
                      required: true,
                      message: "Please enter the Latitude",
                    },
                  ]}
                >
                  <Input placeholder="Enter the Latitude" />
                </Form.Item>
              </Col>
              <Col span={24}>
                <div className="text-right">
                  <Space wrap>
                    <Button
                      type="primary"
                      size="large"
                      shape="round"
                      htmlType="submit"
                    >
                      Submit Location
                    </Button>
                  </Space>
                </div>
              </Col>
            </Row>
          </Form>
        </Row>
      </Modal>
    </>
  );
};

export default PetrolPumps;
