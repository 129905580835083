import axios from 'axios';
import React, { useEffect, useState } from 'react'
import config from '../../config';


function AccountTypesDropdown(props) {


    const AccessKey = localStorage.getItem("AccessKey");
    const UserID = localStorage.getItem("ID");
    const [AccountTypes, setAccountTypes] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        const data = {
            UserID: UserID,
            AccessKey: AccessKey,
            //ID: props.ID

        }
        var api_config = {
            method: 'post',
            url: config.base_url + 'accounttype/GetAccountTypes',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };
        axios(api_config)
            .then(function (response) {
             
                if (response.data.status_code == 1) {
                    // Handle success
                    setAccountTypes(response.data.listofAccountType);
                    //setAccount(response.data)
                    setLoading(false);

                } else {
                    // Handle error

                }
                //   setLoading(false);
            })
            .catch(function (error) {

                console.log(error);
            });

    }, []);

    if (!loading) {

        // Return the array of objects in the specified format
        const result = AccountTypes.map((item) => ({
            value: item.ID,
            label: item.Name,
        }));

        return result;

    }
    else {
        return false;
    }
}

export default AccountTypesDropdown
