import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import config from "../../config";
import CashInHand from "../../assets/images/CashInHand.svg";
import CashAtBank from "../../assets/images/CashAtBank.svg";
import {
  Col,
  Row,
  Space,
  Spin,
  Tooltip,
  Button,
  Modal,
  Form,
  Input,
  message,
  Checkbox,
  Table,
  Dropdown,
  Menu,
} from "antd";
import {
  DownloadOutlined,
  ShoppingCartOutlined,
  DollarCircleOutlined,
  StockOutlined,
} from "@ant-design/icons";

import SaleChart from "./SaleChart";
import dayjs from "dayjs";

import CashInHandChart from "./CashInHandChat";
import {
  Advances,
  CalendarIcon,
  Cash,
  Dots,
  Expenses,
  FuelTank,
  GraphDown,
  GraphUp,
  Nozzel,
  NozzleOil,
  Paper,
  PumpMachine,
  Stock,
  Tank,
} from "../UserControls/Icons";
import ProductSaleChart from "./ProductSaleChart";
import NoTank from "../../assets/images/NoTank.svg";

function Analytics() {
  const AccessKey = localStorage.getItem("AccessKey");
  const UserID = localStorage.getItem("ID");
  const [loading, setLoading] = useState(false);
  const [NoOfAccounts, SetNoOfAccounts] = useState("");
  const [TotalSales, SetTotalSales] = useState("");
  const [TotalExpenses, SetTotalExpenses] = useState("");
  const [TotalPurchases, SetTotalPurchases] = useState("");
  const [CashInHand, setCashInHand] = useState("");
  const [CashInBank, setCashInBank] = useState("");

  const [open, setOpen] = useState(false);
  const [data, setData] = useState({});
  const [selectedProduct, setSelectedProduct] = useState({});
  const [loadingCahsAccountID, setloadingCahsAccountID] = useState(false);
  const [loadingProductId, setLoadingProductId] = useState(false);
  const [ListCashAccounts, setListCashAccounts] = useState([]);
  const [LoadingProduct, setLoadingProduct] = useState(false);
  const [cashAccountId, setCashAccountId] = useState(0);
  const [productID, setProductID] = useState(0);
  const [listOfSaleProduct, setListOfSaleProduct] = useState([]);
  const [Dated, setDated] = useState(moment().format("YYYY-MM-DD"));
  const [dieselID, setDieselID] = useState(0);
  const [Button_Lodding, setButton_Lodding] = useState(false);
  const [checkboxChecked, setCheckboxChecked] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [ProductUpdated, setProductUpdated] = useState(false);
  const [ListofLoginLog, SetlistofLoginLog] = useState([]);
  const [ListOfTanks, setListOfTanks] = useState([]);
  const [Summaryreport, setSummaryreport] = useState([]);
  const [EndDate, SetEndDate] = useState(dayjs());
  const [StartDate, SetStartDate] = useState(dayjs().subtract(10, "day"));



  useEffect(() => {
    document.title = "Fuelbook - Dashboard";

    setLoading(true);
    const data = {
      UserID: UserID,
      AccessKey: AccessKey,
    };

    var api_config = {
      method: "post",
      url: config.base_url + "Members/GetDashboardData",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        if (response.data.status_code == 1) {
          
          setData(response.data);
          SetNoOfAccounts(response.data.NoOfAccounts);
          SetTotalSales(response.data.TotalSales);
          SetTotalExpenses(response.data.TotalExpenses);
          SetTotalPurchases(response.data.TotalPurchases);
          setListOfTanks(response.data.ListofTanks);
          setCashInHand(response.data.CashInHand);
          setCashInBank(response.data.CashAtBank)
          setLoading(false);
        } else {
          setLoading(false);
        }
      })
      .catch(function (error) {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    setloadingCahsAccountID(true);
    const data = {
      UserID: UserID,
      AccessKey: AccessKey,
      Account_Type_ID: 0,
    };
    var api_config = {
      method: "post",
      url: config.base_url + "account/GetAccountsByType",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        if (response.data.status_code == 1) {
          setListCashAccounts(response.data.listofAccounts);
          setloadingCahsAccountID(false);
        } else {
          setloadingCahsAccountID(false);
          // Handle error
        }
        //   setLoading(false);
      })
      .catch(function (error) { });
  }, []);

  useEffect(() => {
    if (!loadingCahsAccountID) {
      const list = ListCashAccounts.filter(
        (item) =>
          item.Account_Type_ID === 1 || item.Account_Type_BE?.Name === "Cash"
      );

      if (list.length > 0) {
        setCashAccountId(list[0].ID);
      }
    }
  }, [ListCashAccounts]);

  useEffect(() => {
    const dashboardParms = {
      AccessKey: AccessKey,
      UserID: UserID,
    };

    var api_config = {
      method: "post",
      url: config.base_url + "Members/ValidateAccessKey",
      headers: {
        "Content-Type": "application/json",
      },
      data: dashboardParms,
    };

    axios(api_config)
      .then(function (response) { })
      .catch(function (error) { });
  }, []);

  useEffect(() => {
    setLoadingProductId(true);
    const data = {
      UserID: UserID,
      AccessKey: AccessKey,
      Created_Date: Dated,
    };
    var api_config = {
      method: "post",
      url: config.base_url + "Product/GetProducts",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(api_config)
      .then(function (response) {
        if (response.data.status_code == 1) {
          setListOfSaleProduct(response.data.ListofProducts);
          setLoadingProductId(false);
        } else {
        }
      })
      .catch(function (error) { });
  }, []);

  useEffect(() => {
    if (!loadingProductId) {
      const petrolProduct = listOfSaleProduct.find(
        (item) => item.Name === "Petrol"
      );
      const dieselProduct = listOfSaleProduct.find(
        (item) => item.Name === "Hi Speed Diesel"
      );

      if (petrolProduct) {
        setProductID(petrolProduct.ID);
      }
      if (dieselProduct) {
        setDieselID(dieselProduct.ID);
      }
    }
  }, [listOfSaleProduct]);

  const Update_Product_Price = (formData) => {
    setButton_Lodding(true);
    setLoadingProduct(true);
    // setLoading(true);

    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      ID: selectedProduct.ID,
      UpdateAllRates: checkboxChecked,
      ...formData,
    };

    const api_config = {
      method: "post",
      url: config.base_url + "Product/UpdateRate",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        if (response.data.status_code === 1) {
          setProductUpdated(true);
          setButton_Lodding(false);
          setLoadingProduct(false);
          messageApi.open({
            type: "success",
            content: response.data.status_message,
          });

          setTimeout(() => {
            setOpen(false);
          }, 2000);
        } else {
          setLoadingProduct(false);
          setButton_Lodding(false);
          messageApi.open({
            type: "error",
            content: response.data.status_message,
          });
        }
      })
      .catch(function (error) {
        setLoadingProduct(false);
        setButton_Lodding(false);
      });
  };

  const handleCheckboxChange = (e) => {
    setCheckboxChecked(e.target.checked);
  };

  const showModal = (item) => {
    const editProduct = {
      ID: item.ID,
      Name: item.Name,
      Sale_Price: item.Sale_Price,
    };

    setSelectedProduct(editProduct);
    setOpen(true);
  };

  const handleCancel = () => {
    setSelectedProduct(null);
    setOpen(false);
  };

  useEffect(() => {
    setLoading(true);
    const data = {
      UserID: UserID,
      AccessKey: AccessKey,
      StartDate: StartDate,
      EndDate: EndDate,
    };
    var api_config = {
      method: "post",
      url: config.base_url + "Report/DateWiseSummaryReport",
      header: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        if (response.data.status_code == 1) {
          setSummaryreport(response.data.ListofSummary);
          setLoading(false);
        }
      })
      .catch(function (error) { });
  }, []);

  useEffect(() => {
    setLoading(true);
    const data = {
      UserID: UserID,
      AccessKey: AccessKey,
      PageNo: 1,
      PageSize: 10,
    };
    var api_config = {
      method: "post",
      url: config.base_url + "Users/GetLoginLogs",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(api_config)
      .then(function (response) {
        if (response.data.status_code == 1) {
          // Handle success
          SetlistofLoginLog(response.data.listofLogs);
          setLoading(false);
        } else {
          // Handle error
          setLoading(false);
        }
        //   setLoading(false);
      })
      .catch(function (error) { });
  }, []);

  const columns = [
    {
      title: "Sr.#",
      dataIndex: "Sr",
      key: "Sr",
      render: (_, record, index) => index + 1,
    },
    {
      title: "Dated",
      dataIndex: "Created_Date",
      key: "Created_Date",
      render: (_, record) => dayjs(record.Created_Date).format("DD-MM-YYYY"),
    },
    {
      title: "User Name",
      dataIndex: "User_Name",
      key: "User_Name",
    },
    {
      title: "IP Address",
      dataIndex: "IP_Address",
      key: "IP_Address",
    },
    {
      title: "User Type",
      dataIndex: "User_Type",
      key: "User_Type",
      render: (_, record) => (
        <div className="table-tag-row">
          <div className={`tag blue`}>
            <span className="tag-line">{record.User_Type}</span>
          </div>
        </div>
      ),
    },
  ];
  const Readingscolumns = [
    {
      title: "Sr.#",
      dataIndex: "Sr",
      key: "Sr",
      render: (_, record, index) => index + 1,
    },
    {
      title: "Product Name",
      dataIndex: "Type",
      key: "Type",

      render: (_, record) => (
        <div className="table-avatar">
          <div className="icon text">
            <span>{record.ProductCode}</span>
          </div>
          <div className="content">
            <span className="title">{record.ProductName}</span>
            <span className="description">
              {dayjs(record.Created_Date).format("DD-MM-YYYY")}
            </span>
          </div>
        </div>
      ),
    },

    {
      title: "Nozzle number",
      dataIndex: "Pump_No",
      key: "Pump_No",
      render: (_, record) => (
        <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
          <NozzleOil />
          <span>{record.Pump_No}</span>
        </div>
      ),
    },
    {
      title: "Current Reading",
      dataIndex: "Current_Reading",
      key: "Current_Reading",
      render: (_, record) => (
        <NavLink
          to={`/products/machine-reading/?machine_id=${record.PumpMachineID}`}
        >
          {record.Current_Reading}
        </NavLink>
      ),
    },
  ];

  const columnsDatewiseSummary = [
    {
      title: "Sr.#",
      dataIndex: "Sr",
      key: "Sr",
      render: (_, record, index) => index + 1,
    },
    {
      title: "Dated",
      dataIndex: "Dated",
      key: "Dated",
      render: (_, record) => dayjs(record.Dated).format("D-MMM-YYYY"),
    },
    {
      title: "Format 1",
      render: (_, record) => {
        return (
          <Tooltip title="Download Summary 1">
            <NavLink
              to={`/report-datewiseSummary/print-list/?Dated=${dayjs(
                record.Dated
              ).format("YYYY-MM-DD")}`}
              target="_blank"
            >
              <DownloadOutlined
                style={{
                  color: "blue",
                  fontSize: "14px",
                }}
              />
            </NavLink>
          </Tooltip>
        );
      },
    },
    {
      title: "Format 2",
      render: (_, record) => {
        return (
          <Tooltip title="Download Summary 2">
            <NavLink
              to={`/report-datewiseSummary2/print-list/?Dated=${dayjs(
                record.Dated
              ).format("YYYY-MM-DD")}`}
              target="_blank"
            >
              <DownloadOutlined style={{ color: "red", fontSize: "14px" }} />
            </NavLink>
          </Tooltip>
        );
      },
    },
    {
      title: "Format 3",
      render: (_, record) => {
        return (
          <Tooltip title="Download Summary 3">
            <NavLink
              to={`/report-Summary3/print-list?Dated=${dayjs(
                record.Dated
              ).format("YYYY-MM-DD")}`}
              target="_blank"
            >
              <DownloadOutlined style={{ color: "green", fontSize: "14px" }} />
            </NavLink>
          </Tooltip>
        );
      },
    },
  ];

  const columnsStockDetail = [
    {
      title: "Product",
      dataIndex: "Balance",
      key: "Sr",

      render: (_, record) => (
        <div className="table-avatar">
          <div className="icon">
            <span>
              {record.Name.split(" ")
                .map((word) => word.charAt(0))
                .join("")}
            </span>
          </div>
          <div className="content">
            <span className="title">{record.Balance} {record?.Measure_Unit_BE.Name}</span>
            <span className="description">
            {record.Name}
            </span>
          </div>
        </div>
      ),
    },
    {
      title: "Last Purchase Price",
      dataIndex: "Last_Purchase_Price",
      key: "Last_Purchase_Price",
      render: (_, record) => <>Rs {record.Last_Purchase_Price}</>,
    },
    {
      title: "Sale Price",
      dataIndex: "Sale_Price",
      key: "Last_Purchase_Price",
      render: (_, record) => <>Rs {record.Sale_Price}</>,
    },
    {
      title: "Actions",
      dataIndex: "Actions",
      key: "Actions",
      render: (_, record) => {
        const menu = (
          <Menu>
            {record.Is_Default ? (
              ""
            ) : (
              <Menu.Item key="1">
                <NavLink to={`/products/edit-product/${record.ID}`}>
                  Edit Product
                </NavLink>
              </Menu.Item>
            )}

            <Menu.Item key="2">
              <a type="button" onClick={() => showModal(record)}>
                Edit Price
              </a>
            </Menu.Item>
            <Menu.Item key="3">
              <NavLink
                to={`/reports/product-ledger/?product_id=${record.ID}`}
                target="_blank"
              >
                View Stock Detail
              </NavLink>
            </Menu.Item>
          </Menu>
        );

        return (
          <Dropdown overlay={menu} placement="bottomLeft" arrow>
            <Button type="link">
              <Dots />
            </Button>
          </Dropdown>
        );
      },
    },
  ];

  return (
    <>
      {/* <LoginChecked /> */}

      <Modal
        title={`Update Price Of ${selectedProduct ? selectedProduct.Name : ""}`}
        onCancel={handleCancel}
        footer={null}
        open={open}
        style={{ maxWidth: "350px" }}
      >
        <Form
          title="Update Products Rates"
          name="basic"
          layout="vertical"
          onFinish={Update_Product_Price}
          autoComplete="off"
          initialValues={selectedProduct}
          fields={[
            {
              name: "ID",
              value: selectedProduct?.ID,
            },
            {
              name: "Name",
              value: selectedProduct?.Name,
            },
            {
              name: "Amount",
              value: selectedProduct?.Sale_Price,
            },
          ]}
        >
          {selectedProduct ? (
            <div style={{ paddingBottom: "10px" }}>
              Current Price: <b>{selectedProduct.Sale_Price}</b>
            </div>
          ) : (
            ""
          )}
          <Row>
            <Col span={24}>
              <Form.Item name="SalePrice" label="New Price">
                <Input />
              </Form.Item>
            </Col>

            <Col>
              <Checkbox onChange={handleCheckboxChange}>
                Apply For All Customers Sales
              </Checkbox>
            </Col>
            <Col span={24}>
              <div className="text-right">
                <Space wrap>
                  <Button
                    type="primary"
                    size="large"
                    shape="round"
                    loading={Button_Lodding}
                    htmlType="submit"
                  >
                    Save
                  </Button>
                </Space>
              </div>
            </Col>
          </Row>
        </Form>
      </Modal>
      {contextHolder}

      <div className="right-side-contents">
        {loading ? (
          <div className="spin-center">
            <Space size="large">
              <Spin size="large" />
            </Space>
          </div>
        ) : (
          <div className="page-content bg-gray">
            <div className="page-header">
              <h3 className="page-title">Dashboard</h3>
            </div>

            <div className="header-top" style={{ marginBottom: "20px" }}>
              <Row gutter={[20, 20]}>
                <Col xs={24} md={8}>
                  <div className="border-card blue">
                    <div className="card-head">
                      <div className="icon-wrap">
                        <ShoppingCartOutlined className="icon" />
                      </div>
                      <span>Total Purchase</span>
                    </div>
                    <div className="card-content">
                      <p>
                        <span>Rs.</span> {TotalPurchases}
                      </p>
                    </div>
                  </div>
                </Col>
                <Col xs={24} md={8}>
                  <div className="border-card yellow">
                    <div className="card-head">
                      <div className="icon-wrap">
                        <StockOutlined className="icon" />
                      </div>
                      <span>Total Sale</span>
                    </div>
                    <div className="card-content">
                      <p>
                        <span>Rs.</span> {TotalSales}
                      </p>
                    </div>
                  </div>
                </Col>
                <Col xs={24} md={8}>
                  <div className="border-card purpole">
                    <div className="card-head">
                      <div className="icon-wrap">
                        <DollarCircleOutlined className="icon" />
                      </div>
                      <span>Total Expense</span>
                    </div>
                    <div className="card-content">
                      <p>
                        <span>Rs.</span> {TotalExpenses}
                      </p>
                    </div>
                  </div>
                </Col>
                <Col xs={24} md={8}>
                  <div className="border-card sky">
                    <div className="card-head">
                      <div className="icon-wrap">
                        <img src={CashInHand} alt="" />
                      </div>
                      <span>Cash in hand</span>
                    </div>
                    <div className="card-content">
                      <p>
                        <span>Rs.</span> {CashInHand}
                      </p>
                    </div>
                  </div>
                </Col>
                <Col xs={24} md={8}>
                  <div className="border-card light-blue">
                    <div className="card-head">
                      <div className="icon-wrap">
                        <img src={CashAtBank} alt="" />
                      </div>
                      <span>Cash at Bank</span>
                    </div>
                    <div className="card-content">
                      <p>
                        <span>Rs.</span> {CashInBank}
                      </p>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>

            <Row gutter={24} className="mb-5">
              <Col xs={24} sm={24} md={16}>
                <CashInHandChart />
              </Col>
              <Col xs={24} sm={16} md={8}>
                <ProductSaleChart />
              </Col>
            </Row>

            <div className="dash-card" style={{ marginBottom: 24 }}>
              <Row gutter={24}>
                <Col xs={24} md={24}>
                  <SaleChart />
                </Col>
              </Row>
            </div>

            <Row gutter={[24, 24]} className="mb-5">
              <Col xs={24} md={14}>
                <div className="fb-card">
                  <div className="fb-card-header">
                    <div className="fb-card-title">
                      <span>
                        <Stock />
                      </span>
                      <h4>Stock Details</h4>
                    </div>
                  </div>
                  <div
                    className="fb-card-body table"
                    style={{ "--cardheight": "400px" }}
                  >
                    <Table
                      columns={columnsStockDetail}
                      dataSource={data.ListofStockDetails}
                      pagination={false}
                      scroll={{
                        x: "100%",
                      }}
                      loading={loading}
                    />
                  </div>
                </div>
              </Col>
              <Col xs={24} md={10}>
                <div className="fb-card">
                  <div className="fb-card-header">
                    <div className="fb-card-title">
                      <span>
                        <Tank />
                      </span>
                      <h4>Tanks</h4>
                    </div>
                    <div className="header-action">
                      <NavLink to={"/dipsettings/manage-tanks"}>
                        <Button type="primary" size="smalll" shape="round">
                          Manage Tanks
                        </Button>
                      </NavLink>
                    </div>
                  </div>
                  <div
                    className="fb-card-body tank-section"
                    style={{ "--cardheight": "370px" }}
                  >
                    {ListOfTanks.length > 0 ? (
                      ListOfTanks.map((product, index) => {
                        const isLastItem = index === ListOfTanks.length - 1;
                        return (
                          <div
                            className={`tank-details ${isLastItem ? "last" : ""
                              } `}
                            key={product.ProductCode}
                          >
                            <div
                              className={`tank-wrapper ${isLastItem ? "last" : ""
                                }`}
                            >
                              <div
                                className={`tank ${product.ProductCode.toLowerCase()}`}
                              >

                                <h3 className="tank-percentage">
                                  {product.TankFullCapacity.toFixed(2)}%
                                </h3>

                                <div
                                  className="oil"
                                  style={{
                                    height: `${product.TankFullCapacity}%`,
                                    transition: "height 0.5s ease",
                                  }}
                                >

                                  <div className="wave"></div>
                                  <div className="wave second-wave"></div>
                                </div>
                              </div>

                              <div className="tank-details">
                                <h3>{product.Name}</h3>
                                <div className="sub-tank-detail ">
                                  <span>Available Quantity</span>
                                  <p>{product.Balance} Ltr</p>
                                </div>
                                <div className="sub-tank-detail">
                                  <span>Tank Capacity</span>
                                  <p>{product.UseableCapacity} Ltr</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <>
                        <img src={NoTank} alt="NoTank" />
                        <h5>Looks like you haven’t added any tanks yet!</h5>
                        <p>
                          Start by defining your storage tanks to keep track of
                          your petrol and diesel stock levels accurately.
                        </p>
                        <br />
                        <NavLink to="/dipsettings/add-tank">
                          <Button type="dashed" shape="round">
                            Add Tank
                          </Button>
                        </NavLink>
                      </>
                    )}
                  </div>
                </div>
              </Col>
            </Row>
            <Row gutter={[24, 24]} className="mb-5">
              <Col xs={24} md={10}>
                <div className="fb-card">
                  <div className="fb-card-header">
                    <div className="fb-card-title">
                      <span>
                        <CalendarIcon />
                      </span>
                      <h4>Date Wise Summary</h4>
                    </div>
                  </div>
                  <div
                    className="fb-card-body table"
                    style={{ "--cardheight": "370px" }}
                  >
                    <Table
                      columns={columnsDatewiseSummary}
                      dataSource={Summaryreport}
                      pagination={false}
                      scroll={{
                        x: "100%",
                      }}
                      loading={loading}
                    />
                  </div>
                </div>
              </Col>
              <Col xs={24} md={14}>
                <div className="fb-card">
                  <div className="fb-card-header">
                    <div className="fb-card-title">
                      <span>
                        <Paper />
                      </span>
                      <h4>Login Logs</h4>
                    </div>
                    <div className="header-action">
                      <NavLink to={"/security/login-logs"}>
                        <Button type="primary" size="smalll" shape="round">
                          View All
                        </Button>
                      </NavLink>
                    </div>
                  </div>
                  <div
                    className="fb-card-body table"
                    style={{ "--cardheight": "370px" }}
                  >
                    <Table
                      columns={columns}
                      dataSource={ListofLoginLog}
                      pagination={false}
                      scroll={{
                        x: "100%",
                      }}
                      loading={loading}
                    />
                  </div>
                </div>
              </Col>
              <Col xs={24} md={12}>
                <div className="fb-card">
                  <div className="fb-card-header">
                    <div className="fb-card-title">
                      <span>
                        <PumpMachine />
                      </span>
                      <h4>Machine Reading</h4>
                    </div>
                  </div>
                  <div
                    className="fb-card-body table"
                    style={{ "--cardheight": "370px" }}
                  >
                    <Table
                      columns={Readingscolumns}
                      dataSource={data.ListofReadings}
                      pagination={false}
                      scroll={{
                        x: "100%",
                      }}
                      loading={loading}
                    />
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        )}
      </div>
    </>
  );
}

export default Analytics;
