import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Breadcrumb,
  Button,
  Form,
  Input,
  Select,
  Space,
  Card,
  message,
} from "antd";
import { ChevronLeftIcon } from "@heroicons/react/24/solid";
import {
  UserOutlined,
  PhoneOutlined,
  MailOutlined,
  LockOutlined,
  TeamOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { NavLink, useParams } from "react-router-dom";
import Header from "../Layout/Header";
import SecurityMenu from "../Security/SecurityMenu";
import config from "../../config";
import axios from "axios";
import LoginChecked from "../Shared/LoginChecked";

const { Option } = Select;

function EditUser() {
  const params = useParams();
  const [messageApi, contextHolder] = message.useMessage();
  const AccessKey = localStorage.getItem("AccessKey");
  const UserID = localStorage.getItem("ID");
  const [form] = Form.useForm();
  const [validated, setValidated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [LoadingGetUser, setLoadingGetUser] = useState(false);
  const [update, setUpdate] = useState(false);
  const [User, setUser] = useState({});

  useEffect(() => {
    document.title = "Edit User";
    setLoadingGetUser(true);
    setUpdate(false);
    const data = {
      UserID: UserID,
      AccessKey: AccessKey,
      ID: params.id,
    };

    var api_config = {
      method: "post",
      url: config.base_url + "Users/GetUserByID",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(api_config)
      .then(function (response) {
     
        if (response.data.status_code == 1) {
          setLoadingGetUser(false);
          setUser(response.data.User);
        } else {
          setLoadingGetUser(false);
        }
        //   setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [update]);

  const handleSubmit = (values) => {
    

    setLoading(true);
    const data = {
      UserID: UserID,
      AccessKey: AccessKey,
      ID: params.id,
      ...values,
    };
  
    const api_config = {
      method: "post",
      url: config.base_url + "Users/UpdateUser",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
       
        if (response.data.status_code == 1) {
          setLoading(false);
          setUpdate(true);
          messageApi.open({
            type: "success",
            content: response.data.status_message,
          });

          // Handle success
        } else {
          // Handle error
          setLoading(false);
          messageApi.open({
            type: "error",
            content: response.data.status_message,
          });
        }
      })
      .catch(function (error) {
        // setValidated(true);

        console.log(error);
      });
  };

  return (
    <>
      <LoginChecked />
      {contextHolder}

      <div className="right-side-contents">
        <div className="page-content">
          <div className="page-header">
            <h3 className="page-title">
              <NavLink to="/security/manage-users">
                <ChevronLeftIcon />
              </NavLink>
              Edit User
            </h3>

            <div className="header-actions">
              {/* <NavLink to={`/security/manage-users`}>
                <Button
                  type="primary"
                  size="large"
                  shape="round"
                  style={{ marginBottom: 10 }}
                >
                  <PlusOutlined /> Manage Users
                </Button>
              </NavLink> */}
            </div>
          </div>

          <Row gutter={[24, 24]} justify={"center"}>
            <Col md={12} xs={24} >
                <Form
                  form={form}
                  noValidate
                  validated={validated}
                  onFinish={handleSubmit}
                  name="basic"
                  layout="horizontal"
                  size="large"
                  labelCol={{
                    span: 8,
                  }}
                  wrapperCol={{
                    span: 16,
                  }}
                  initialValues={{
                    remember: true,
                  }}
                  autoComplete="off"
                  loading={loading}
                  fields={[
                    {
                      name: "FullName",
                      value: User.FullName,
                    },
                    {
                      name: "User_Mobile",
                      value: User.User_Mobile,
                    },
                    {
                      name: "User_Name",
                      value: User.User_Name,
                    },
                    {
                      name: "Password",
                      value: User.Password,
                    },
                    {
                      name: "User_Type",
                      value: User.User_Type,
                    },
                  ]}
                >
                  <Form.Item
                    name="FullName"
                    label="Full Name:"
                    rules={[
                      { required: true, message: "Please enter Full Name" },
                    ]}
                  >
                    <Input placeholder="Enter Full Name" />
                  </Form.Item>

                  <Form.Item
                    name="User_Mobile"
                    label="Mobile:"
                    rules={[{ required: true, message: "Please enter Mobile" }]}
                  >
                    <Input placeholder="Enter Mobile" />
                  </Form.Item>

                  <Form.Item
                    name="User_Name"
                    label="Email:"
                    rules={[{ required: true, message: "Please enter E-Mail" }]}
                  >
                    <Input placeholder="Enter Email" />
                  </Form.Item>

                  <Form.Item
                    name="Password"
                    label="Password:"
                    rules={[
                      { required: true, message: "Please enter Password" },
                    ]}
                  >
                    <Input.Password placeholder="Enter Password" />
                  </Form.Item>

                  <Form.Item
                    name="User_Type"
                    label="User Type:"
                    rules={[
                      { required: true, message: "Please select User Type" },
                    ]}
                  >
                    <Select placeholder="Select User Type">
                      <Option value="Admin">Admin</Option>
                      <Option value="Operator">Operator</Option>
                    </Select>
                  </Form.Item>

                  <Form.Item
                    wrapperCol={{
                      offset: 8,
                      span: 16,
                    }}
                  >
                    <Button
                      type="primary"
                      size="large"
                      shape="round"
                      htmlType="submit"
                      loading={loading}
                    >
                      Edit User
                    </Button>
                  </Form.Item>
                </Form>
              
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}

export default EditUser;
