import React, { useEffect, useState } from "react";
import Header from "../Layout/Header";
import ProductsDropDown from "../Shared/ProductsDropDown";
import config from "../../config";
import axios from "axios";
import { ChevronLeftIcon } from "@heroicons/react/24/solid";
import LoginChecked from "../Shared/LoginChecked";
import { Button, Col, Form, Input, Row, Select, Spin, message } from "antd";
import { NavLink } from "react-router-dom";

function AddTank() {
  const AccessKey = localStorage.getItem("AccessKey");
  const UserID = localStorage.getItem("ID");
  const [validated, setValidated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [productOptions, setProductOptions] = useState([]);
  const [ListofProducts, setListofProducts] = useState([]);
  const [messageApi, contextHolder] = message.useMessage();
  const [ProductName, setProductName] = useState("");
  const [form] = Form.useForm();

  useEffect(() => {
    document.title = "Add Tank";
  }, []);

  useEffect(() => {
    setLoading(true);

    const data = {
      UserID: UserID,
      AccessKey: AccessKey,
    };

    const api_config = {
      method: "post",
      url: config.base_url + "Product/GetProducts",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
       

        if (response.data.status_code === 1) {
          setListofProducts(response.data.ListofProducts);

          const filteredProducts = response.data.ListofProducts.filter((item) =>
            ["PET", "SPET", "HPET", "HSD"].includes(item.ProductCode)
          ).map((item) => ({
            value: item.ID,
            label: item.Name,
          }));

          setProductOptions(filteredProducts);
        } else {
          messageApi.open({
            type: "error",
            content: response.data.status_message,
          });
        }
      })
      .catch(function (error) {
        console.error("Error fetching products:", error);
        messageApi.open({
          type: "error",
          content: "Failed to fetch products.",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }, [AccessKey, UserID, messageApi]);

  const handleSubmit = (formData) => {
    setLoading(true);

    const data = {
      AccessKey,
      UserID,
      ProductName,
      ...formData,
    };

    axios
      .post(config.base_url + "Product/AddNewTank", data, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response.data.status_code === 1) {
          messageApi.open({
            type: "success",
            content: response.data.status_message,
          });
          form.resetFields();
        } else {
          messageApi.open({
            type: "error",
            content: response.data.status_message,
          });
        }
      })
      .catch((error) => {
        console.error("Error adding new tank:", error);
        messageApi.open({
          type: "error",
          content: "Failed to add new tank.",
        });
      })
      .finally(() => setLoading(false));
  };

  const handleProductChange = (value, option) => {
    setProductName(option.label);
  };

  return (
    <>
      <LoginChecked />
      {contextHolder}
      <div className="right-side-contents">
        <div className="page-content">
          <div className="page-header">
            <h6 className="page-title">
              <NavLink to="/dipsettings/manage-tanks">
                <ChevronLeftIcon />
              </NavLink>
              Add Tank
            </h6>
            <div className="header-actions">
              <NavLink to="/dipsettings/manage-tanks">
                <Button type="primary" size="large" shape="round">
                  Manage Tanks
                </Button>
              </NavLink>
            </div>
          </div>

          <Row justify={"center"}>
            <Col md={12} xs={24}>
              <Form
                name="basic"
                form={form}
                layout="horizontal"
                labelCol={{
                  span: 8,
                }}
                wrapperCol={{
                  span: 16,
                }}
                initialValues={{
                  remember: true,
                }}
                onFinish={handleSubmit}
                autoComplete="off"
              >
                <Form.Item
                  label="Tank Number"
                  name="TankNo"
                  rules={[
                    {
                      required: true,
                      message: "Please enter tank number",
                    },
                  ]}
                >
                  <Input placeholder="Ex. 01" />
                </Form.Item>
                <Form.Item
                  label="Product"
                  name="ProductID"
                  rules={[
                    {
                      required: true,
                      message: "Select Product",
                    },
                  ]}
                >
                  <Select
                    showSearch
                    placeholder="Select Product"
                    optionFilterProp="children"
                    onChange={handleProductChange}
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    loading={loading}
                    options={productOptions}
                  />
                </Form.Item>
                <Form.Item label="Tank Capacity" name="TankFullCapacity">
                  <Input placeholder="Ex. 5000" />
                </Form.Item>
                <Form.Item
                  label="Useable Capacity"
                  name="UseableCapacity"
                  rules={[
                    {
                      required: true,
                      message: "Please input your Usable Capacity",
                    },
                  ]}
                >
                  <Input placeholder="Enter Used Capacity" />
                </Form.Item>
                <Form.Item
                  label="Shape Details"
                  name="TankShape"
                  rules={[
                    {
                      required: true,
                      message: "Please input your Tank shape",
                    },
                  ]}
                >
                  <Input placeholder="Ex. Square" />
                </Form.Item>
                <Form.Item
                  label="Size Details"
                  name="TankSizeDetails"
                  rules={[
                    {
                      required: true,
                      message: "Please input your size details",
                    },
                  ]}
                >
                  <Input placeholder="Enter size details" />
                </Form.Item>

                <Form.Item
                  wrapperCol={{
                    offset: 8,
                    span: 16,
                  }}
                >
                  <Button
                    type="primary"
                    size="large"
                    shape="round"
                    htmlType="submit"
                    loading={loading}
                  >
                    {loading ? <Spin /> : "Add Tank"}
                  </Button>
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}

export default AddTank;
